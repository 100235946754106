<div class="filter-holder" [ngStyle]="{'box-shadow': pagetype == 'turnkey-tracker' || pagetype == 'crowdsourcing' || pagetype == 'parade' ? 'none' : ''}">
    <form [formGroup]="filterForm">
        <div style="display: flex">
            <div style="width: 50px; margin-right: 5px">
                <div class="filter-text" [ngStyle]="{'height': pagetype == 'turnkey-tracker' || pagetype == 'crowdsourcing' || pagetype == 'parade' ? '40px' : ''}">
                    <span class="hld">
                         <img *ngIf="!isFilterhasValue" src="./assets/images/filter-cp.png"> 
                         <img *ngIf="isFilterhasValue" class="cursor-pointer" title="Clear All" (click)="clearAllFilter()" src="./assets/images/clear-filter.png"> 
                    </span>
                </div>
            </div>
            <div style="flex-grow: 1">
                <div class="vscroll" id="style-3" *ngIf="pagetype == 'opportunity' || pagetype == 'project'">
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="portfolio">
                            <mat-chip-list #chipList1 aria-label="Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.portfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'portfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search portfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="portfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'portfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredPortfolioList, 'portfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('portfolio')" *ngIf="filterForm.value.portfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subportfolio">
                            <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subportfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subportfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search subportfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'subportfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredSubPortfolioList, 'subportfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subportfolio')" *ngIf="filterForm.value.subportfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="program">
                            <mat-chip-list #chipList3 aria-label="Sub program selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.program.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'program')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search program..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="program_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'program')">
                                <mat-option *ngFor="let option of filteredList(filteredProgramList, 'program')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('program')" *ngIf="filterForm.value.program.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item" *ngIf="pagetype == 'opportunity'">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="opportunity">
                            <mat-chip-list #chipList4 aria-label="Sub opportunity selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.opportunity.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'opportunity')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search opportunity..."
                                    formControlName="input"
                                    [matAutocomplete]="autoOppo"
                                    [matChipInputFor]="chipList4"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="opportunity_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoOppo="matAutocomplete" (optionSelected)="search($event, 'opportunity')">
                                <mat-option *ngFor="let option of filteredList(filteredOpportunityList, 'opportunity')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('opportunity')" *ngIf="filterForm.value.opportunity.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item" *ngIf="pagetype == 'project'">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="project">
                            <mat-chip-list #chipList5 aria-label="Sub project selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.project.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'project')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search project..."
                                    formControlName="input"
                                    [matAutocomplete]="autoProject"
                                    [matChipInputFor]="chipList5"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="project_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoProject="matAutocomplete" (optionSelected)="search($event, 'project')">
                                <mat-option *ngFor="let option of filteredList(filteredProjectList, 'project')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('project')" *ngIf="filterForm.value.project.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="vendor">
                            <mat-chip-list #chipList6 aria-label="Sub vendor selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.vendor.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'vendor')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search OU/GDP..."
                                    formControlName="input"
                                    [matAutocomplete]="autoVendor"
                                    [matChipInputFor]="chipList6"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="vendor_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoVendor="matAutocomplete" (optionSelected)="search($event, 'vendor')">
                                <mat-option *ngFor="let option of filteredList(filteredVendorList, 'vendor')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('vendor')" *ngIf="filterForm.value.vendor.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="tag">
                            <mat-chip-list #chipList7 aria-label="Sub tag selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.tag.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'tag')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Tags..."
                                    formControlName="input"
                                    [matAutocomplete]="autoTag"
                                    [matChipInputFor]="chipList7"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="tag_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoTag="matAutocomplete" (optionSelected)="search($event, 'tag')">
                                <mat-option *ngFor="let option of filteredList(filteredTagList, 'tag')" [value]="option" style="font-size: 0.8rem; height: 30px;" matTooltip="{{option.name}} - {{option.tag_category}}">
                                    <b>{{option.name}}</b> - {{option.tag_category}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('tag')" *ngIf="filterForm.value.tag.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item" *ngIf="pagetype == 'opportunity'">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="oppStatus">
                            <mat-chip-list #chipListOppStatus aria-label="Opportunity Status">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.oppStatus.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'oppStatus')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Status..."
                                    formControlName="input"
                                    [matAutocomplete]="oppStatus"
                                    [matChipInputFor]="chipListOppStatus"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="oppStatus_name">
                            </mat-chip-list>
                            <mat-autocomplete #oppStatus="matAutocomplete" (optionSelected)="search($event, 'oppStatus')">
                                <mat-option *ngFor="let option of filteredList(filteredOppStatusList, 'oppStatus')" [value]="option" style="font-size: 0.8rem; height: 30px;" matTooltip="{{option.label}}">
                                    {{option.label}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('oppStatus')" *ngIf="filterForm.value.oppStatus.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>

                <!--Workforce Filter Start-->
                <div class="vscroll" id="style-3" *ngIf="pagetype == 'workforce'" style="width: calc(55vw - 90px);">
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceMonth">
                            <mat-chip-list #chipList8 aria-label="Sub tag selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceMonth.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceMonth')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Month..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceMonth"
                                    [matChipInputFor]="chipList8"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceMonth_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceMonth="matAutocomplete" (optionSelected)="search($event, 'workforceMonth')">
                                <mat-option *ngFor="let option of filteredListNoName(workforceMonthList, 'workforceMonth')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceMonth')" *ngIf="filterForm.value.workforceMonth.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceJobCategory">
                            <mat-chip-list #chipList9 aria-label="Sub tag selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceJobCategory.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceJobCategory')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Job Category..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceJobCategory"
                                    [matChipInputFor]="chipList9"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceJobCategory_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceJobCategory="matAutocomplete" (optionSelected)="search($event, 'workforceJobCategory')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredWorkforceFilterList?.job_category, 'workforceJobCategory')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceJobCategory')" *ngIf="filterForm.value.workforceJobCategory.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="vendor">
                            <mat-chip-list #chipList10 aria-label="Sub vendor selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.vendor.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'vendor')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search OU/GDP..."
                                    formControlName="input"
                                    [matAutocomplete]="autoVendor2"
                                    [matChipInputFor]="chipList10"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="vendor_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoVendor2="matAutocomplete" (optionSelected)="search($event, 'vendor')">
                                <mat-option *ngFor="let option of filteredList(filteredVendorList, 'vendor')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('vendor')" *ngIf="filterForm.value.vendor.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceClassification">
                            <mat-chip-list #chipList11 aria-label="Sub classification selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceClassification.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceClassification')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Job Category..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceClassification"
                                    [matChipInputFor]="chipList11"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceClassification_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceClassification="matAutocomplete" (optionSelected)="search($event, 'workforceClassification')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredWorkforceFilterList?.classification, 'workforceClassification')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceClassification')" *ngIf="filterForm.value.workforceClassification.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceCountry">
                            <mat-chip-list #chipList12 aria-label="Sub classification selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceCountry.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceCountry')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Country..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceCountry"
                                    [matChipInputFor]="chipList12"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceCountry_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceCountry="matAutocomplete" (optionSelected)="search($event, 'workforceCountry')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredWorkforceFilterList?.country, 'workforceCountry')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceCountry')" *ngIf="filterForm.value.workforceCountry.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <!--Workforce Filter End-->

                
                <!--Application Master Filter Start-->
                <div class="vscroll" id="style-3" *ngIf="pagetype == 'application'" style="width: calc(55vw - 90px);">
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="applicationTier">
                            <mat-chip-list #chipList13 aria-label="Sub application tier selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.applicationTier.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'applicationTier')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Tiers..."
                                    formControlName="input"
                                    [matAutocomplete]="autoapplicationTier"
                                    [matChipInputFor]="chipList13"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="applicationTier_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoapplicationTier="matAutocomplete" (optionSelected)="search($event, 'applicationTier')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredapplicationMasterFilterList?.unique_tiers, 'applicationTier')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('applicationTier')" *ngIf="filterForm.value.applicationTier.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="applicationSupportGroup">
                            <mat-chip-list #chipList14 aria-label="Sub support group selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.applicationSupportGroup.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'applicationSupportGroup')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Support Group..."
                                    formControlName="input"
                                    [matAutocomplete]="autoapplicationSupportGroup"
                                    [matChipInputFor]="chipList14"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="applicationSupportGroup_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoapplicationSupportGroup="matAutocomplete" (optionSelected)="search($event, 'applicationSupportGroup')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredapplicationMasterFilterList?.unique_support_groups, 'applicationSupportGroup')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('applicationSupportGroup')" *ngIf="filterForm.value.applicationSupportGroup.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="applicationBusinessOwner">
                            <mat-chip-list #chipList15 aria-label="Sub Business Owners selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.applicationBusinessOwner.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'applicationBusinessOwner')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Business Owners..."
                                    formControlName="input"
                                    [matAutocomplete]="autoapplicationBusinessOwner"
                                    [matChipInputFor]="chipList15"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="applicationBusinessOwner_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoapplicationBusinessOwner="matAutocomplete" (optionSelected)="search($event, 'applicationBusinessOwner')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredapplicationMasterFilterList?.unique_business_owners, 'applicationBusinessOwner')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('applicationBusinessOwner')" *ngIf="filterForm.value.applicationBusinessOwner.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <!-- <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="applicationBusinessArea">
                            <mat-chip-list #chipList16 aria-label="Sub business areas selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.applicationBusinessArea.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'applicationBusinessArea')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Support Group..."
                                    formControlName="input"
                                    [matAutocomplete]="autoapplicationBusinessArea"
                                    [matChipInputFor]="chipList16"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="applicationBusinessArea_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoapplicationBusinessArea="matAutocomplete" (optionSelected)="search($event, 'applicationBusinessArea')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredapplicationMasterFilterList?.unique_business_areas, 'applicationBusinessArea')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('applicationBusinessArea')" *ngIf="filterForm.value.applicationBusinessArea.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="portfolio">
                            <mat-chip-list #chipList1 aria-label="Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.portfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'portfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search portfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="portfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'portfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredPortfolioList, 'portfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('portfolio')" *ngIf="filterForm.value.portfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subportfolio">
                            <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subportfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subportfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search subportfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'subportfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredSubPortfolioList, 'subportfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subportfolio')" *ngIf="filterForm.value.subportfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="program">
                            <mat-chip-list #chipList3 aria-label="Sub program selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.program.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'program')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search program..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="program_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'program')">
                                <mat-option *ngFor="let option of filteredList(filteredProgramList, 'program')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('program')" *ngIf="filterForm.value.program.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div> -->
                </div>
                <!--Application Master Filter End-->
                
                <!--People Insights Start-->
                <div class="vscroll" id="style-3" *ngIf="pagetype == 'people-insights'" >
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceMonth">
                            <mat-chip-list #chipList141 aria-label="Sub tag selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceMonth.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceMonth')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Month..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceMonth14"
                                    [matChipInputFor]="chipList141"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceMonth_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceMonth14="matAutocomplete" (optionSelected)="search($event, 'workforceMonth')">
                                <mat-option *ngFor="let option of filteredListNoName(workforceMonthList, 'workforceMonth')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceMonth')" *ngIf="filterForm.value.workforceMonth.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceJobCategory">
                            <mat-chip-list #chipList25 aria-label="Sub tag selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceJobCategory.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceJobCategory')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Job Category..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceJobCategory15"
                                    [matChipInputFor]="chipList25"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceJobCategory_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceJobCategory15="matAutocomplete" (optionSelected)="search($event, 'workforceJobCategory')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredWorkforceFilterList?.job_category, 'workforceJobCategory')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceJobCategory')" *ngIf="filterForm.value.workforceJobCategory.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="vendor">
                            <mat-chip-list #chipList136 aria-label="Sub vendor selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.vendor.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'vendor')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search OU/GDP..."
                                    formControlName="input"
                                    [matAutocomplete]="autoVendor16"
                                    [matChipInputFor]="chipList136"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="vendor_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoVendor16="matAutocomplete" (optionSelected)="search($event, 'vendor')">
                                <mat-option *ngFor="let option of filteredList(filteredVendorList, 'vendor')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('vendor')" *ngIf="filterForm.value.vendor.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceClassification">
                            <mat-chip-list #chipList117 aria-label="Sub classification selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceClassification.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceClassification')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Job Category..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceClassification17"
                                    [matChipInputFor]="chipList117"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceClassification_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceClassification17="matAutocomplete" (optionSelected)="search($event, 'workforceClassification')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredWorkforceFilterList?.classification, 'workforceClassification')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceClassification')" *ngIf="filterForm.value.workforceClassification.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workforceCountry">
                            <mat-chip-list #chipList128 aria-label="Sub classification selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workforceCountry.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workforceCountry')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search Country..."
                                    formControlName="input"
                                    [matAutocomplete]="autoworkforceCountry18"
                                    [matChipInputFor]="chipList128"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="workforceCountry_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoworkforceCountry18="matAutocomplete" (optionSelected)="search($event, 'workforceCountry')">
                                <mat-option *ngFor="let option of filteredListNoName(FilteredWorkforceFilterList?.country, 'workforceCountry')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workforceCountry')" *ngIf="filterForm.value.workforceCountry.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="portfolio">
                            <mat-chip-list #chipList1 aria-label="Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.portfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'portfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search portfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="portfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'portfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredPortfolioList, 'portfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('portfolio')" *ngIf="filterForm.value.portfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subportfolio">
                            <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subportfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subportfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search subportfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'subportfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredSubPortfolioList, 'subportfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subportfolio')" *ngIf="filterForm.value.subportfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="program">
                            <mat-chip-list #chipList3 aria-label="Sub program selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.program.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'program')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search program..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="program_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'program')">
                                <mat-option *ngFor="let option of filteredList(filteredProgramList, 'program')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('program')" *ngIf="filterForm.value.program.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    
                    

                </div>    
                <!--People Insights End-->
                <!--Balance Scorecard Filter-->
                <div class="vscroll" id="style-3" *ngIf="pagetype == 'balance-scorecard'" >
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="portfolio">
                            <mat-chip-list #chipList1 aria-label="Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.portfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'portfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search portfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="portfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'portfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredPortfolioList, 'portfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('portfolio')" *ngIf="filterForm.value.portfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subportfolio">
                            <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subportfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subportfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search subportfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'subportfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredSubPortfolioList, 'subportfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subportfolio')" *ngIf="filterForm.value.subportfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="program">
                            <mat-chip-list #chipList3 aria-label="Sub program selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.program.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'program')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search program..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="program_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'program')">
                                <mat-option *ngFor="let option of filteredList(filteredProgramList, 'program')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('program')" *ngIf="filterForm.value.program.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="vendor">
                            <mat-chip-list #chipListBV aria-label="Sub vendor selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.vendor.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'vendor')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search OU/GDP..."
                                    formControlName="input"
                                    [matAutocomplete]="autoVendor"
                                    [matChipInputFor]="chipListBV"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="vendor_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoVendor="matAutocomplete" (optionSelected)="search($event, 'vendor')">
                                <mat-option *ngFor="let option of filteredList(filteredVendorList, 'vendor')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('vendor')" *ngIf="filterForm.value.vendor.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    
                </div>
                <!--Balance Scorecard Filter End-->
                <!--Plan Center Filter-->
                <div class="vscroll" id="style-3" *ngIf="pagetype == 'turnkey-tracker'"  [ngStyle]="{'width': pagetype == 'turnkey-tracker' ? '100%' : '', 'padding':  pagetype == 'turnkey-tracker' ? '0px 0 0px 0' : ''}">
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="owner">
                            <mat-chip-list #chipListTKT1 aria-label="Owner selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.owner.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'owner')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search owner..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipListTKT1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="owner_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'owner')">
                                <mat-option *ngFor="let option of filteredList(filteredOwnerList, 'owner')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('owner')" *ngIf="filterForm.value.owner.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="workgroup">
                            <mat-chip-list #chipListTKT2 aria-label="Sub Workgroup selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.workgroup.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'workgroup')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search workgroup..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipListTKT2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'workgroup')">
                                <mat-option *ngFor="let option of filteredList(filteredWorkgroupList, 'workgroup')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('workgroup')" *ngIf="filterForm.value.workgroup.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="tag">
                            <mat-chip-list #chipListTKT3 aria-label="Sub tag selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.tag.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'tag')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search tag..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipListTKT3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="tag_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'tag')">
                                <mat-option *ngFor="let option of filteredList(filteredTagList, 'tag')" [value]="option" style="font-size: 0.8rem; height: 30px;" matTooltip="{{option.name}} - {{option.tag_category}}">
                                    <b>{{option.name}}</b> - {{option.tag_category}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('tag')" *ngIf="filterForm.value.tag.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    
                </div>
                <!--Plan Center Filter End-->
                <!--Crowdsourcing Filter-->
                <div class="vscroll" id="style-3" *ngIf="pagetype == 'crowdsourcing' || pagetype == 'crowdsourcing-workbench'"  [ngStyle]="{'width': pagetype == 'crowdsourcing' ? '100%' : '', 'padding':  pagetype == 'crowdsourcing' ? '0px 0 0px 0' : ''}">
                    <div class="form-item" *ngIf="pagetype == 'crowdsourcing-workbench'">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="portfolio">
                            <mat-chip-list #chipList1 aria-label="Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.portfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'portfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search portfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="portfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'portfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredPortfolioList, 'portfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('portfolio')" *ngIf="filterForm.value.portfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item" *ngIf="pagetype == 'crowdsourcing-workbench'">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subportfolio">
                            <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subportfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subportfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search subportfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'subportfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredSubPortfolioList, 'subportfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subportfolio')" *ngIf="filterForm.value.subportfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item" *ngIf="pagetype == 'crowdsourcing-workbench'">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="program">
                            <mat-chip-list #chipList3 aria-label="Sub program selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.program.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'program')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search program..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="program_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'program')">
                                <mat-option *ngFor="let option of filteredList(filteredProgramList, 'program')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('program')" *ngIf="filterForm.value.program.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="tag">
                            <mat-chip-list #chipListTKT3 aria-label="Sub tag selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.tag.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'tag')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search tag..."
                                    formControlName="input"
                                    [matAutocomplete]="autoCrowdTag"
                                    [matChipInputFor]="chipListTKT3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="tag_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoCrowdTag="matAutocomplete" (optionSelected)="search($event, 'tag')">
                                <mat-option *ngFor="let option of filteredList(filteredTagList, 'tag')" [value]="option" style="font-size: 0.8rem; height: 30px;" matTooltip="{{option.name}} - {{option.tag_category}}">
                                    <b>{{option.name}}</b> - {{option.tag_category}} 
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('tag')" *ngIf="filterForm.value.tag.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    
                </div>
                <!--Plan Center Filter End-->

                <!--Risk Coverage Filter-->

                <div class="vscroll" id="style-3" *ngIf="pagetype == 'riskcoverage'" >
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="portfolio">
                            <mat-chip-list #chipList1 aria-label="Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.portfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'portfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search portfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="auto"
                                    [matChipInputFor]="chipList1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="portfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="search($event, 'portfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredPortfolioList, 'portfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('portfolio')" *ngIf="filterForm.value.portfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subportfolio">
                            <mat-chip-list #chipList2 aria-label="Sub Portfolio selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subportfolio.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subportfolio')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search subportfolio..."
                                    formControlName="input"
                                    [matAutocomplete]="autoSub"
                                    [matChipInputFor]="chipList2"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="subportfolio_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoSub="matAutocomplete" (optionSelected)="search($event, 'subportfolio')">
                                <mat-option *ngFor="let option of filteredList(filteredSubPortfolioList, 'subportfolio')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subportfolio')" *ngIf="filterForm.value.subportfolio.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="project">
                            <mat-chip-list #chipList5 aria-label="Sub project selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.project.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'project')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search project..."
                                    formControlName="input"
                                    [matAutocomplete]="autoProject"
                                    [matChipInputFor]="chipList5"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="project_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoProject="matAutocomplete" (optionSelected)="search($event, 'project')">
                                <mat-option *ngFor="let option of filteredList(filteredProjectList, 'project')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('project')" *ngIf="filterForm.value.project.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="program">
                            <mat-chip-list #chipList3 aria-label="Sub program selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.program.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'program')">
                                    {{item. name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search program..."
                                    formControlName="input"
                                    [matAutocomplete]="autoPro"
                                    [matChipInputFor]="chipList3"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="program_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoPro="matAutocomplete" (optionSelected)="search($event, 'program')">
                                <mat-option *ngFor="let option of filteredList(filteredProgramList, 'program')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('program')" *ngIf="filterForm.value.program.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="domain">
                            <mat-chip-list #chipListdomain1 aria-label="domain selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.domain.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'domain')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search domain..."
                                    formControlName="input"
                                    [matAutocomplete]="autoDomain"
                                    [matChipInputFor]="chipListdomain1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="domain_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoDomain="matAutocomplete" (optionSelected)="search($event, 'domain')">
                                <mat-option *ngFor="let option of filteredList(FilterDomainList, 'domain')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('domain')" *ngIf="filterForm.value.domain.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="subdomain">
                            <mat-chip-list #chipListsubdomain1 aria-label="Sub domain selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.subdomain.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'subdomain')">
                                    {{item.display_name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search sub domain..."
                                    formControlName="input"
                                    [matAutocomplete]="autosubdom"
                                    [matChipInputFor]="chipListsubdomain1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="sub_domain_name">
                            </mat-chip-list>
                            <mat-autocomplete #autosubdom="matAutocomplete" (optionSelected)="search($event, 'subdomain')">
                                <mat-option *ngFor="let option of filteredList(FilterSubDomainList, 'subdomain')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.display_name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('subdomain')" *ngIf="filterForm.value.subdomain.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="riskresponse">
                            <mat-chip-list #chipListriskresponse1 aria-label="Risk Response selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.riskresponse.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'riskresponse')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search risk response..."
                                    formControlName="input"
                                    [matAutocomplete]="autoriskresponse"
                                    [matChipInputFor]="chipListriskresponse1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="risk_response_name">
                            </mat-chip-list>
                            <mat-autocomplete #autoriskresponse="matAutocomplete" (optionSelected)="search($event, 'riskresponse')">
                                <mat-option *ngFor="let option of filteredList(FilterRiskResponseList, 'riskresponse')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('riskresponse')" *ngIf="filterForm.value.riskresponse.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="form-item">
                        <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="riskstatus">
                            <mat-chip-list #chipListRiskStatus1 aria-label="Risk status selection">
                                <mat-chip
                                    *ngFor="let item of filterForm?.value.riskstatus.array"
                                    [selectable]="selectable"
                                    (removed)="remove(item, 'riskstatus')">
                                    {{item.name}}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                                <input
                                    placeholder="Search risk status..."
                                    formControlName="input"
                                    [matAutocomplete]="autoriskstatus"
                                    [matChipInputFor]="chipListRiskStatus1"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    [matChipInputAddOnBlur]="addOnBlur"
                                    name="risk_response">
                            </mat-chip-list>
                            <mat-autocomplete #autoriskstatus="matAutocomplete" (optionSelected)="search($event, 'riskstatus')">
                                <mat-option *ngFor="let option of filteredList(FilterRiskStatusList, 'riskstatus')" [value]="option" style="font-size: 0.8rem; height: 30px;">
                                    {{option.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('riskstatus')" *ngIf="filterForm.value.riskstatus.array.length > 0">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
               </div>
               
               <!--Risk Coverage Filter end-->

               <!--parade Filter-->
               <div class="vscroll" id="style-3" *ngIf="pagetype == 'parade'"  [ngStyle]="{'width': pagetype == 'parade' ? '100%' : '', 'padding':  pagetype == 'parade' ? '0px 0 0px 0' : ''}">
                <div class="form-item">
                    <mat-form-field class="example-full-width" style="width: 100%" appearance="outline" formGroupName="tag">
                        <mat-chip-list #chipListTKT33 aria-label="Sub tag selection">
                            <mat-chip
                                *ngFor="let item of filterForm?.value.tag.array"
                                [selectable]="selectable"
                                (removed)="remove(item, 'tag')">
                                {{item.name}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input
                                placeholder="Search tag..."
                                formControlName="input"
                                [matAutocomplete]="autoCrowdTag"
                                [matChipInputFor]="chipListTKT33"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                name="tag_name">
                        </mat-chip-list>
                        <mat-autocomplete #autoCrowdTag="matAutocomplete" (optionSelected)="search($event, 'tag')">
                            <mat-option *ngFor="let option of filteredList(filteredTagList, 'tag')" [value]="option" style="font-size: 0.8rem; height: 30px;" matTooltip="{{option.name}} - {{option.tag_category}}">
                                <b>{{option.name}}</b> - {{option.tag_category}} 
                            </mat-option>
                        </mat-autocomplete>
                        <button mat-button mat-icon-button matSuffix class="close-btn" (click)="clearSearchField('tag')" *ngIf="filterForm.value.tag.array.length > 0">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                
            </div>
            
            </div>
        </div>
    </form>
</div>

