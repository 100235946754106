import { Component, Input, OnInit, Output, EventEmitter, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidatorService } from '../../services/form-validator/form-validator.service';
import { of } from "rxjs";
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import {
  debounceTime,
  map,
  distinctUntilChanged,
  switchMap,
  filter,
  startWith
} from "rxjs/operators";
import { fromEvent } from 'rxjs';
import { ProjectService } from '../../services/project.service';
import { FilterService } from '../../services/filter.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
@Component({
  selector: 'app-filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: ['./filter-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterCardComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() pagetype: any;
  @Input() queryParam: any = "";
  isFilterhasValue:boolean = false;

  portFolios: any;
  portfolioList: any;

  filteredPortfolioList: any;
  filterForm: FormGroup | any;

  filteredSubPortfolioList: any;
  subPortfolioList: any;

  filteredProgramList: any;
  programList: any;

  filteredOpportunityList: any;
  oppurtunityList: any;

  filteredOppStatusList:any;
  oppStatusList:any;

  filteredProjectList: any;
  projectList: any;

  filteredVendorList: any;
  vendorList: any;

  filteredTagList: any;
  tagList: any;

  FilteredapplicationMasterFilterList:any;
  applicationMasterList:any;

  FilteredWorkForceMonthFilterList:any;
  workforceMonthList:any = this.staticText?.delivery?.month_list;

  FilteredWorkforceFilterList:any;
  workforceList:any;

  FilteredOwnerFilterList:any;
  ownerList:any;

  FilteredWorkgroupFilterList:any;
  workgroupList:any;

  FilterDomainList:any;
  domainList:any;

  FilterSubDomainList:any;
  subDomainList:any

  FilterRiskResponseList:any=[{id:'Accept',name:'Accept'},{id:'Mitigate',name:'Mitigate'},{id:'Transfer',name:'Transfer'}];
  riskResponseList:any=[{id:'Accept',name:'Accept'},{id:'Mitigate',name:'Mitigate'},{id:'Transfer',name:'Transfer'}];

  FilterRiskStatusList:any = [{id:16,name:'Open'},{id:39,name:'Closed'}];
  riskStatusList:any = [{id:16,name:'Open'},{id:39,name:'Closed'}];

  constructor(private projectService: ProjectService, private filterService: FilterService, private formBuilder: FormBuilder) { 
    
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      portfolio: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      subportfolio: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      program: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      opportunity: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      project: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      vendor: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      tag: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      oppStatus:this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      applicationTier: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      applicationSupportGroup: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      applicationBusinessOwner: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      applicationBusinessArea: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      workforceMonth: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      workforceJobCategory: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      workforceClassification: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      workforceCountry: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      owner: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      workgroup: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      domain: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      subdomain: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      riskresponse: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      }),
      riskstatus: this.formBuilder.group({
        array: this.formBuilder.array([]),
        input: new FormControl(''),
      })
    });

    if(this.pagetype == 'opportunity' || this.pagetype == 'project'){
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getVendor();
      this.getTag();
    };
    if(this.pagetype == 'opportunity'){ 
      this.getOpportunity();
      this.getOppStatus();
    };
    if(this.pagetype == 'project'){
      this.getProject();
    };

    if(this.pagetype == 'application'){
      this.getApplicationsFilters();
    }
    if(this.pagetype == 'workforce'){
      this.getWorkforceFilters();
      this.getVendor();
    }
    if(this.pagetype == 'people-insights'){
      this.getWorkforceFilters();
      this.getVendor();
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
    }
    if(this.pagetype == 'balance-scorecard'){
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getVendor();
    }
    if(this.pagetype == 'crowdsourcing'){
      // this.getPortfolios();
      // this.getSubPortfolios();
      // this.getProgram();
      this.getTag();
    }

    if(this.pagetype == 'parade'){
      this.getTag();
    }

    if(this.pagetype == 'crowdsourcing-workbench'){
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getTag();
    }
    if(this.pagetype == 'riskcoverage' ){
      this.getPortfolios();
      this.getSubPortfolios();
      this.getProgram();
      this.getProject();
      this.getDomain();
      this.getSubDomain();
    }

    this.filterService.setValue('');

  }

  setQueryParam(field: any){
    if(this.queryParam[field]){
      let obj = [];
      if(field == 'portfolio'){
        obj = this.portfolioList.filter((e: any) => e.id == Number(this.queryParam[field]));
      }else if(field == 'subportfolio'){
        obj = this.subPortfolioList.filter((e: any) => e.id == Number(this.queryParam[field]));
      }if(field == 'program'){
        obj = this.programList.filter((e: any) => e.id == Number(this.queryParam[field]));
      }
      this.filterForm.value[field].array = obj;
      this.onOptionSelectionChanged(field);
    }else if(this.filterForm.value[field].array.length > 0 && Object.keys(this.queryParam).length == 0){
      this.filterForm.value[field].array.value = [];
      this.filterForm.controls[field].controls['input'].setValue("");
      this.onOptionSelectionChanged(field);
    }
  }

  async getPortfolios(){
    await this.filterService.getPortfolios().subscribe((res: any) => {
      this.filteredPortfolioList = res.records;
      this.portfolioList = res.records;

      this.setQueryParam('portfolio');
    })
  }
  async getSubPortfolios(){
    this.filteredSubPortfolioList =[];
    this.subPortfolioList=[];
    await this.filterService.getSubPortfolios(this.filterForm.value.portfolio.input.id).subscribe((res: any) => {
      if(this.filterForm.value.portfolio.input.id){
        res = res.records;
      }
      this.filteredSubPortfolioList = res;
      this.subPortfolioList = res;

      this.setQueryParam('subportfolio');
    })
  }
  async getProgram(){
    await this.filterService.getProgram(this.filterForm.value.subportfolio.input.id).subscribe((res: any) => {
      if(this.filterForm.value.subportfolio.input.id){
        res = res.records;
      }
      this.programList = res;
      this.programList = this.programList.map((val:any)=>({id:val.id,manager:val.manager,name:val.name,level:'program'}));
      this.filteredProgramList = this.programList;
      this.setQueryParam('program');
    })
  }
  async getOpportunity(){
    await this.filterService.getOpportunity(this.filterForm.value.subportfolio.input.id,this.filterForm.value.program.input.id).subscribe((res: any) => {
      if(this.filterForm.value.subportfolio.input.id && this.filterForm.value.program.input.id){
        res = res.records;
      }
      res.forEach((element: any) => {
        element.name = element.opportunity_name
      });
      this.filteredOpportunityList = res;
      this.oppurtunityList = res;
    })
  }
  async getOppStatus(){
    await this.filterService.getOppStatus().subscribe((res:any)=>{
      res.forEach((element: any) => {
        element.name = element.label;
      });
      res = res.filter((item:any) => item.label !== 'Cancelled');
      this.filteredOppStatusList = res;
      this.oppStatusList = res;
    })
  }
  async getProject(){
    await this.filterService.getProject(this.filterForm.value.subportfolio.input.id,this.filterForm.value.program.input.id).subscribe((res: any) => {
      if(this.filterForm.value.subportfolio.input.id && this.filterForm.value.program.input.id){
        res = res.records;
      }
      res.forEach((element: any) => {
        element.name = element.project_name
      });
      this.filteredProjectList = res;
      this.projectList = res;
    })
  }
  async getDomain(){
    await this.filterService.getDomain().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.display_name
      });
      this.FilterDomainList = res;
      this.domainList = res;
    })
  }

  async getSubDomain(){
    await this.filterService.getSubDomain().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.display_name
      });
      this.FilterSubDomainList = res;
      this.subDomainList = res;
    })
  }
  async getVendor(){
    await this.filterService.getVendor().subscribe((res: any) => {
      this.filteredVendorList = res;
      this.vendorList = res;
    })
  }
  async getTag(){
    await this.filterService.getTag().subscribe((res: any) => {
      res.forEach((element: any) => {
        element.name = element.tag_name
      });
      this.filteredTagList = res;
      this.tagList = res;
    })
  }

  getPortfolioName(portfolioId: any) {
    if(portfolioId){
      return this.portfolioList.find((item: any) => item.id === Number(portfolioId)).name;
    }
  }

  getSubPortfolioName(subPortfolioId: any) {
    if(subPortfolioId){
      return this.subPortfolioList.find((item: any) => item.id === Number(subPortfolioId)).display_name;
    }
  }

  getProgramName(programId: any) {
    if(programId){
      return this.programList.find((item: any) => item.id === Number(programId)).name;
    }
  }

  getOpportunityName(opportunityId: any) {
    if(opportunityId){
      return this.oppurtunityList.find((item: any) => item.id === opportunityId).opportunity_name
    }
  }

  getProjectName(projectId: any) {
    if(projectId){
      return this.projectList.find((item: any) => item.id === projectId).project_name;
    }
  }

  getVendorName(vendorId: any) {
    if(vendorId){
      return this.vendorList.find((item: any) => item.id === vendorId).name;
    }
  }

  getTagName(tagId: any) {
    if(tagId){
      return this.tagList.find((item: any) => item.id === tagId).tag_name;
    }
  }

  getDomainName(domaonId: any) {
    if(domaonId){
      return this.domainList.find((item: any) => item.id === domaonId).display_name;
    }
  }

  getSubDomainName(subdomaonId: any) {
    if(subdomaonId){
      return this.subDomainList.find((item: any) => item.id === subdomaonId).display_name;
    }
  }

  getRiskResponseName(response:any){
    if(response){
      return this.riskResponseList.find((item: any) => item.id === response).name;
    }
  }

  getRiskStatusName(status:any){
    if(status){
      return this.riskStatusList.find((item: any) => item.id === status).name;
    }
  }

  searchSubPortfolio(){
    return this.getSubPortfolioList()
  }

  searchProgram(){
    return this.getProgramList()
  }

  searchOpportunity(){
    return this.getOpportunityList()
  }

  searchProject(){
    return this.getProjectList()
  }

  searchVendor(){
    return this.getVendorList()
  }

  searchTag(){
    return this.getTagList()
  }

  searchDomain(){
    return this.getDomainList()
  }

  searchSubDomain(){
    return this.getSubDomainList()
  }

  searchRiskResponse(){
    return this.getRiskResponseList()
  }
  searchRiskStatus(){
    return this.getRiskStatusList()
  }

  filteredList(filterObj:any, field: any){
    if(filterObj){
      let filteringArray = this.filterForm.value[field].array ? this.filterForm.value[field].array : [];
      var filteredArray  = filteringArray.length > 0 ? filterObj.filter(function(array_el: any){
          return filteringArray.filter(function(innerel: any){
            return innerel.id == array_el.id;
          }).length == 0
      }) : filterObj;
      filteredArray = typeof this.filterForm.value[field].input === 'string' ? 
        filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value[field].input.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  filteredListNoName(filterObj:any, field: any){
    if(filterObj){
      let filteringArray = this.filterForm.value[field].array ? this.filterForm.value[field].array : [];
      var filteredArray  = filteringArray.length > 0 ? filterObj.filter(function(array_el: any){
          return filteringArray.filter(function(innerel: any){
            return innerel.id == array_el.id;
          }).length == 0
      }) : filterObj;

      filteredArray = typeof this.filterForm.value[field].input === 'string' ? 
        filteredArray.filter((f: any) => f.display_name.toLowerCase().indexOf(this.filterForm.value[field].input.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  search(event: MatAutocompleteSelectedEvent, field: any): void {
    this.filterForm.value[field].array.push(event.option.value);
    this.onOptionSelectionChanged(field)
  }

  remove(val: string, field: any): void {
    const index = this.filterForm.value[field].array.indexOf(val);
    if (index >= 0) {
      this.filterForm.value[field].array.splice(index, 1);
      this.filterService.setValue(this.filterForm.value);
    }
    this.groupHasValues(this.filterForm);
  }

  clearSearchField(field: any){
    this.filterForm.controls[field].controls['array'].setValue([]);
    this.onOptionSelectionChanged(field);
    this.groupHasValues(this.filterForm);
  }

  onOptionSelectionChanged(field: any = null){
    if(field == 'portfolio'){
      this.getSubPortfolios();
    }
    if(field == 'subportfolio'){
      this.getProgram();
    }
    if(field == 'program' && this.pagetype == 'opportunity'){
      this.getOpportunity();
    }
    if(field == 'program' && this.pagetype == 'project'){
      this.getProject();
    }
   
    for (var key in this.filterForm.value) {
      if (key != field) {
        // this.filterForm.controls[key].controls['array'].setValue([]);
          // this.filterForm.controls[key].controls['input'].setValue("");
      }
  }
    this.filterService.setFilterField(field);
    this.filterService.setValue(this.filterForm.value);
    this.groupHasValues(this.filterForm);
  }



  // getPortfolioList(){
  //   if(this.portfolioList){
  //     let filteringArray = this.filterForm.value.portfolio.value ? this.filterForm.value.portfolio.value : [];
  //     var filteredArray  = filteringArray.length > 0 ? this.filteredPortfolioList.filter(function(array_el: any){
  //       return filteringArray.filter(function(chl: any){
  //           return chl.portfolio.value == array_el.id;
  //       }).length == 0
  //     }) : this.portfolioList;

  //     filteredArray = typeof this.filterForm.value.portfolio === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value.portfolio.toLowerCase()) === 0) : filteredArray;
  //     return filteredArray;
  //   }else{
  //     return [];
  //   }
  // }

  getSubPortfolioList(){
    if(this.subPortfolioList){
      let filteringArray = this.filterForm.value.subportfolio.value ? this.filterForm.value.subportfolio.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.filteredSubPortfolioList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.subportfolio.value == array_el.id;
        }).length == 0
      }) : this.subPortfolioList;

      filteredArray = typeof this.filterForm.value.subportfolio === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value.subportfolio.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getProgramList(){
    if(this.programList){
      let filteringArray = this.filterForm.value.program.value ? this.filterForm.value.program.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.filteredProgramList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.program.value == array_el.id;
        }).length == 0
      }) : this.programList;

      filteredArray = typeof this.filterForm.value.program === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value.program.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getOpportunityList(){
    if(this.oppurtunityList){
      let filteringArray = this.filterForm.value.opportunity.value ? this.filterForm.value.opportunity.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.filteredOpportunityList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.opportunity.value == array_el.id;
        }).length == 0
      }) : this.oppurtunityList;

      filteredArray = typeof this.filterForm.value.opportunity === 'string' ? filteredArray.filter((f: any) => f.opportunity_name.toLowerCase().indexOf(this.filterForm.value.opportunity.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getOppStatusList(){
    if(this.oppStatusList){
      let filteringArray = this.filterForm.value.oppStatus.value ? this.filterForm.value.oppStatus.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.filteredOppStatusList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.oppStatus.value == array_el.id;
        }).length == 0
      }) : this.oppStatusList;

      filteredArray = typeof this.filterForm.value.oppStatus === 'string' ? filteredArray.filter((f: any) => f.label.toLowerCase().indexOf(this.filterForm.value.oppStatus.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getProjectList(){
    if(this.projectList){
      let filteringArray = this.filterForm.value.project.value ? this.filterForm.value.project.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.filteredProjectList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.project.value == array_el.id;
        }).length == 0
      }) : this.projectList;

      filteredArray = typeof this.filterForm.value.project === 'string' ? filteredArray.filter((f: any) => f.project_name.toLowerCase().indexOf(this.filterForm.value.project.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getVendorList(){
    if(this.vendorList){
      let filteringArray = this.filterForm.value.vendor.value ? this.filterForm.value.vendor.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.filteredVendorList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.vendor.value == array_el.id;
        }).length == 0
      }) : this.vendorList;

      filteredArray = typeof this.filterForm.value.vendor === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value.vendor.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getTagList(){
    if(this.tagList){
      let filteringArray = this.filterForm.value.tag.value ? this.filterForm.value.tag.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.filteredTagList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.tag.value == array_el.id;
        }).length == 0
      }) : this.tagList;

      filteredArray = typeof this.filterForm.value.tag === 'string' ? filteredArray.filter((f: any) => f.tag_name.toLowerCase().indexOf(this.filterForm.value.tag.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getDomainList(){
    if(this.domainList){
      let filteringArray = this.filterForm.value.domain.value ? this.filterForm.value.domain.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.FilterDomainList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.domain.value == array_el.id;
        }).length == 0
      }) : this.domainList;

      filteredArray = typeof this.filterForm.value.domain === 'string' ? filteredArray.filter((f: any) => f.display_name.toLowerCase().indexOf(this.filterForm.value.domain.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getSubDomainList(){
    if(this.subDomainList){
      let filteringArray = this.filterForm.value.subdomain.value ? this.filterForm.value.subdomain.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.FilterSubDomainList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.subdomain.value == array_el.id;
        }).length == 0
      }) : this.subDomainList;

      filteredArray = typeof this.filterForm.value.subdomain === 'string' ? filteredArray.filter((f: any) => f.display_name.toLowerCase().indexOf(this.filterForm.value.subdomain.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }

  getRiskResponseList(){
    if(this.riskResponseList){
      let filteringArray = this.filterForm.value.riskresponse.value ? this.filterForm.value.riskresponse.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.FilterRiskResponseList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.riskresponse.value == array_el.id;
        }).length == 0
      }) : this.riskResponseList;

      filteredArray = typeof this.filterForm.value.riskresponse === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value.riskresponse.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }
  getRiskStatusList(){
    if(this.riskStatusList){
      let filteringArray = this.filterForm.value.riskstatus.value ? this.filterForm.value.riskstatus.value : [];
      var filteredArray  = filteringArray.length > 0 ? this.FilterRiskStatusList.filter(function(array_el: any){
        return filteringArray.filter(function(chl: any){
            return chl.riskstatus.value == array_el.id;
        }).length == 0
      }) : this.riskStatusList;

      filteredArray = typeof this.filterForm.value.riskstatus === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.filterForm.value.riskstatus.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    }else{
      return [];
    }
  }


  //Application Master code
  
  async getApplicationsFilters(){
    await this.filterService.getApplicationFilters().subscribe((res: any) => {
      let unique_tiers: any = []
      res.unique_tiers.forEach((element: any) => {
        unique_tiers.push({
          id: element,
          display_name: element
        })
      })
      let unique_support_groups: any = []
      res.unique_support_groups.forEach((element: any) => {
        unique_support_groups.push({
          id: element,
          display_name: element
        })
      })
      let unique_business_owners: any = []
      res.unique_business_owners.forEach((element: any) => {
        unique_business_owners.push({
          id: element,
          display_name: element
        })
      })
      let unique_business_areas: any = []
      res.unique_business_areas.forEach((element: any) => {
        unique_business_areas.push({
          id: element,
          display_name: element
        })
      })
      this.FilteredapplicationMasterFilterList = {
        unique_tiers: unique_tiers,
        unique_support_groups: unique_support_groups,
        unique_business_owners: unique_business_owners,
        unique_business_areas: unique_business_areas
      };

      this.applicationMasterList = this.FilteredapplicationMasterFilterList;
    })
  }
//Applcation Tier
  searchApplicationsTier(){
    return this.getApplicationTierList()
  }

  getApplicationTierList(){
      if(this.applicationMasterList?.unique_tiers?.length > 0){
        let filteringArray = this.filterForm.value.applicationTier.value ? this.filterForm.value.applicationTier.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredapplicationMasterFilterList?.unique_tiers.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.applicationTier.value == array_el;
          }).length == 0
        }) : this.applicationMasterList?.unique_tiers;
  
        filteredArray = typeof this.filterForm.value.applicationTier === 'string' ? filteredArray.filter((f: any) => f.toLowerCase().indexOf(this.filterForm.value.applicationTier.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
  getApplicationsTier(tierName: any) {
    if(tierName){
      return tierName;
    }
  }

  //Support Group
  searchApplicationsSupportGroup(){
    return this.getApplicationSupportGroupList()
  }

  getApplicationSupportGroupList(){
      if(this.applicationMasterList?.unique_support_groups?.length > 0){
        let filteringArray = this.filterForm.value.applicationSupportGroup.value ? this.filterForm.value.applicationSupportGroup.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredapplicationMasterFilterList?.unique_support_groups.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.applicationSupportGroup.value == array_el;
          }).length == 0
        }) : this.applicationMasterList?.unique_support_groups;
  
        filteredArray = typeof this.filterForm.value.applicationSupportGroup === 'string' ? filteredArray.filter((f: any) => f.toLowerCase().indexOf(this.filterForm.value.applicationSupportGroup.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
  getApplicationsSupportGroup(supportGroupName: any) {
    if(supportGroupName){
      return supportGroupName;
    }
  }
  //Business owners
  searchApplicationsBusinessOwner(){
    return this.getApplicationBusinessOwnerList()
  }

  getApplicationBusinessOwnerList(){
      if(this.applicationMasterList?.unique_business_owners?.length > 0){
        let filteringArray = this.filterForm.value.applicationBusinessOwner.value ? this.filterForm.value.applicationBusinessOwner.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredapplicationMasterFilterList?.unique_business_owners.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.applicationBusinessOwner.value == array_el;
          }).length == 0
        }) : this.applicationMasterList?.unique_business_owners;
  
        filteredArray = typeof this.filterForm.value.applicationBusinessOwner === 'string' ? filteredArray.filter((f: any) => f.toLowerCase().indexOf(this.filterForm.value.applicationBusinessOwner.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
  getApplicationsBusinessOwner(BusinessOwner: any) {
    if(BusinessOwner){
      return BusinessOwner;
    }
  }
  //Business Area
  searchApplicationsBusinessArea(){
    return this.getApplicationBusinessAreaList()
  }

  getApplicationBusinessAreaList(){
      if(this.applicationMasterList?.unique_business_areas?.length > 0){
        let filteringArray = this.filterForm.value.applicationBusinessArea.value ? this.filterForm.value.applicationBusinessArea.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredapplicationMasterFilterList?.unique_business_areas.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.applicationBusinessArea.value == array_el;
          }).length == 0
        }) : this.applicationMasterList?.unique_business_areas;
  
        filteredArray = typeof this.filterForm.value.applicationBusinessArea === 'string' ? filteredArray.filter((f: any) => f.toLowerCase().indexOf(this.filterForm.value.applicationBusinessArea.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
  getApplicationsBusinessArea(BusinessArea: any) {
    if(BusinessArea){
      return BusinessArea;
    }
  }
  //workforce filter Started

  async getWorkforceFilters(){
    await this.filterService.getWorkforceFilters().subscribe((res: any) => {
      let job_category: any = []
      res.job_category.forEach((element: any) => {
        job_category.push({
          id: element,
          display_name: element
        })
      })
      let classification: any = []
      res.classification.forEach((element: any) => {
        classification.push({
          id: element,
          display_name: element
        })
      })
      let country: any = []
      res.country.forEach((element: any) => {
        country.push({
          id: element,
          display_name: element
        })
      })
      this.FilteredWorkforceFilterList = {
        job_category: job_category,
        classification: classification,
        country: country
      };

      this.workforceList = this.FilteredWorkforceFilterList;
    })
  }
 
  //Workforce filter for month
  searchWorkforceMonth(){
    return this.getWorkforceMonthList()
  }

  getWorkforceMonthList(){
      if(this.workforceMonthList?.length > 0){
        let filteringArray = this.filterForm.value.workforceMonth.value ? this.filterForm.value.workforceMonth.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredWorkForceMonthFilterList.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.workforceMonth.value == array_el.id;
          }).length == 0
        }) : this.workforceMonthList;
  
        filteredArray = typeof this.filterForm.value.workforceMonth === 'string' ? filteredArray.filter((f: any) => f.display_name.toLowerCase().indexOf(this.filterForm.value.workforceMonth.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
 
  getWorkforceMonth(monthId: any) {
    if(monthId){
      return this.workforceMonthList.find((item: any) => item.id === monthId).display_name;
    }
  }

  //Workforce filter for Job Category
  searchWorkforceJobCategory(){
    return this.getWorkforceJobCategoryList()
  }

  getWorkforceJobCategoryList(){
      if(this.workforceList?.job_category?.length > 0){
        let filteringArray = this.filterForm.value.workforceJobCategory.value ? this.filterForm.value.workforceJobCategory.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredWorkforceFilterList?.job_category.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.workforceJobCategory.value == array_el;
          }).length == 0
        }) : this.workforceList?.job_category;
  
        filteredArray = typeof this.filterForm.value.workforceJobCategory === 'string' ? filteredArray.filter((f: any) => f.toLowerCase().indexOf(this.filterForm.value.workforceJobCategory.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
  getWorkforceJobCategory(jobCategory: any) {
    if(jobCategory){
      return jobCategory;
    }
  }

  //Workforce filter for Classification
  searchWorkforceClassification(){
    return this.getWorkforceClassificationList()
  }

  getWorkforceClassificationList(){
      if(this.workforceList?.classification?.length > 0){
        let filteringArray = this.filterForm.value.workforceClassification.value ? this.filterForm.value.workforceClassification.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredWorkforceFilterList?.classification.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.workforceClassification.value == array_el;
          }).length == 0
        }) : this.workforceList?.classification;
  
        filteredArray = typeof this.filterForm.value.workforceClassification === 'string' ? filteredArray.filter((f: any) => f.toLowerCase().indexOf(this.filterForm.value.workforceClassification.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
  getWorkforceClassification(classification: any) {
    if(classification){
      return classification;
    }
  }

  //Workforce filter for Country
  searchWorkforceCountry(){
    return this.getWorkforceCountryList()
  }

  getWorkforceCountryList(){
      if(this.workforceList?.country?.length > 0){
        let filteringArray = this.filterForm.value.workforceCountry.value ? this.filterForm.value.workforceCountry.value : [];
        var filteredArray  = filteringArray.length > 0 ? this.FilteredWorkforceFilterList?.country.filter(function(array_el: any){
          return filteringArray.filter(function(chl: any){
              return chl.workforceCountry.value == array_el;
          }).length == 0
        }) : this.workforceList?.country;
  
        filteredArray = typeof this.filterForm.value.workforceCountry === 'string' ? filteredArray.filter((f: any) => f.toLowerCase().indexOf(this.filterForm.value.workforceCountry.toLowerCase()) === 0) : filteredArray;
        return filteredArray;
      }else{
        return [];
      }
  }
  getWorkforceCountry(country: any) {
    if(country){
      return country;
    }
  }

  groupHasValues(formBuilderGroup:any) {
    let flag:any = false;
    Object.keys(formBuilderGroup.value).forEach((key:any) => {
      if(formBuilderGroup.value[key]['array'].length > 0){
        flag = true;
        this.isFilterhasValue = true;
      }
    });
    if(flag == false){
      this.isFilterhasValue = false;
    }
  }

  clearAllFilter(){
    for (var key in this.filterForm.value) {
        this.filterForm.controls[key].controls['array'].setValue([]);
        this.filterForm.controls[key].controls['input'].setValue("");
    }
    this.filterService.setFilterField('');
    this.filterService.setValue(this.filterForm.value);
    this.isFilterhasValue = false;
  }


  
}
