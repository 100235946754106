@Pipe({
  name: 'formGroupNameScope'
})
export class FormGroupNameScopePipe {
  transform(item: any, stepper: any, arr: any, is_custom: boolean = false) {
    let formGroupNameIndex = 1000000;
    try {
      arr?.controls?.forEach((res: any, index: any) => {
        if (res?.controls?.uuid_name?.value == item?.scope_uuid && (res?.controls?.category?.value == stepper?.display_name)) {
          formGroupNameIndex = index;;
          throw BreakException;
        }
      });
    } catch (e) {
      if (e != BreakException) {
        throw e
      }
    }
    return formGroupNameIndex;
  }
}

import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Pipe, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BreadcrumbService } from 'src/app/shared/services/breadcrumb.service';
import { DataService } from 'src/app/shared/services/data.service';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ScopeService } from 'src/app/shared/services/scope.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
var _ = require('lodash');
var BreakException = {};
@Component({
  selector: 'app-shared-scope',
  templateUrl: './scope.component.html',
  styleUrls: ['./scope.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [FormGroupNameScopePipe]
})
export class SharedScopeComponent implements OnInit, OnDestroy {
  staticText: any = (textConfiguration as any).default;
  @Input() entity: any;
  title: string;
  info: string;





  @ViewChild('stepper') stepper: MatStepper | any;
  stepStates: any = [];
  opportunityId: any;
  initalLoad = false;
  side = false;
  side1 = false;
  searchText = '';
  search = false;
  showAddField: boolean | any;
  scopeItems: any;
  allscopeItems: any;
  allcustomScopeItems: any;
  newScopeItems: any;
  textContents = {
    searchText: 'Parameter searched is not found in the list'
  }
  sideHelp = true;
  selectedItems: any[] = [];
  updateItems: any;
  tips = true;
  scopeList: any[] = [];
  parameter: any;
  stepperCategory: any[] = [];
  cloneStepperCategory: any[] = [];
  cloneCustomCategory: any[] = [];
  sideParam = false;
  newParameterItems: any;
  selectedParameterItems: any[] = [];
  parameterForm: FormBuilder | any;
  stepperIndex = 0;
  parameteClick: any;
  addedParameterItems: any;
  sideParameter: any;
  submitted = false;
  newArr: FormArray | any;
  scope_data: any = {};
  parameterIndex: any;
  addColumnIndex = 0;
  showNext = false;
  showUpload = false;
  showAddCustom = false;
  showCustomOnly = false;
  showAddCustomParameter = false;
  CusParameterName: any;
  cusScopeDescription: any;
  cusScopeTitle: any;
  customScopeParameter: any;
  newCustomParameterItems: any;
  categoryName: any;
  customCategory: any;
  showCustomScope = false;
  customScopeItems: any;
  sideScopeParameter: any;
  newCustomScopeItems: any;
  newCustomCategory: any;
  showCustomButton = true;
  selectedCategoryItems: any[] = [];
  customTab = false;
  selectedScope: any[] = [];
  scopeIndex: any;
  totalFiles: any[] = [];
  matTab = true;
  opportunityInfo: any;

  public tabIndex1 = 0;
  itemCategory: any;
  name: any;
  scopeArray: any[] = [];
  filterPayload: any[] = [];
  patchResponse: any;
  newScope: any;
  newStepperCategory: any[] = [];
  index = 0;
  @ViewChild('tabGroup') tabGroup: MatTabGroup | any;
  scopeArrayToFilter: any[] = [];
  categoryArrayToFilter: any[] = [];
  patchResponseItem: any;
  inc = 0;
  maxChars = 100;
  parameterArrayToFilter: any[] = [];
  scopeData: any;
  disableFields: boolean = false;
  stepperCategoryInitial: any[] = [];
  selectedCategoryItemsInitial: any[] = [];

  isRedirectFrom = false;
  projectID: any = '';

  hasChange: boolean = false;

  opportunityData: any;
  projectData: any;
  tagDetails: any;

  scopeCreateForm: FormArray | any;
  scopeDescForm: FormGroup | any;
  scopeCustomCreateForm: FormGroup | any;
  breadcrumb: any;
  user: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private scopeService: ScopeService,
    private ps: ProgramService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private permissionsService: NgxPermissionsService,
    private ref: ChangeDetectorRef,
    private dataService: DataService,
    private formScopePipe: FormGroupNameScopePipe,
    private breadCrumService: BreadcrumbService
  ) {
    this.dataService.getScopeData().subscribe(data => {
    })
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    const perm: any = JSON.parse(localStorage.getItem('permission') || '{}');
    this.permissionsService.loadPermissions(perm.permissions);
    setTimeout(() => {
      this.showNext = true;
    }, 1100)

    this.breadCrumService.breadcrumbs$.subscribe((bc: any) => {
      this.breadcrumb = bc;
    })

    this.createForm();

    if (this.entity == 'opportunity') {
      this.route.parent?.parent?.params.subscribe((res: any) => {
        this.opportunityId = atob(res.id);
        this.getOpportunityById();
      });

      let oppInfo = localStorage.getItem('opportunity-info');
      this.opportunityInfo = typeof (oppInfo) === 'string' ? JSON.parse(oppInfo) : {};
      this.title = this.staticText?.opportunity.scope;
      this.info = this.opportunityInfo?.opportunity_name + ' | ' + this.opportunityInfo?.opportunity_number + ' | '
        + this.opportunityInfo?.opportunity_type + ' | ' + this.opportunityInfo?.portfolio + ' | ' + this.opportunityInfo?.subportfolio
        + ' | ' + this.opportunityInfo?.program + ' | ' + this.opportunityInfo?.owner_name
    } else if (this.entity == 'project') {
      this.route.parent?.params.subscribe((res: any) => {
        this.projectID = atob(res.id);
        this.getProjectById();
      });
    }
  }

  createForm() {
    this.parameterForm = this.fb.group({
      value: this.fb.array([])
    });
    this.scopeDescForm = new FormGroup({
      scope_desc: new FormControl('', [
        Validators.required,
      ]),
    });
    this.scopeCreateForm = this.fb.array([])
    this.scopeCustomCreateForm = this.fb.array([])
  }

  ngAfterViewInit() {
    // this.tabGroup._selectedIndex = 0;
  }

  get parameterValueFormArray() {
    return this.parameterForm.get('value') as FormArray;
  }

  parameterValueFormArrayControls(i: any): FormGroup {
    return this.parameterValueFormArray.controls[i] as FormGroup;
  }

  createNewFormGroup() {
    return this.fb.group({
    });
  }

  async getScopeList() {
    this.spinner.show();
    await this.getOpportunityScope();
    this.ps.getOpportunityFormData('opportunity_scopes').subscribe((res: any) => {
      this.spinner.hide();

      this.scopeList = res.records;
      this.modifiedScopeData(1, this.scopeList);

    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  async getProjectScopeList() {
    this.spinner.show();
    this.ps.getOpportunityFormData('opportunity_scopes').subscribe(async (res: any) => {
      this.spinner.hide();

      this.scopeList = res.records;
      await this.getProjectScope();
      this.modifiedScopeData(1, this.scopeList);

    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  modifiedScopeData(type?: any, scopeList?: any) {
    scopeList.map((i: any) => {
      if (i.field_name == 'scope') {
        this.allscopeItems = i.field_values;
      }
      if (i.field_name == "scope_parameter") {
        this.parameter = i.field_values;
      }
      if (i.field_name == "scope_category") {
        this.scopeCreateForm.reset();
        i.field_values.filter((i: any) => i.is_custom == false).forEach((element: any) => {
          element.category = [];
          this.scopeCreateForm.push(
            this.fb.control(element)
          )
        });

        this.scopeCustomCreateForm.reset();
        i.field_values.filter((i: any) => i.is_custom == true).forEach((element: any) => {
          element.category = [];
          this.scopeCustomCreateForm.push(
            this.fb.control(element)
          )
        });

        if(this.entity == 'opportunity'){
          this.patchapiData();
        }else if(this.entity == 'project'){
          this.patchapiProjectData();
        }

        this.ref.detectChanges();
      }
    })
  }

  patchapiData() {
    console.log(this.opportunityData);
    console.log(this.patchResponseItem);

    if (this.opportunityData && this.patchResponseItem) {
      this.opportunityData.opportunity_scope = this.patchResponseItem;
      this.patchResponse = this.opportunityData.opportunity_scope;

      this.ps.setStatusValue(this.opportunityData?.opportunity_scope?.status);
      this.constructPatchData(this.opportunityData);
    }
  }

  patchapiProjectData() {
    if (this.projectData && this.patchResponseItem) {
      this.projectData.project_scope = this.patchResponseItem;
      this.patchResponse = this.projectData.project_scope;

      this.ps.setStatusValue(this.projectData?.project_scope?.status);
      this.constructProjectPatchData(this.projectData);
    }
  }

  selectedStepper(e: any) {
    this.stepper.steps.forEach((step: any, index: number) => {
      if (index == e.selectedIndex) {
        this.stepStates[index] = 'edit';
      } else if (!step.completed && index != e.selectedIndex) {
        this.stepStates[index] = 'number';
      } else if (step.completed && index != e.selectedIndex) {
        this.stepStates[index] = 'done';
      }
    });
    this.stepperIndex = e.selectedIndex;
    this.showNext = false;
    this.sideParam = false;
    if (!this.disableFields) {
      this.side = true;
    }
    console.log('in selectedStepper......')
    console.log(this.index)
    this.updateScope(this.index);
    setTimeout(() => {
      this.showNext = true;
    }, 550)
  }

  skipStepper() {
    const tabCount = 2;
    this.tabIndex1 = (this.tabIndex1 + 1) % tabCount;
    console.log('in skipStepper......')
    this.updateScope();
    this.ps.getOppData(this.opportunityId).subscribe((res) => { this.ps.setStatusValue(res?.status); this.router.navigate([`rfx/` + btoa(this.opportunityId) + `/opportunity/terms`]) });
  }

  goToSetup() {
    this.ps.setValue(0)
    this.ps.getOppData(this.opportunityId).subscribe((res) => { this.ps.setStatusValue(res?.status); this.router.navigate([`rfx/` + btoa(this.opportunityId) + `/opportunity/setup`]) });
  }

  nextScope(stepperVar: any, stepper: any) {
    this.scopeDescForm.markAllAsTouched();
    if (this.scopeDescForm.invalid) {
      return;
    }
    if (!this.disableFields) {
      this.side = true;
    }
    stepper.next();
  }

  onExpand(i: any) {
    console.log('open', i);
  }

  onCollapse(i: any) {
    console.log('close', i);
  }

  uploadBar() {
    this.showUpload = true;
    this.showCustomOnly = false;
    this.sideHelp = false;
    this.side = false;
    this.sideParam = false;
  }
  clearCategoryField() {
    this.categoryName = '';
  }

  clearCustomParam() {
    this.CusParameterName = '';
  }
 
  showCustom() {
    this.newCustomCategory = JSON.stringify(this.customCategory);
    this.showCustomOnly = true;
    this.sideHelp = false;
    this.side = false;
    this.showAddCustom = false;
    this.showCustomButton = false;
    this.showUpload = false;
    this.sideParam = false;
  }
  
  closeCategory() {
    this.dialog.closeAll();
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  goToList() {
    this.router.navigate(['program']);
  }

  getOpportunityById() {
    this.ps.getOppStatus(this.opportunityId).subscribe((res: any) => {
      this.opportunityData = res;
      this.getScopeList();
      let breadCrumbFirst: any = [this.breadcrumb[0]];
      let breadCrumbLast: any = [this.breadcrumb[this.breadcrumb.length - 1]];
      let breadCrumb = [
        { label: this.opportunityInfo.portfolio, url: '/program', queryParams: { portfolio: this.opportunityInfo.portfolio_id } },
        { label: this.opportunityInfo.subportfolio, url: '/program', queryParams: { subportfolio: this.opportunityInfo.subportfolio_id } },
        { label: this.opportunityInfo.program, url: '/program', queryParams: { program: this.opportunityInfo.program_id } }
      ];
      breadCrumbFirst.push(...breadCrumb, ...breadCrumbLast)

      this.breadCrumService.setBreadcrumb(breadCrumbFirst);


    }, error => {
      throw error;
    })
  }

  getProjectById() {
    this.ps.getProject(this.projectID).subscribe((res: any) => {
      this.projectData = res;
      this.getProjectScopeList();
    }, error => {
      throw error;
    })
  }


  getOpportunityScope() {
    //  this.spinner.show();
    return new Promise((resolve, reject) => {
      this.ps.getOpportunityScopeData(this.opportunityId).subscribe((res: any) => {
        this.tagDetails = {
          id: res.id,
          tag_ids: res.tag_ids
        }
        //  this.spinner.hide();
        this.patchResponseItem = res.opportunity_scope;

        if (res.status.slug == 'opportunity_evaluating' || res.status.slug == 'opportunity_sow_signed' || res.status.slug == 'opportunity_cancelled') {
          this.disableFields = true;
          this.side = false;
          this.parameterForm.disable();
          this.parameterForm.get("value").disable();
          this.parameterForm.get("value").controls.forEach((control: any, index: number) => {
            this.parameterForm.get("value").controls[index].disable();
          });
        }
        this.scopeDescForm.get('scope_desc').setValue(res?.scope_description);

        this.patchResponseItem?.forEach((el: any) => {
          if (el?.category?.is_custom == true) {
            this.categoryArrayToFilter.push(el?.category.id);
          }
          el?.category?.scope?.forEach((elemnt: any) => {
            this.scopeArrayToFilter.push(elemnt.uuid);
            elemnt.parameter.forEach((p: any) => {
              this.parameterArrayToFilter.push(p.id);
            });
            this.parameterArrayToFilter = _.uniqWith([...this.parameterArrayToFilter], _.isEqual);
          });
        });
        resolve('sdf');
      }, error => {
        // this.spinner.hide();
        reject();
        throw error;
      })
    });
  }

  getProjectScope() {
    //  this.spinner.show();
    return new Promise((resolve, reject) => {
      this.ps.getProjectScopeData(this.projectID).subscribe((res: any) => {
        this.tagDetails = {
          id: res.id,
          tag_ids: res.tag_ids
        }
        //  this.spinner.hide();
        this.patchResponseItem = res.scopes;
        this.scopeDescForm.get('scope_desc').setValue(res?.scope_description);

        this.patchResponseItem?.forEach((el: any) => {
          if (el?.category?.is_custom == true) {
            this.categoryArrayToFilter.push(el?.category.id);
          }
          el?.category?.scope?.forEach((elemnt: any) => {
            this.scopeArrayToFilter.push(elemnt.uuid);
            elemnt.parameter.forEach((p: any) => {
              this.parameterArrayToFilter.push(p.id);
            });
            this.parameterArrayToFilter = _.uniqWith([...this.parameterArrayToFilter], _.isEqual);
          });
        });
        resolve('sdf');
      }, error => {
        // this.spinner.hide();
        reject();
        throw error;
      })
    });
  }

  constructPatchData(res: any) {
    let recommened = res.opportunity_scope.filter((e: any) => e.category?.is_custom === false)
    recommened.forEach((element: any) => {
      let index = this.scopeCreateForm.value.findIndex((e: any) => e.id === element.category.id)
      this.scopeCreateForm.controls[index].value.category = element.category;
    });
  }

  constructProjectPatchData(res: any) {
    let recommened = res.project_scope.filter((e: any) => e.category?.is_custom === false)
    recommened.forEach((element: any) => {
      let index = this.scopeCreateForm.value.findIndex((e: any) => e.id === element.category.id)
      this.scopeCreateForm.controls[index].value.category = element.category;
    });
  }

  getScope(index: any, scopeIndx: any) {
    if (this.allscopeItems) {
      let filteringArray = this.scopeCreateForm.controls[index].value.category.scope ? this.scopeCreateForm.controls[index].value.category.scope : [];
      var filteredArray = filteringArray.length > 0 ? this.allscopeItems.filter(function (array_el: any) {
        return filteringArray.filter(function (chl: any) {
          return chl.id == array_el.id;
        }).length == 0
      }) : this.parameter;

      filteredArray = typeof this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].id === 'string' ? filteredArray.filter((f: any) => f.scope_title.toLowerCase().indexOf(this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].id.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  getScopeTitle(scopeId: any) {
    if (scopeId) {
      return this.allscopeItems.find((item: any) => item.id === scopeId).scope_title;
    }
  }

  searchScope(index: any, scopeIndx: any) {
    return this.getScope(index, scopeIndx)
  }

  getScopeDesc(id: any) {
    if (this.allscopeItems) {
      return id ? this.allscopeItems[this.allscopeItems.findIndex((e: any) => e.id === id)]?.scope_description : ""
    } else {
      return "";
    }
  }

  addScopeItems(index: any) {
    if (this.scopeCreateForm.controls[index].value.category.scope == undefined) {
      this.scopeCreateForm.controls[index].value.category = {
        scope: []
      }
    }
    this.scopeCreateForm.controls[index].value.category.scope.push({
      id: null,
      title: null,
      description: null,
      uuid: null,
      parameter: []
    })
  }

  removeScope(index: any, i: any) {
    this.scopeCreateForm.controls[index].value.category.scope.splice(i, 1);
  }

  getParamterName(parameterId: any) {
    if (parameterId) {
      return this.parameter.find((item: any) => item.id === parameterId).name;
    }
  }

  getParameter(index: any, scopeIndx: any, paramIndx: any) {
    if (this.parameter) {
      let filteringArray = this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter ? this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter : [];
      var filteredArray = filteringArray.length > 0 ? this.parameter.filter(function (array_el: any) {
        return filteringArray.filter(function (chl: any) {
          return chl.id == array_el.id;
        }).length == 0
      }) : this.parameter;

      filteredArray = typeof this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter[paramIndx].id === 'string' ? filteredArray.filter((f: any) => f.name.toLowerCase().indexOf(this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter[paramIndx].id.toLowerCase()) === 0) : filteredArray;
      return filteredArray;
    } else {
      return [];
    }
  }

  searchParameter(index: any, scopeIndx: any, paramIndx: any) {
    return this.getParameter(index, scopeIndx, paramIndx)
  }

  addParameterItems(index: any, scopeIndx: any) {
    this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter.push({
      id: null,
      name: null,
      scope_parameter_value: null
    })
  }

  removeParameterItems(index: any, scopeIndx: any, paramIndx: any) {
    this.scopeCreateForm.controls[index].value.category.scope[scopeIndx].parameter.splice(paramIndx, 1)
  }

  onInputChanged(value: string): void {
    console.log(value)
  }

  onselectparameter(event: any, index: any, i: any, j: any) {
    this.scopeCreateForm.controls[index].value.category.scope[i].parameter[j] = Object.assign({}, this.parameter[this.parameter.findIndex((e: any) => e.id == event.option.value)]);
    this.scopeCreateForm.controls[index].value.category.scope[i].parameter[j].scope_parameter_value = null;
  }

  onselectscope(event: any, index: any, i: any) {
    this.scopeCreateForm.controls[index].value.category.scope[i].uuid = this.allscopeItems[this.allscopeItems.findIndex((e: any) => e.id == event.option.value)].scope_uuid;
  }

  nextSection() {
    this.scopeDescForm.markAllAsTouched();
    if (this.scopeDescForm.invalid) {
      return;
    }
    this.skipStepper();
  }

  updateScope(type?: any) {
    console.log(this.scopeDescForm.invalid);
    console.log(type)
    return;
    if (this.scopeDescForm.invalid) {
      return;
    }
    this.spinner.show();
    let scope_data: any = []

    this.scopeCreateForm.value.forEach((element: any) => {
      let scope: any = [];

      if (element?.category?.scope?.length > 0) {
        element.category.scope.forEach((scpe: any) => {
          let parameter: any = [];

          if (scpe.parameter.length > 0) {
            scpe.parameter.forEach((param: any) => {
              parameter.push({
                scope_parameter: param.id,
                scope_parameter_value: param.scope_parameter_value
              })
            });
          }
          scope.push({
            uuid: scpe.uuid,
            parameter: parameter
          });
        });
      }

      scope_data.push({
        scope_category: element.id,
        scope: scope
      })
    });

    scope_data = { categories: scope_data, description: this.scopeDescForm.controls['scope_desc'].value }
    console.log(scope_data)
    console.log('Úpdate Opportunity Called......')
    this.ps.updateOpportunity(this.opportunityId, { scope_data: scope_data }).subscribe((res: any) => {
      this.spinner.hide();
      return true;
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  updateCustomCategory(stepper: any) {
    if (this.parameterForm.invalid) {
      this.toastrService.error("Please fill all the fields");
      return;
    } else {
      this.updateScope(1);
    }
  }
  onClickEdit(data: any, modal: any) {
    this.scopeData = data;
    this.cusScopeTitle = data.title || data.scope_title;
    this.cusScopeDescription = data.description || data.scope_description;

    this.dialog.open(modal);
  }
  updateScopeData() {
    this.stepperCategory[this.stepperIndex].selectedItems.filter((ele: any) => ele.scope_uuid != this.scopeData.scope_uuid)
  }

  saveSelection(){
    this.spinner.show();
    let scope_data: any = []

    this.scopeCreateForm.value.forEach((element: any) => {
      let scope: any = [];

      if (element?.category?.scope?.length > 0) {
        element.category.scope.forEach((scpe: any) => {
          let parameter: any = [];

          if (scpe.parameter.length > 0) {
            scpe.parameter.forEach((param: any) => {
              parameter.push({
                scope_parameter: param.id,
                scope_parameter_value: param.scope_parameter_value
              })
            });
          }
          scope.push({
            uuid: scpe.uuid,
            parameter: parameter
          });
        });
      }

      scope_data.push({
        scope_category: element.id,
        scope: scope
      })
    });

    scope_data = { categories: scope_data, description: this.scopeDescForm.controls['scope_desc'].value }
    this.ps.updateProjectScope(this.projectID, { scope_data: scope_data }).subscribe((res: any) => {
      this.spinner.hide();
      return true;
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  clickPrevious() {
    this.router.navigate([`view/${this.projectID}/overview`])
  }

  ngOnDestroy() {
   
  }

  getTagObject(id: any) {
    return {
      entity_id: id,
      entity_type: this.entity == "opportunity" ? "opportunity" : "project"
    }
  }

}

