import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { ProjectService } from 'src/app/shared/services/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import Highcharts, { Point } from "highcharts/highmaps";
import {ScorecardService} from 'src/app/shared/services/scorecard.service';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { PowerBiService } from 'src/app/shared/services/power-bi.service';
import { MatSlider } from '@angular/material/slider';
import { FilterService } from 'src/app/shared/services/filter.service';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-scorecard-dashboard',
  templateUrl: './scorecard.component.html',
  styleUrls: ['./scorecard.component.scss']
})
export class ScorecardComponent implements OnInit {
  @ViewChild('slider') public slider: MatSlider;
  @Input() projectId: string;
  @Input() pageType: string;
  staticText: any = (textConfiguration as any).default;
  headerText: string = this.staticText?.delivery?.left_bar.performance_dashboard;
  noDataCustomer: boolean = false;
  noDataPeople: boolean = false;
  noDataFinance: boolean = false;
  noDataDelivery: boolean = false;
  matrixOverView:boolean=false;
  cardMatric:any;
  whatIfValues:any;
  reportClass = 'report-container';
  quadrants:any = {
    'customer':'',
    'finance':'',
    'engineering':'',
    'people':'',
  };
  customerData:any;
  financeData:any;
  engineerData:any;
  peopleData:any;
  dragPeopleEnableFlag:any[]=[];
  dragEnggEnableFlag:any[]=[];
  dragFinanceEnableFlag:any[]=[];
  dragCustomerEnableFlag:any[]=[];
  performanceBenchMark:any;
  reportModel:IReportEmbedConfiguration = {
    type: 'report',
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    settings: undefined,
  }; 
  reportConfig = this.reportModel;
  reportConfigSUnBurst = this.reportModel;
  sentimentGraph = this.reportModel;
  reportConfigRadarChart = this.reportModel;
  rightPanelBiReport:any;
  zoomLevel:any;  
  linkedMatrics:any = [];
  minValue = 0;
  maxValue = 100;
  sliderValue = 0;
  filterObj:any;
  portfolioId:any=null;
  programId:any=null;
  subportfolioId:any=null;
  vendorId:any=null;
  benchaMarkType:any = 'Portfolio';
  setMetricOverviewDetails:any;
  infoQuadrantName:any;
  quadrantInfoDetails:any;
  private subscription: Subscription;
  user: any;
  
  constructor(
    private activatedRoute: ActivatedRoute, 
    private spinner: NgxSpinnerService, 
    private projectService: ProjectService,
    private ScorecardService : ScorecardService,
    private PowerBiService : PowerBiService,
    private filterService: FilterService,
    private dialog: MatDialog,
  ) { 
    this.ScorecardService.changeMessage('nodata');
     this.loadMetricValueOnclick();
  }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    if(this.pageType != "projectLevel"){
     this.subscription = this.filterService.filterSubject.subscribe((res: any) => {
        this.filterObj = res;
        this.portfolioId='';this.subportfolioId ='';this.programId='';
        Object.keys(this.filterObj).forEach((element: any) => {
          if(this.filterObj[element].array.length > 0){
            if(this.filterObj[element].array[0].level == "portfolio"){
              this.portfolioId = this.filterObj[element].array[0].id;
            }else if(this.filterObj[element].array[0].level == "sub_portfolio"){
              this.subportfolioId = this.filterObj[element].array[0].id;
            }else if(this.filterObj[element].array[0].level == "program"){ 
              this.programId = this.filterObj[element].array[0].id;
            }else if(element == "vendor"){  
              this.vendorId = this.filterObj[element].array[0].id;
            };
            
          }
        });
          this.getScoreCardQuadrantData();
          // this.loadMetricValueOnclick();
        
      });
    }else{
      this.getScoreCardQuadrantData();
    }
    // 
  }
  getScoreCardQuadrantData() {
    this.spinner.show();
      this.projectService.getscoreCardQuadrant(this.projectId,this.portfolioId,this.subportfolioId,this.programId,this.vendorId).subscribe((response: any) => {
        // this.sparkLineData = [];
        this.customerData=[];
        this.financeData=[];
        this.engineerData=[];
        this.peopleData=[];
        this.spinner.hide();
        this.ScorecardService.okrSpread(response?.objectives);
        this.ScorecardService.riskSpread(response?.risk);

        if(response?.metrics?.length > 0){
          response?.metrics.forEach((res:any)=>{
          if (res.id == 139) {this.quadrants.engineering = res.display_name;
            this.dataToDelivery('engg',res.metrics,res.id);
            this.reloadMetricDataOnOverview(res.id,this.engineerData);
          } 
          if (res.id == 137) { this.quadrants.people = res.display_name;
            this.dataToDelivery('people',res.metrics,res.id);
            this.reloadMetricDataOnOverview(res.id,this.peopleData);
      
          } 
          if (res.id == 138) { this.quadrants.finance = res.display_name; 
            this.dataToDelivery('finance',res.metrics,res.id); 
            this.reloadMetricDataOnOverview(res.id,this.financeData);
      
          }
          if (res.id == 140) { this.quadrants.customer = res.display_name;
            this.dataToDelivery('customer',res.metrics,res.id)
            this.reloadMetricDataOnOverview(res.id,this.customerData);
      
          } 
          })
        }else{
          this.noDataCustomer = true;this.noDataFinance = true ; this.noDataDelivery = true;this.noDataPeople = true 
        }
      },(error)=>{
        this.spinner.hide()
        this.noDataCustomer = true
        this.noDataFinance = true;this.noDataPeople = true
        this.noDataDelivery = true
      })
  }

  matric_value:any; month:any;percent_value:any;
  dataToDelivery(type:any,res:any,quadrant_id:any) {
    
      res.forEach((val:any)=>{
        this.matric_value= [];
        this.percent_value = [];
        this.month = [];
        let gdp_name :any = [];
        let gdp_value : any = [];
        val.actual_value.forEach((matric:any)=>{
          this.matric_value.push(matric.actual_value);
          this.month.push(matric.month_short);
          if(matric.percentage){
            this.percent_value.push(matric.percentage);
          }

          if(matric.gdp_name != "ALL"){
            gdp_value.push(matric.actual_value);;
            gdp_name.push(matric.gdp_name);
          }
        })
        let lineTrendGrph:any ='';  
        let lineTrendOverview:any = '';
        let actual_value;
        let change:any;
         if(val.name == 'req_aging'){
          lineTrendGrph = this.reqAgingGraph(200,100,val?.data?.range,val?.data?.value,'Days');
          lineTrendOverview = this.reqAgingGraphOverview(val?.data?.range,val?.data?.value,val?.display_name,'Days Open','Count of Open Reqs','Avg. Score');
         }else if(val.name == 'gdp_survey_rating'){
          lineTrendGrph = this.reqAgingGraph(200,100,gdp_name,gdp_value,'Avg. Score');
          lineTrendOverview = this.reqAgingGraphOverview(gdp_name,gdp_value,val?.display_name,'GDP Name','Actual Value','Avg. Score');
         }
         else if(val.name == 'experience_junior'){
          lineTrendGrph = this.returnSparkLineChart([{data:val?.actual_value[val?.actual_value.length-1]?.data?.value}], 'bar', val?.actual_value[val?.actual_value.length-1]?.data?.range);
          lineTrendOverview = this.returnSparkLineChartOverView([{data:val?.actual_value[val?.actual_value.length-1]?.data?.value}], 'bar', val?.actual_value[val?.actual_value.length-1]?.data?.range);
         }else if(val.name == 'attrition_ltm' || val.name == 'attrition_ytd'){
          lineTrendGrph = this.returnSparkLineChart([{data: this.matric_value},{data: this.percent_value}], 'line', this.month);
          lineTrendOverview = this.returnSparkLineChartOverView([{data: this.matric_value},{data: this.percent_value}], 'line', this.month);
         }else{
          lineTrendGrph = this.returnSparkLineChart([{data: this.matric_value}], 'line', this.month);
          lineTrendOverview = this.returnSparkLineChartOverView([{data: this.matric_value}], 'line', this.month);
        }
        if(val.name == 'attrition_currentmonth' || val.name == 'krr_tt' || val.name == 'attrition_ltm' || val.name == 'attrition_ytd'){
          actual_value = val?.actual_value[val?.actual_value.length-1]?.percentage ? val?.actual_value[val?.actual_value.length-1]?.percentage : val?.actual_value[val?.actual_value.length-1]?.actual_value;
          change = val?.variance;
        }else if(val.name == 'experience_junior'){
          actual_value = val?.actual_value[val?.actual_value.length-1]?.data?.ratio;
          change = 'false';
        }else if(val.name == 'req_aging'){
          actual_value = 'false';
          change = 'false';
        }else if(val.name == 'gdp_survey_rating' || val.name == 'bvt_survey_rating'){
          actual_value = val?.actual_value[val?.actual_value.length-1]?.actual_value;
          change = 'false';
        }
        else{
          actual_value = val?.actual_value[val?.actual_value.length-1]?.actual_value;
          change = val?.variance;
        }
      var data = {
        id:val.id,
        metric_name:val.name,
        name:val.display_name,
        desc:val.description,
        actual_value:this.formatNumber(actual_value,2),
        change:change,
        variance_per:val?.variance_percent ? val?.variance_percent : 0,
        is_update:val.is_uptrend,
        trend:lineTrendGrph,
        overviewTrend:lineTrendOverview,
        powerBiReport:val?.powerbireport,
        unit_name:val?.unit_name,
      }
      
      if(type == 'engg'){
        this.engineerData.push(data);
      }
      if(type == 'people'){
        this.peopleData.push(data);
      }
      if(type == 'finance'){
        this.financeData.push(data);
      }
      if(type == 'customer'){
        this.customerData.push(data);
      }
    });
  }

  reloadMetricDataOnOverview(quadrant_id:any,qudrants_metric:any){
    if(this.setMetricOverviewDetails?.quadrant_id && this.matrixOverView){
      let metric;
      if(quadrant_id == this.setMetricOverviewDetails?.quadrant_id){
        metric = qudrants_metric.find((x:any) => x.id == this.setMetricOverviewDetails?.metric_id);
        let data:any = {metric:metric,cardDetails:qudrants_metric,cardName:this.setMetricOverviewDetails?.quadrant_name};
        this.ScorecardService.changeMessage(data);
      }
    }
  }
  returnSparkLineChart(seriesData: any, type: any, categories: any) {
    return new Chart(<any>{
      title: {
        text: ''
      },
      credits: false,
      chart: {
        width: 120,
        height: 30,
        type: type,
        margin: [0, 0, 8, 0],
        style: {
          overflow: 'visible',
        },
        skipClone: true
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      xAxis: {
        categories: categories,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          },
        }
      },
      tooltip: {
        enabled: true,
        outside: true,
        formatter: function(this:any) {
          return '<b>'+this.x+'</b></br>'+this.y;
        }
      },
      series: seriesData,
      exporting: { enabled: false }
    });
  }
  returnSparkLineChartOverView(seriesData: any, type: any, categories: any) {
    return new Chart(<any>{
      title: {
        text: ''
      },
      credits: false,
      chart: {
        backgroundColor: '#f9f9f9',
        renderTo: 'container',
        // width: 120,
        height: 60,
        type: type,
        margin: [2, 0, 6, 0],
        style: {
          overflow: 'visible',
        },
        skipClone: true
      },
      yAxis: {
        endOnTick: false,
        startOnTick: false,
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        tickPositions: [0]
      },
      xAxis: {
        categories: categories,
        lineWidth: 0,
        minorGridLineWidth: 0,
        lineColor: 'transparent',
        labels: {
          enabled: false
        },
        title: {
          text: null
        },
        startOnTick: false,
        endOnTick: false,
        tickPositions: []
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true
          },
          dataLabels: {
            shape: 'connector',
            enabled: true,
            formatter: function(this:any) {
              return '<span style="font-size:8px;font-weight:100">'+this.x+'</span>';
            },
            y: -60,
            allowOverlap: true,
            useHTML: false
          }
        }
      },
      tooltip: {
        enabled: true,
        outside: true,
        formatter: function(this:any) {
          return '<b>'+this.x+'</b></br>'+this.y;
        }
      },
      series: seriesData,
      exporting: { enabled: false }
    });
  }
  drop(event: CdkDragDrop<string[]>,quadrant_id:any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.saveSortedMatricsData(event.container.data,quadrant_id);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
      this.saveSortedMatricsData(event.container.data,quadrant_id);
    }
    if(quadrant_id==140){
      this.dragCustomerEnableFlag[event.previousIndex]="true";
    }
    if(quadrant_id==138){
      this.dragFinanceEnableFlag[event.previousIndex]="true";
    }
    if(quadrant_id==139){
      this.dragEnggEnableFlag[event.previousIndex]="true";
    }
    if(quadrant_id==137){
      this.dragPeopleEnableFlag[event.previousIndex]="true";
    }
  }

  enableDragClick(i:any,type:any){

    if(type==140){
      this.dragCustomerEnableFlag[i]="false";
    }
    if(type==138){
      this.dragFinanceEnableFlag[i]="false";
    }
    if(type==139){
      this.dragEnggEnableFlag[i]="false";
    }
    if(type==137){
      this.dragPeopleEnableFlag[i]="false";
    }
    
  }

  clickOverView(type:any,metric:any,cardDetails:any,cardName:any){
    if(this.user?.is_vendor) return;
    this.whatIfValues = cardDetails;
    let data:any = {metric:metric,cardDetails:cardDetails,cardName:cardName};
    this.setMetricOverviewDetails = {metric_id:metric?.id,quadrant_id:type,quadrant_name:cardName}
    this.ScorecardService.changeMessage(data);
    console.log(this.whatIfValues);
  }
  saveSortedMatricsData(data:any,quadrant_id:any){
    this.spinner.show();
    var payload:any;
    var matrics:any = [];
    data.forEach((mat:any)=>{
      matrics.push(mat.id)
    });
    payload = {quadrant_id:quadrant_id,sort_order:matrics}
    this.projectService.saveSortedQuadrant(payload).subscribe((response: any) => { 
      this.spinner.hide()
    },(error)=>{
        this.spinner.hide()
   });
  }

  loadPowerBIReport(metric:any){
    if(metric?.powerBiReport?.length > 0){
      let metricValues:any = metric?.powerBiReport[0] ? metric?.powerBiReport[0] : [];
      this.reportConfig = this.reportModel;
      // this.reportConfigSUnBurst = this.reportModel;
      // this.sentimentGraph = this.reportModel;
      // this.reportConfigRadarChart  = this.reportModel;
      this.setBIZoomLevel();
      var basicFilter:any = {
        $schema: "http://powerbi.com/product/schema#basic",
        // target: {
        //     table: "survey_data",
        //     column: "survey_id"
        // },
        // operator: "In",
        // values:[`${surveyName}`],
        // filterType: models.FilterType.Basic
    };
  
      this.PowerBiService.getTokenForBI().subscribe((res: any) => {
        this.PowerBiService.getBIEmbedURL(res.access_token,metricValues?.report).subscribe((reportData: any) => {
          this.PowerBiService.getBIEmbedToken(res.access_token,metricValues?.report,metricValues?.group).subscribe((tokenData: any) => {
            this.reportConfig = this.loadPowerBIReportGraph(reportData,tokenData,basicFilter,metricValues?.report_name);
          });
        });
      });
    }else{
      return ;
    }
  }
  loadPowerBIReportGraph(reportData:any,tokenData:any,basicFilter:any,pageName:any){
   return {type: 'report',
    id: reportData.id,
    embedUrl: reportData.embedUrl,
    accessToken:tokenData.token,
    tokenType: models.TokenType.Embed,
    pageName: pageName,
     filters: [basicFilter],
    settings: {
        panes: {
            filters: {
                expanded: false,
                visible: false
            },
            pageNavigation: {
              visible: false
            },
        },
        zoomLevel :0.6,
        background: models.BackgroundType.Transparent,
      }
    }
  }
  setBIZoomLevel(){
    if(window.innerWidth > 1500){
      this.zoomLevel = 0.6;
    }else if(window.innerWidth <= 1500 && window.innerWidth > 1246 ){
      this.zoomLevel = 0.5;
    }else if(window.innerWidth <= 1245 ){
      this.zoomLevel = 0.4
    }
  }

  async loadLinkedMatricsData(metricId:any){
    this.linkedMatrics =[];
    await this.projectService.getLinkedMatricsData(metricId,this.projectId,this.portfolioId,this.subportfolioId,this.programId).subscribe((response: any) => {
      let matric_value:any = [];
      let month:any = [];
      response.forEach((val:any)=>{
        matric_value= [];
        month = [];
        val.actual_value.forEach((matric:any)=>{
          matric_value.push(matric.actual_value);
          month.push(matric.month_short);
        });
        val.trend = this.returnSparkLineChart([{data: matric_value}], 'line', month);
        this.linkedMatrics.push(val);
      });
    });
  }
  async loadPerformanceBenchMarcData(metricId:any){
    if(this.projectId){
      this.benchaMarkType = 'Projects';
    }else if(this.portfolioId){
      this.benchaMarkType = 'Portfolio';
    }else if(this.subportfolioId){
      this.benchaMarkType = 'Sub Portfolio';
    }else if(this.programId){
      this.benchaMarkType = 'Program';
    }else{
      this.benchaMarkType = 'Portfolio';
    }
    await this.projectService.getPerformanceBenchMarkData(metricId,this.projectId,this.portfolioId,this.subportfolioId,this.programId).subscribe((response:any)=>{
      this.performanceBenchMark = [];
      let matric_value:any = [];
      let month:any = [];
      response.forEach((val:any)=>{
        matric_value= [];
        month = [];
        val.actual_value.forEach((matric:any)=>{
          matric_value.push(matric.actual_value);
          month.push(matric.month_short);
        });
        val.trend = this.returnSparkLineChart([{data: matric_value}], 'line', month);
        this.performanceBenchMark.push(val);
      })
    })
  }
  onInputChange(event: Event) {
    // Handle slider value change here
    console.log('Slider value changed:', this.sliderValue);
  }
  
  loadMetricValueOnclick(){
    this.ScorecardService.cardDetails.subscribe((cards:any) => {
      if(cards?.cardName){
        this.matrixOverView = true;
        this.cardMatric = cards?.metric;
        this.loadPowerBIReport(cards?.metric);
        this.loadLinkedMatricsData(cards?.metric?.id);
        this.loadPerformanceBenchMarcData(cards?.metric?.id);
      }else{
        this.matrixOverView = false;
      }
   });
  }
  ngOnDestroy(): void{
    this.programId = null;
    this.portfolioId =null;
    this.subportfolioId =null;
    this.programId = null;
    this.ScorecardService.changeMessage('nodata');
    if(this.pageType != "projectLevel"){
      this.subscription.unsubscribe();
    }
    this.ScorecardService.okrSpread('');
    this.ScorecardService.riskSpread('');
  }
  closeMatrixOverview(){
    this.ScorecardService.changeMessage('nodata');
    this.matrixOverView = false;
    
    this.getScoreCardQuadrantData();
  }

  getDifference(itemActualValue:any,metricActualValue:any){
    let val:any = itemActualValue-metricActualValue;
    val = parseFloat(val).toFixed(2); 
    return val ? val : 0;
  }

  quadrantInfo(type:any,modal:any,quadrant_name:any){
    this.infoQuadrantName = quadrant_name;
    this.dialog.open(modal, {
      width: 'auto',
      height: 'auto',
    });
    this.spinner.show();
    this.projectService.quadrantIfo(type).subscribe((res:any)=>{
      console.log(res)
      this.spinner.hide();
      this.quadrantInfoDetails = res;
    })
  }

  dialogClose(){
    this.dialog.closeAll();
  }

reqAgingGraph(width:any,height:any,row:any,column:any,tooltipName:any){
    return new Chart(<any>{
      title: {
            text: ''
          },
      chart: {
        type: 'column',
        width: width,
        height: height,
      },
      credits: false,
      yAxis: {
        min: 0,
        title:''
    },
      xAxis: {
          categories: row,
          crosshair: true,
      },
    
      tooltip: {
          // valueSuffix: ' (1000 MT)'
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              borderWidth: 0
          }
      },
      legend: {
            enabled: false
          },
      series: [
          {
            name: tooltipName,
            data: column
          },
      ],
      exporting: { enabled: false }
    });
  }

  reqAgingGraphOverview(row:any,column:any,display_name:any,xAxisName:any,yAxisName:any,tooltipName:any){
    return new Chart(<any>{
      title: {
            text: ''
          },
      chart: {
        type: 'column',
        height: '300'
      },
      credits: false,
      yAxis: {
        min: 0,
        title:{
          text: yAxisName
        }
    },
      xAxis: {
          categories: row,
          crosshair: true,
          title:{
            text: xAxisName
          }
        },
    
      tooltip: {
          // valueSuffix: ' (1000 MT)'
      },
      plotOptions: {
          column: {
              // pointPadding: 0.2,
              borderWidth: 0
          }
      },
      legend: {
            enabled: true
          },
      series: [
          {
            name: display_name,
            data: column
          },
      ],
      exporting: { enabled: false }
    });
  }

  private formatNumber(number: any, decimalPlaces: number): string {
    // Ensure the number is a valid number
    if (!isNaN(number)) {
      // Use toFixed to format the number with the specified decimal places
      return number.toFixed(decimalPlaces);
    } else {
      // Handle the case where the input is not a valid number
      return number;
    }
  }


  openWhatIfModel(modal:any){
    this.dialog.open(modal, {
      width: 'auto',
      minWidth: "60vw",
      height: 'auto',
    });
  }
  

}

