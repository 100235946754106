import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tree-listing',
  templateUrl: './tree-listing.component.html',
  styleUrls: ['./tree-listing.component.scss'],
})
export class TreeListingComponent implements OnInit {
  @Input() items: any = [];
  @Input() tree: any;
  @Input() context: any;
  @Input() rightpane: boolean = false;
  
  loading: boolean = false
  user: any;
  
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
  }

  async toggleAccordion(item: any, indx: number) {
    item.expanded = !item.expanded;

    if(item.expanded){
      this.context.componentParent.collapse(item);
      
      this.loading = true;
      const response = await this.context.componentParent.getLevel(item);
      this.loading = false;
    }
  }

  getColor(item: any){
    if(item.level == 'portfolio'){
      return '#0E2954';
    }else if(item.level == 'sub_portfolio'){
      return '#1F6E8C';
    }else if(item.level == 'program'){
      return '#2E8A99';
    }else if(item.level == 'last'){
      return 'rgb(224 224 224)';
    }else{
      return '';
    }
  }

  openUrl(item: any,event?:any){
    event.preventDefault();

    console.log(item);
    this.context.componentParent.navigateTo(item);
  }

  deleteItem(item:any){
    this.context.componentParent.deleteItem(item);
  }

  archiveItem(item:any){
    this.context.componentParent.archiveItem(item);
  }

  archiveList(item: any){
    this.context.componentParent.archiveList(item);
  }
}