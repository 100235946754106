<div class="row py-2" *ngIf="cards?.cardName">
    <div class="col-md-12">
        <div *ngIf="cards?.cardName == 'Customer'" class="column-bg-1 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center justify-content-center">
                <i class="fas fa-user-friends user-icon">
                </i>
                <p class="title">{{cards?.cardName}}</p>
            </div>
        </div>
        <div *ngIf="cards?.cardName == 'Finance'" class="column-2-bg d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center justify-content-center">
                <i class="far fa-sack-dollar user-icon">
                </i>
                <p class="title">{{cards?.cardName}}</p>
            </div>
        </div>
        <div *ngIf="cards?.cardName == 'Engineering'" class="column-bg-3 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center justify-content-center">
                <i class="fas fa-users user-icon">
                </i>
                <p class="title">{{cards?.cardName}}</p>
            </div>
        </div>
        <div *ngIf="cards?.cardName == 'People'" class="column-bg-4 d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center justify-content-center">
                <i class="fad fa-analytics user-icon">
                </i>
                <p class="title">{{cards?.cardName}}</p>
            </div>
        </div>
        
        <div >
        <!--Customer Card start-->
        <div class="table-responsive" style="max-height: 310px; height:auto; width:100%;">
            <table id="table-sparkline" class="table mb-0" style="font-size: 0.5rem;border-top:medium none;">
                <ng-container *ngTemplateOutlet="matricTableBody"></ng-container>
                <tbody id="tbody-sparkline"  class="example-list">
                    <tr *ngFor="let metric of cards.cardDetails;index as i"  class="example-box" (click)="clickOnMetric(metric,cards.cardDetails,cards?.cardName)">
                        <td class="w-25 text-primary cursor-pointer"><span class="fw-bold" matTooltip="{{metric.desc}}"  matTooltipClass="example-tooltip">{{metric.name}}&nbsp; <span *ngIf="metric?.unit_name">({{metric?.unit_name}})</span></span></td>
                        <td class="w-25" [attr.colspan]="metric.metric_name == 'req_aging' ? '2' : ''">
                            <div [chart]="metric.trend">
                            </div>
                        </td>
                        <td *ngIf="metric.actual_value != 'false'">
                            <span class="fw-bold">{{metric.actual_value ? metric.actual_value : 0}}</span>
                        </td>
                        <td class="text-center" *ngIf="metric.change != 'false'">
                            <div class="fw-bold" [ngClass]="metric.is_update == false ? 'spanGreen' : 'spanRed'">{{metric.change ? metric.change : 0}}</div>
                            <span class="px-2 d-block">{{metric.variance_per}}%</span>
                        </td>
                        <td *ngIf="metric.change == 'false'">
                        </td>
                    </tr> 
                </tbody>
            </table>    
        </div>
            <!--Customer Card end-->
        </div>
        
    </div>
</div>


<ng-template #matricTableBody>
    <thead>
        <tr>
            <th class="tblBg"> {{staticText?.balance_scorecard?.dashboard?.metrics}} </th>
            <th class="tblBg">{{staticText?.balance_scorecard?.dashboard?.trend}}</th>
            <th class="tblBg">Current Value</th>
            <th class="tblBg">{{staticText?.balance_scorecard?.dashboard?.change}}</th>
        </tr>
    </thead>
</ng-template>