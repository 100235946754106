import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { of } from 'rxjs';
import {tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ActionTrackerService {

  constructor(private http: HttpClient,private router:Router,private transferState: TransferState) { }

  resolve(route: ActivatedRouteSnapshot): any {
    let id = route.params['id'];
    try{
      id = atob(id)
    }catch(e){
      this.router.navigate(["404"])
    }
    return this.http.get('action-tracker/' + id);
  }

  getActionTrackerList(offset: number = 0, end: number = 10, subPortId?: any, searchText?: any, portId?: any, action_category?: any, action_function?: any, gdpId?: any) {
    let params = `offset=${offset}&end=${end}`;
    if (portId) {
      params = `${params}&portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&sub_portfolio=${subPortId}`
    }
    if (action_category) {
      params = `${params}&action_category=${action_category}`
    } if (action_function) {
      params = `${params}&action_function=${action_function}`

    } if (gdpId) {
      params = `${params}&vendor=${gdpId}`
    }

    if (searchText) {
      params = `${params}&search_text=${searchText}`
    }

    return this.http.get(`action-tracker/?${params}`)
  }


  getActionCategoryList() {
    const detailKey = makeStateKey(`get-action-category`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get('action-tracker/get-action-category/').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }
  userDetails(data: any) {
    return this.http.post('v1/users/get-user-profile/', data);
  }

  getVendorList() {
    return this.http.get('dynamic-form/?form_name=opportunity_empanelled_vendors');
  }

  getAssigneeList() {    
    const detailKey = makeStateKey(`dynamic-form/?form_name=opportunity_teams`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get('dynamic-form/?form_name=opportunity_teams').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }

  getvendordetails() {
    const detailKey = makeStateKey(`get-vendor-details`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get('action-tracker/get-vendor-details').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }

  getActionTrackerDetails(id: any) {
    return this.http.get('action-tracker/' + id);
  }

  updateComments(data: any, id: any) {
    return this.http.post('action-tracker/add-update-comments-to-action-tracker/' + id + '/', data);
  }
  updateassignee(data: any, id: any) {
    return this.http.patch('action-tracker/update-action-tracker/' + id + '/', data);
  }

  getStatusFieldValues() {
    const detailKey = makeStateKey(`action-tracker-status-list`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get('action-tracker/action-tracker-status-list/').pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }

  downloadFile(payload: any, action_tracker_id: any) {
    return this.http.post(`action-tracker/action-tracker-file-download/${action_tracker_id}/`, payload, { responseType: 'blob' });
  }

  deleteCommands(id: any) {
    return this.http.delete('action-tracker/delete-action-tracker-comment/' + id + '/');
  }

  updateStatus(data: any) {
    return this.http.post('action-tracker/update-action-tracker-status/', data);
  }

  submitForm(payload: any) {
    return this.http.post('action-tracker/add-action-tracker/', payload, { responseType: 'blob' });
  }


  //=========================================================================
  getActionCategory(){
    return this.http.get('action-tracker-old/get-action-category');
  }
  getUser(query?: any) {
    return this.http.get(`users/user-list/`)
  }
  getOpportunity(){
    return this.http.get(`/delivery/get-complete-opportunity-list/`);
  }

  getProject(){
    return this.http.get(`/delivery/get-complete-project-list/`);
  }
  getParadeList(){
    return this.http.get(`parade/parade/`);
  }
  getCuratedInsightList(){
    return this.http.get(`insights/insights/`);
  }
  getActionTrackerListNew(){
    return this.http.get(`/action-tracker`);
  }
  deleteActionTrackerById(trackerId:any){
    return this.http.delete('action-tracker/' + trackerId + '/');
  }
  updateActionTracerById(data:any,id:any){
    return this.http.patch('action-tracker/'+id+'/',data);
  }
  addNewActionTracker(data:any){
    return this.http.post('action-tracker/',data);
  }
}



