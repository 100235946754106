import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from './components/datatable/datatable.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { HeaderComponent } from './components/header/header.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule } from '@angular/forms';
import { ListComponent } from './components/list/list.component';
import { TipsComponent } from './components/tips/tips.component';
import { SelectComponent } from './components/select/select.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AvatarModule } from 'ngx-avatar';
import { LeftSideMenuComponent } from './components/left-side-menu/left-side-menu.component';
import { AutocompleteTextboxComponent } from './components/autocomplete-textbox/autocomplete-textbox.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SwiperModule } from "swiper/angular";
import { MaterialmodulesModule } from './materialmodules.module';
import { TabmenuComponent } from './components/tabmenu/tabmenu.component';
import { TableCardComponent } from './components/table-card/table-card.component';
/* import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'; */
import { NgCircleProgressModule } from 'ng-circle-progress';
import { RouterModule } from '@angular/router';
import { TreeComponent } from './components/tree/tree.component';
import { NgbModule, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TreefilterPipe } from './pipes/treefilter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component'
import { PipeModule } from './pipes/pipe.module';
import { TicketComponent } from './components/ticket/ticket.component'
import { TransferState } from '@angular/platform-browser';
import { TagComponent } from './components/tag/tag.component';
import { ConfirmationDialogComponent } from './components/tag/confirmation-dialog/confirmation-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { ComingSoonDialog } from './components/coming-soon/coming-soon-dialog';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { TreeListingComponent } from './components/tree-listing/tree-listing.component';
import { LoadingComponent } from './components/loading/loading.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { SelectionTreeListingComponent } from './components/selection-tree-listing/selection-tree-listing.component';
import { FilterCardComponent } from './components/filter-card/filter-card.component';
import { ChatBotWindowComponent } from './components/chat-bot-window/chat-bot-window.component';
import { LabelInputComponent } from './components/label-input/label-input.component';
import { OwnerComponent } from './components/owner/owner.component';
import { WorkgroupComponent } from './components/workgroup/workgroup.component';
import { TimeagoModule } from 'ngx-timeago';
import { CommentsComponent } from './components/comments/comments.component';
import { QuillModule } from 'ngx-quill';
import { ScorecardComponent } from './components/scorecard-matrix/scorecard/scorecard.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChartModule } from 'angular-highcharts';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { IndividualScorecardComponent } from './components/scorecard-matrix/individual-scorecard/individual-scorecard.component';
import { MatSliderModule } from '@angular/material/slider';
import {RightPanelStatementDetailsComponent} from './components/right-panel-statement-details/right-panel-statement-details.component';
import { StatementResponseDetailsComponent } from './components/statement-response-details/statement-response-details.component';
import { RisklogHeatmapComponent } from './components/risklog-heatmap/risklog-heatmap.component';
import { ModalHeaderCommonComponent } from './components/modal-header-common/modal-header-common.component';
import { GnewsComponent } from './components/gnews/gnews.component';
import { TagMappedProjectsComponent } from './components/tags-mapped-projects/tags-mapped-projects.component';
import { SharedScopeComponent } from './components/scope/scope.component';

@NgModule({
    declarations: [
        DatatableComponent,
        HeaderComponent,
        FooterComponent,
        ComingSoonDialog,
        ModalComponent,
        ListComponent,
        TipsComponent,
        SelectComponent,
        LeftSideMenuComponent,
        SideMenuComponent,
        AutocompleteTextboxComponent,
        CarouselComponent,
        TabmenuComponent,
        FilterCardComponent,
        TableCardComponent,
        TreeComponent,
        TreeListingComponent,
        SelectionTreeListingComponent,
        GnewsComponent,
        TreefilterPipe,
        DeletePopupComponent,
        BreadCrumbComponent,
        TicketComponent,
        TagComponent,
        ConfirmationDialogComponent,
        LoadingComponent,
        ConfirmModalComponent, 
        ChatBotWindowComponent,
        LabelInputComponent,
        OwnerComponent,
        WorkgroupComponent,
        CommentsComponent,
        ScorecardComponent,
        IndividualScorecardComponent,
        RightPanelStatementDetailsComponent,
        StatementResponseDetailsComponent,
        RisklogHeatmapComponent,
        ModalHeaderCommonComponent,
        TagMappedProjectsComponent,
        SharedScopeComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxDatatableModule,
        FormsModule,
        NgxPermissionsModule,
        AvatarModule,
        SwiperModule,
        MaterialmodulesModule,
        NgbModule,
        NgCircleProgressModule.forRoot({
        }),
        Ng2SearchPipeModule,
        PipeModule,
        NgbPaginationModule,
        QuillModule,
        ChartModule,
        HighchartsChartModule,
        DragDropModule,
        PowerBIEmbedModule,
        MatSliderModule
    ],
    exports: [
        DatatableComponent,
        HeaderComponent,
        FooterComponent,
        ComingSoonDialog,
        ListComponent,
        TipsComponent,
        SelectComponent,
        TableCardComponent,
        LeftSideMenuComponent,
        SideMenuComponent,
        AutocompleteTextboxComponent,
        CarouselComponent,
        TabmenuComponent,
        FilterCardComponent,
        TreeComponent,
        TreeListingComponent,
        SelectionTreeListingComponent,
        GnewsComponent,
        Ng2SearchPipeModule,
        AvatarModule,
        DeletePopupComponent,
        BreadCrumbComponent,
        PipeModule,
        TicketComponent,
        NgbPaginationModule,
        TagComponent,
        LoadingComponent,
        LabelInputComponent,
        OwnerComponent,
        WorkgroupComponent,
        TimeagoModule,
        CommentsComponent,
        ScorecardComponent,
        IndividualScorecardComponent,
        RightPanelStatementDetailsComponent,
        StatementResponseDetailsComponent,
        RisklogHeatmapComponent,
        ModalHeaderCommonComponent,
        TagMappedProjectsComponent,
        SharedScopeComponent
    ],
    providers:[TransferState],
    entryComponents: [ ConfirmationDialogComponent ],
})
export class SharedModule { }
