import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { NavigationCancel, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import * as moment from 'moment';
import { ProgramService } from 'src/app/shared/services/program.service';
import { ActionTrackerService } from 'src/app/shared/services/action-tracker.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { SurveyService } from 'src/app/shared/services/survey.service';
import { PublishSurveyComponent } from 'src/app/pages/survey/publish-survey/publish-survey.component';

import * as textConfiguration from 'src/assets/static-text-configuration.json';
@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss'],
  encapsulation:ViewEncapsulation.Emulated
})
export class TableCardComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  @Input() proposalLists: any[] = [];
  @Input() screen_name: any;
  @Input() statusList: any;
  @Output() refereshParentData = new EventEmitter<string>();
  page: number = 1;
  totalCount: any;
  selectedId: any;
  initiatedDate: any;
  toeStatusValue: any;
  statusId: any;
  card_data: any;
  toeStatus: any[] = [];
  statusFilter: any;
  ColorRandom: any;
  trackerStatusValue: any;
  trackerStatus: any;

  constructor(private router: Router, private ps: ProgramService, private trackerService: ActionTrackerService, private spinner: NgxSpinnerService, public dialog: MatDialog, private toastrService: ToastrService, private surveyService: SurveyService) { 
    router.events.subscribe((event: RouterEvent|any) => {
      this.navigationInterceptor(event);
    });
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      //this.spinner.show();
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      setTimeout(() => { // here
        //this.spinner.hide();
      }, 2000);
    }
    if (event instanceof NavigationError) {
      setTimeout(() => { // here
      //  this.spinner.hide();
      }, 2000);
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges() {    
    if(this.screen_name == "actionTracker")
      this.getTrackerstatus();
  }

  viewProject(id: any) {
    this.router.navigate([`view/`+btoa(id)+`/overview`]);
  }

  getTrackerstatus() {
    this.trackerService.getStatusFieldValues().subscribe((res: any) => {
      console.log('status', res);
      this.trackerStatus = res;
      this.proposalLists = this.proposalLists?.map((i: any) => {

        this.trackerStatus.forEach((element: any) => {
          if (element.label == i?.status?.label) {
            i.updatedStatus = element;
          }
        });
        return i;
      })
    });

  }


  getToestatus() {
    //this.ps.getToestatus().subscribe((res: any) => {
      // this.toeStatus = res;
      // this.statusFilter = res;
      this.proposalLists = this.proposalLists?.map((i: any) => {
        i.last_updated_on = !!i.last_updated_on ? moment(i.last_updated_on).format('DD-MM-yyyy  HH:mm') : '';
        // this.toeStatus.forEach((element: any) => {
        //   if (element?.action_complete_label == i?.status?.action_complete_label) {
            i.updatedStatus = i?.status
      //     }
      //   });
      //   return i;
      // }, (error: any) => {
      //   this.toastrService.error('Error');
      //   throw error;
      // })
    });
  }
  getName(name: any) {
    try{
      return name.split(' ').slice(0, 2).map((n: any) => n[0]).join('');

    }catch(e){
      return name;

      console.log(e)
    }
  }

  viewProposal(id: any,status:string) {    
    if(status =='Published'){
      this.router.navigate([`getproposal/`+btoa(id)+`/proposal-evaluation`]);
    }
    else if(status =='Evaluating' ){
      this.router.navigate([`getproposal/`+btoa(id)+`/summary`]);
    }else{
      this.router.navigate([`getproposal/${btoa(id)}/proposal-evaluation`]);
    }
  }

  viewToe(id: any, view: any) {
    this.dialog.open(view);
    var obj = this.proposalLists.filter(function (data) {

      return data.id == id;
    });
    this.card_data = obj[0];
    return obj;
  }
  openOpportunity(id: any, viewOpportunity: any) {
    this.dialog.open(viewOpportunity,{panelClass:"mw-100"});
    var obj = this.proposalLists.filter(function (data) {
      return data.id == id;
    });
    this.card_data = obj[0];
    return obj;
  }
  openProposal(id: any, viewProposalPopup: any) {
    this.dialog.open(viewProposalPopup);
    var obj = this.proposalLists.filter(function (data) {
      return data.id == id;
    });
    this.card_data = obj[0];
    return obj;
  }

  projectViewPopup(id: any, data: any) {
    this.dialog.open(data);
    var obj = this.proposalLists.filter(function (data) {
      return data.id == id;
    });
    this.card_data = obj[0];
    return obj;
  }

  editToe(id: any,status:string) {
    if(status =='Evaluating' ){
      this.router.navigate([`getproposal/`+btoa(id)+`/summary`]);
    }else{
      this.router.navigate([`toe/`+btoa(id)+`/award-deal`]);
    }
  }
  screenInfo(id: any) {

    this.dialog.closeAll();
    this.router.navigate([`view/`+btoa(id)+`/overview`]);
  }
  navigateTo(id: any) {
    this.dialog.closeAll();
    this.router.navigate([`getproposal/`+btoa(id)+`/proposal-evaluation`]);
  }

  editOpportunity(id: any) {
    this.router.navigate([`rfx`+btoa(id)+`/opportunity/setup`]);
  }

  viewPreview(id: any) {
    
    this.router.navigate([`rfx/`+btoa(id)+`/opportunity/preview`]);
  }

  onDateChange(event: any, i: any) {
    this.selectedId = i.id;
    this.initiatedDate = event.value ? moment(event.value).format('yyyy-MM-DD') : '';
    this.updateInnitiatedate();
  }


  updateInnitiatedate() {
    const payload = {
      initiation_date: this.initiatedDate,
      opportunity: this.selectedId
    }
    this.ps.updateInnitiatedate(payload).subscribe((res: any) => {
      if (res == true) {
        this.spinner.hide();
        this.refereshParentData.emit();
        this.toastrService.success("Saved succesfully");
        this.dialog.closeAll();
      }
    }, (error: any) => {
      throw error;
    })
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  deleteDialog() { }

  selectToeStatus(e: any, i: any) {
    console.log(e);
    
    this.selectedId = i.id;
    this.toeStatusValue = e.value;
    this.updateToeStatus();
  }
  selectedTrackerStatus(e: any, i: any) {
    this.selectedId = i.id;
    this.trackerStatusValue = e.value.slug;
    this.updateTrackerStatus();

  }

  statusChange(e: any) {
    this.statusId = e.value.id;
  }

  updateToeStatus() {
    const payload = {
      opportunity: this.selectedId,
      status: this.toeStatusValue
    }
    this.ps.updateToeStatus(payload).subscribe((res: any) => {
      if (res == true) {
        this.spinner.hide();
        this.toastrService.success("Saved succesfully");
        this.refereshParentData.emit();
        this.dialog.closeAll();
      }
    }, (error: any) => {
      throw error;
    })
  }


  updateTrackerStatus() {
    const payload = {
      action_tracker_id: this.selectedId,
      status: this.trackerStatusValue,
    }
    this.ps.updateStatus(payload).subscribe((res: any) => {
      if (res == true) {
        this.spinner.hide();
        this.refereshParentData.emit();
        this.toastrService.success("Saved succesfully");
        this.dialog.closeAll();
      }
    }, (error: any) => {
      throw error;
    })
  }

  editProgram(id: any) {
    this.router.navigate([`rfx/`+btoa(id)+`/opportunity/setup`]);
  }

  viewProgram(id: any,status:string) {
    if(status =='Published'){
      this.router.navigate([`getproposal/`+btoa(id)+`/proposal-evaluation`]);
    }else if(status =='Evaluating' ){
      this.router.navigate([`getproposal/`+btoa(id)+`/summary`]);
    }else if(status =='SoW Signed'){
      this.router.navigate([`toe/`+btoa(id)+`/award-deal`]);
    }else if(status =='Draft'){
         this.ps.getSingleOpportunityById(id).subscribe((res: any) => {
          this.router.navigate([`rfx/`+btoa(id)+`/opportunity/setup`]);})
      }
    
    else{
      this.router.navigate([`rfx/`+btoa(id)+`/opportunity/preview`]);
    }
  }

  ideaDetails(id: any) {
    this.router.navigate(['innovation/detail-ideas/' + btoa(id)])
  }

  actionTracker(id: any) {
    this.router.navigate(['actiontracker/detail-tracker/' + btoa(id)])
  }

  getRandomcolors(index?: any) {
    const colors: any[] = ["#ed5c49", "#f9a914", "#0f355a", "#48db7e", "#479cdd", "#ff9a07", "#6ce197", "#38c4ab", "#df559e", "#f07c6c"];
    this.ColorRandom = Math.floor(Math.random() * colors.length);
    this.ColorRandom = colors[index];
    return this.ColorRandom;

  }
  getRandomBg(index?: any) {
    const colors: any[] = ["#479cdd", "#ff9a07", "#6ce197", "#38c4ab", "#df559e", "#f07c6c", "#ed5c49", "#f9a914", "#0f355a", "#48db7e"];
    this.ColorRandom = Math.floor(Math.random() * colors.length);
    this.ColorRandom = colors[index];
    return this.ColorRandom;
  }
  viewSurveyDetails(form: any,evt:Event) {
    this.surveyService.setActiveTab("questions")
    this.router.navigate([`BVT/survey-details/${btoa(form?.id)}`])
  }
  openSurveyModal(id: any,evt:Event) {
    this.surveyService.setFormId(id);
    let dialogRef = this.dialog.open(PublishSurveyComponent,{panelClass:"survey-container"});
    evt.stopPropagation(); 
    dialogRef.afterClosed().subscribe((res)=>{
      console.log(res);
      
      if(res == true){
        this.refereshParentData.emit()
      }
    });
  }
  navigateToProject(){
    this.dialog.closeAll();
    this.router.navigate([`view/`+btoa(this.card_data?.id)+`/overview`]);
  }
}





