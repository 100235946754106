

<div *ngIf="!matrixOverView" style="overflow: hidden;">
    <div class="row" >
        <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
            <div class="column-bg-1 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                    <i class="fas fa-user-friends user-icon">
                    </i>
                    <p class="title">{{quadrants?.customer}}</p>
                </div>
                <div class="pr-2"><mat-icon (click)="quadrantInfo(140,info,'Customer')">info</mat-icon></div>
            </div>
            <div *ngIf="!noDataCustomer" >
            <!--Customer Card start-->
            <div class="table-responsive" style="height: 310px;">
                <table id="table-sparkline" class="table" style="font-size: 0.5rem;border-top:medium none;">
                <ng-container *ngTemplateOutlet="matricTableBody"></ng-container>
                    <tbody id="tbody-sparkline"  class="example-list" cdkDropListGroup cdkDropList [cdkDropListData]="customerData" (cdkDropListDropped)="drop($event,140)">
                        <tr title="click to drag and drop" *ngFor="let metric of customerData;index as i" cdkDrag  class="example-box" (click)="enableDragClick(i,140)"  [cdkDragDisabled]="dragCustomerEnableFlag[i] ? dragCustomerEnableFlag[i] : true">
                            <td (click)="clickOverView(140,metric,customerData,'Customer')" class="text-primary cursor-pointer"><span class="fw-bold" matTooltip="{{metric?.desc}}"  matTooltipClass="example-tooltip">{{metric?.name}}&nbsp; <span *ngIf="metric?.unit_name">({{metric?.unit_name}})</span></span></td>
                            <td>
                                <div [chart]="metric?.trend">
                                </div>
                            </td>
                            <td>
                                <span class="fw-bold">{{metric?.actual_value ? metric?.actual_value : 0}}</span>
                            </td>
                            <td class="text-center" *ngIf="metric.change != 'false'">
                                <div class="fw-bold" [ngClass]="metric.is_update == false ? 'spanGreen' : 'spanRed'">{{metric?.change ? metric?.change : 0}}</div>
                                <span class="px-2 d-block">{{metric?.variance_per}}%</span>
                            </td>
                            <td *ngIf="metric.change == 'false'">
                            </td>
                        </tr> 
                    </tbody>
                </table>    
            </div>
                <!--Customer Card end-->
            </div>
            <div class="row" *ngIf="noDataCustomer">
                <div class="col-md-5 mx-auto mt-5">
                    <img src="./assets/images/no-record.png" class="mt-3"
                        height="100%" width="100%">
                </div>
                <div class="col-md-8 mx-auto">

                    <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                        {{staticText?.common.no_data_found}}
                    </p>

                    <!-- <p class="text-center text-muted">
                        {{staticText?.delivery.scorecard_setup.click_toadd}}
                    </p> -->
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
            <div class="column-2-bg d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                    <i class="far fa-sack-dollar user-icon">
                    </i>
                    <p class="title">{{quadrants?.finance}}</p>
                </div>
                <div class="pr-2"><mat-icon (click)="quadrantInfo(138,info,'Finance')">info</mat-icon></div>
            </div>
            <div *ngIf="!noDataFinance">
                <!--finance Card start-->
                <div class="table-responsive" style="height: 310px;">
                    <table id="table-sparkline" class="table" style="font-size: 0.5rem;border-top:medium none;">
                        <ng-container *ngTemplateOutlet="matricTableBody"></ng-container>
                        <tbody id="tbody-sparkline" class="example-list" cdkDropListGroup cdkDropList [cdkDropListData]="financeData" (cdkDropListDropped)="drop($event,138)">
                            <tr title="click to drag and drop" *ngFor="let metric of financeData;index as i" cdkDrag  class="example-box"  (click)="enableDragClick(i,138)"  [cdkDragDisabled]="dragFinanceEnableFlag[i] ? dragFinanceEnableFlag[i] : true">
                                <td (click)="clickOverView(138,metric,financeData,'Finance')" class="text-primary cursor-pointer"><span class="fw-bold"  matTooltip="{{metric?.desc}}"  matTooltipClass="example-tooltip">{{metric?.name}}&nbsp; <span *ngIf="metric?.unit_name">({{metric?.unit_name}})</span></span></td>
                                <td>
                                    <div [chart]="metric?.trend">
                                    </div>
                                </td>
                                <td>
                                    <span class="fw-bold">{{metric?.actual_value ? metric?.actual_value : 0}}</span>
                                </td>
                                <td class="text-center">
                                    <div class="fw-bold" [ngClass]="metric.is_update == false ? 'spanGreen' : 'spanRed'">{{metric?.change ? metric?.change : 0}}</div>
                                    <span class="px-2 d-block">{{metric?.variance_per}}%</span>
                                </td>
                            </tr> 
                        </tbody>
                    </table>    
                </div>
                <!--finance Card end-->
            </div>
            <div class="row" *ngIf="noDataFinance">
                <div class="col-md-5 mx-auto mt-5">
                    <img src="./assets/images/no-record.png" class="mt-3"
                        height="100%" width="100%">
                </div>
                <div class="col-md-8 mx-auto">

                    <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                        {{staticText?.common.no_data_found}}
                    </p>

                    <!-- <p class="text-center text-muted">
                        {{staticText?.delivery.scorecard_setup.click_toadd}}
                    </p> -->
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
            <div class="column-bg-3 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                    <i class="fas fa-users user-icon">

                    </i>
                    <p class="title">{{quadrants?.engineering}}</p>
                </div>
                <div class="pr-2"><mat-icon (click)="quadrantInfo(139,info,'Engineering')">info</mat-icon></div>
            
            </div>
            <div *ngIf="!noDataDelivery">
                <!--engg Card start-->
                <div class="table-responsive" style="height: 310px;">
                    <table id="table-sparkline" class="table" style="font-size: 0.5rem;border-top:medium none;">
                        <ng-container *ngTemplateOutlet="matricTableBody"></ng-container>
                        <tbody id="tbody-sparkline" class="example-list" cdkDropListGroup cdkDropList [cdkDropListData]="engineerData" (cdkDropListDropped)="drop($event,139)">
                            <tr title="click to drag and drop" *ngFor="let metric of engineerData;index as i" cdkDrag  class="example-box" (click)="enableDragClick(i,139)"  [cdkDragDisabled]="dragEnggEnableFlag[i] ? dragEnggEnableFlag[i] : true">
                                <td (click)="clickOverView(139,metric,engineerData,'Engineering')" class="text-primary cursor-pointer"><span class="fw-bold"  matTooltip="{{metric?.desc}}"  matTooltipClass="example-tooltip">{{metric?.name}}&nbsp; <span *ngIf="metric?.unit_name">({{metric?.unit_name}})</span></span></td>
                                <td>
                                    <div [chart]="metric.trend">
                                    </div>
                                </td>
                                <td>
                                    <span class="fw-bold">{{metric?.actual_value ? metric?.actual_value : 0}}</span>
                                </td>
                                <td class="text-center">
                                    <div class="fw-bold" [ngClass]="metric.is_update == false ? 'spanGreen' : 'spanRed'">{{metric?.change ? metric?.change : 0}}</div>
                                    <span class="px-2 d-block">{{metric.variance_per}}%</span>
                                </td>
                            </tr> 
                        </tbody>
                    </table>    
                </div>
                <!--engg Card end-->
            </div>
            <div class="row" *ngIf="noDataDelivery">
                <div class="col-md-5 mx-auto mt-5">
                    <img src="./assets/images/no-record.png" class="mt-3"
                        height="100%" width="100%">
                </div>
                <div class="col-md-8 mx-auto">

                    <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                        {{staticText?.common.no_data_found}}
                    </p>

                    <!-- <p class="text-center text-muted">
                        {{staticText?.delivery.scorecard_setup.click_toadd}}
                    </p> -->
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-12 col-xl-6 mb-4">
            <div class="column-bg-4 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center justify-content-center">
                    <i class="fad fa-analytics user-icon">
                    </i>
                    <p class="title">{{quadrants?.people}}</p>
                </div>
                <div class="pr-2"><mat-icon (click)="quadrantInfo(137,info,'People')">info</mat-icon></div>
            
            </div>
            <div *ngIf="!noDataPeople">
                <!--people Card start-->
                <div class="table-responsive" style="height: 310px;">
                    <table id="table-sparkline" class="table" style="font-size: 0.5rem;border-top:medium none;">
                        <ng-container *ngTemplateOutlet="matricTableBody"></ng-container>
                        <tbody id="tbody-sparkline" class="example-list" cdkDropListGroup cdkDropList [cdkDropListData]="peopleData" (cdkDropListDropped)="drop($event,137)">
                            <tr title="click to drag and drop" *ngFor="let metric of peopleData;index as i" cdkDrag  class="example-box"  (click)="enableDragClick(i,137)"  [cdkDragDisabled]="dragPeopleEnableFlag[i] ? dragPeopleEnableFlag[i] : true">
                                <td (click)="clickOverView(137,metric,peopleData,'People')" class="text-primary cursor-pointer"><span class="fw-bold" matTooltip="{{metric.desc}}"  matTooltipClass="example-tooltip">{{metric.name}}&nbsp; <span *ngIf="metric?.unit_name">({{metric?.unit_name}})</span></span></td>
                                <td [attr.colspan]="metric.metric_name == 'req_aging' ? '2' : ''">
                                    <div [chart]="metric.trend">
                                    </div>
                                </td>
                                <td *ngIf="metric.actual_value != 'false'">
                                    <span class="fw-bold">{{metric?.actual_value ? metric?.actual_value : 0}}</span>
                                </td>
                                <td class="text-center" *ngIf="metric.change != 'false'">
                                    <div class="fw-bold" [ngClass]="metric.is_update == false ? 'spanGreen' : 'spanRed'">{{metric?.change ? metric?.change : 0}}</div>
                                    <span class="px-2 d-block">{{metric.variance_per}}%</span>
                                </td>
                                <td *ngIf="metric.change == 'false'">
                                </td>
                            </tr> 
                        </tbody>
                    </table>    
                </div>
                <!--people Card end-->
            </div>
            <div class="row" *ngIf="noDataPeople">
                <div class="col-md-5 mx-auto mt-5">
                    <img src="./assets/images/no-record.png" class="mt-3"
                        height="100%" width="100%">
                </div>
                <div class="col-md-8 mx-auto">
                    <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                        {{staticText?.common.no_data_found}}
                    </p>
                    <!-- <p class="text-center text-muted">
                        {{staticText?.delivery.scorecard_setup.click_toadd}}
                    </p> -->
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #matricTableBody>
    <thead>
        <tr>
            <th class="tblBg"> {{staticText?.balance_scorecard?.dashboard?.metrics}} </th>
            <th class="tblBg">{{staticText?.balance_scorecard?.dashboard?.trend}}</th>
            <th class="tblBg">Current Value</th>
            <th class="tblBg">{{staticText?.balance_scorecard?.dashboard?.change}}</th>
        </tr>
    </thead>
</ng-template>



<div *ngIf="matrixOverView" class="scorecard d-block mt-2">
    <div class="d-flex justify-content-between">
        <div>
            <h5>{{cardMatric?.name}} <span *ngIf="cardMatric?.unit_name">({{cardMatric?.unit_name}})</span></h5>
            <span>{{cardMatric?.desc}}</span>
        </div>
        <div>
            <div class="p-0 d-flex mb-2">
                <div class="mr-4 what-if-btn" (click)="openWhatIfModel(whatIf)">
                    What-if Analysis
                </div>
                <div (click)="closeMatrixOverview()" class=" cancle-btn-in float-right">
                    <mat-icon>arrow_back</mat-icon>
                </div>
            </div>
            <div class=" p-0 mb-1 d-flex" >
                <div class="p-4 pt-1 pb-0 text-center" style="background-color: rgb(237, 231, 225);" *ngIf="cardMatric?.actual_value != 'false'">
                    <span style="font-size: 12px;">Current Value</span>
                    <div class="mt-2">
                        <span class="fw-bold ">{{cardMatric?.actual_value ? cardMatric?.actual_value : 0}}</span>
                    </div>
                </div>
                <div class="pl-4 pr-4"></div>
                <div class="p-4 pt-1 pb-0 text-center" style="background-color: rgb(237, 231, 225);" *ngIf="cardMatric?.change != 'false'">
                    <span style="font-size: 12px;">% Change</span><br>
                    <div class="fw-bold" [ngClass]="cardMatric?.is_update == false ? 'spanGreen' : 'spanRed'">{{cardMatric?.change ? cardMatric?.change : 0}}</div>
                    <span class="px-2 d-block">{{cardMatric?.variance_per}}%</span>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-2 mb-2 trendchart">
        <div [chart]="cardMatric.overviewTrend"></div>
    </div>
    <mat-tab-group mat-align-tabs="start" class=" head" mat-stretch-tabs [selectedIndex]="0">
        <mat-tab  >
            <ng-template mat-tab-label>
                <div class="tab-label">
                  <img src="./assets/images/balancescore/metric_indicators/insights.png" width="24px" height="24px" class="mr-2" alt="Image Alt Text" class="tab-icon">
                  Insights
                </div>
              </ng-template>
            <div *ngIf="cardMatric?.powerBiReport?.length > 0">  
                <powerbi-report [embedConfig]="reportConfig" [cssClassName]="reportClass"></powerbi-report>
            </div>
            <div *ngIf="cardMatric?.powerBiReport?.length == 0" class="text-center p-4">
                <span>No reports found</span>
            </div>

        </mat-tab>
        <mat-tab label="Linked Metrics">
            <ng-template mat-tab-label>
                <div class="tab-label">
                  <img src="./assets/images/balancescore/metric_indicators/linkedmetrics.png" width="24px" height="24px" class="mr-2" alt="Image Alt Text" class="tab-icon">
                  Linked Metrics
                </div>
              </ng-template>
            <div>  
              <!-- <div *ngIf="linkedMatrics.length > 0" class="text-center slider-container" style="background-color: #e2eaf3;border-radius: 8px;">
                    <div style="color:rgb(88, 90, 90);margin-top: 20px;">Metrics slider</div>
                    <mat-slider color="primary"
                        thumbLabel="always"
                        #slider
                        [min]="minValue"
                        [max]="maxValue"
                        [(ngModel)]="sliderValue"
                        (input)="onInputChange($event)"  style="width:97%;color:#fff;">
                    </mat-slider>
                    <div style="margin-top:-20px;margin-left:10px;margin-right:10px;" class="text-center">
                        <div class="d-flex justify-content-between" >
                            <div>{{minValue}}</div>
                            <div>{{ sliderValue }}</div>
                            <div>{{maxValue}}</div>
                        </div>
                    </div>
                </div> -->
                <br>
                <div class="row" style="width:98%;margin-left: 10px;">
                    <div class="col-md-12  mb-4">
                        <div class="column-bg-1 d-flex align-items-center justify-content-between">
                            <div class="d-flex align-items-center justify-content-center ml-2">
                                <img src="./assets/images/balancescore/metric_indicators/linkedmetrics.png" width="24px" height="24px" class="mr-2" alt="Image Alt Text">
                                <span class="title">Linked Metrics</span>
                            </div>
                        </div>
                        <!--Customer Card start-->
                        <div class="table-responsive">
                            <table id="table-sparkline" class="table" style="font-size: 0.5rem;border-top:medium none;">
                                <thead>
                                    <tr>
                                        <th class="tblBg">Metrics</th>
                                        <th class="tblBg">{{staticText?.balance_scorecard?.dashboard?.trend}}</th>
                                        <th class="tblBg">Current Value <span *ngIf="cardMatric?.unit_name">({{cardMatric?.unit_name}})</span></th>
                                        <!-- <th class="tblBg">Variance</th> -->
                                    </tr>
                                </thead>
                                   <tbody id="tbody-sparkline"  class="example-list">
                                    <tr *ngFor="let item of linkedMatrics; let i = index"   class="example-box">
                                        <td><span class="fw-bold" matTooltipClass="example-tooltip">{{item?.display_name}}</span></td>
                                        <td>
                                            <div  [chart]="item?.trend">
                                            </div>
                                        </td>
                                        <td>
                                            <span class="fw-bold">{{formatNumber(item?.actual_value[item?.actual_value.length-1]?.actual_value ? item?.actual_value[item?.actual_value.length-1]?.actual_value : 0,2 )}}</span>
                                        </td>
                                        <!-- <td class="text-center">
                                            <div class="fw-bold">
                                                {{getDifference(item?.actual_value[item?.actual_value?.length-1]?.actual_value,cardMatric?.actual_value) | number:'1.2-2'}}
                                            </div>
                                        </td> -->
                                    </tr> 
                                </tbody>
                            </table>    
                        </div>
                            <!--Customer Card end-->
                        </div>
                        <div class="row"  *ngIf="linkedMatrics?.length == 0">
                            <div class="col-md-4 mx-auto">
                                <img src="./assets/images/no-record.png" class="mt-3"
                                    height="100%" width="100%">
                            </div>
                            <div class="col-md- 4 mx-auto">
            
                                <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                                    {{staticText?.common.no_data_found}}
                                </p>
                            </div>
                        </div>
                </div>
            </div>    
        </mat-tab>
        <mat-tab label="Performance BenchMark">
            <ng-template mat-tab-label>
                <div class="tab-label">
                  <img src="./assets/images/balancescore/metric_indicators/perforamanceBench.png" width="24px" height="24px" class="mr-2" alt="Image Alt Text" class="tab-icon">
                  Performance Benchmark
                </div>
              </ng-template>

              <div class="row mt-4" style="width:98%;margin-left: 10px;">
                <div class="col-md-12  mb-4">
                    <div class="column-2-bg d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center justify-content-center ml-2">
                            <img src="./assets/images/balancescore/metric_indicators/perforamanceBench.png" width="24px" height="24px" class="mr-2" alt="Image Alt Text">
                            <span class="title">Performance Benchmark</span>
                        </div>
                    </div>
                    <!--Customer Card start-->
                    <div class="table-responsive">
                        <table id="table-sparkline" class="table" style="font-size: 0.5rem;border-top:medium none;">
                            <thead>
                                <tr>
                                    <th class="tblBg"> {{benchaMarkType}}</th>
                                    <th class="tblBg">{{staticText?.balance_scorecard?.dashboard?.trend}}</th>
                                    <th class="tblBg">Current Value <span *ngIf="cardMatric?.unit_name">({{cardMatric?.unit_name}})</span></th>
                                    <th class="tblBg">Variance</th>
                                </tr>
                            </thead>
                            <tbody id="tbody-sparkline"  class="example-list">
                                <tr *ngFor="let item of performanceBenchMark; let i = index"   class="example-box">
                                    <td><span class="fw-bold" matTooltipClass="example-tooltip">{{item?.name}}</span></td>
                                    <td>
                                        <div [chart]="item?.trend">
                                        </div>
                                    </td>
                                    <td>
                                        <span class="fw-bold">{{formatNumber(item?.actual_value[item?.actual_value.length-1]?.actual_value ? item?.actual_value[item?.actual_value.length-1]?.actual_value : 0, 2) }}</span>
                                    </td>
                                    <td class="text-center">
                                        <div class="fw-bold"> 
                                            <span class="spanvalue" [ngStyle]="item?.status  ? {'background-color':item?.status} : ''">
                                            {{getDifference(item?.actual_value[item?.actual_value?.length-1]?.actual_value,cardMatric?.actual_value)  | number:'1.2-2'}}
                                            </span>
                                        </div>
                                        <!-- <span class="px-2 d-block">{{metric?.variance_per}}%</span> -->
                                    </td>
                                </tr> 
                            </tbody>
                        </table>    
                    </div>
                        <!--Customer Card end-->
                    </div>
                    <div class="row"  *ngIf="performanceBenchMark?.length == 0">
                        <div class="col-md-4 mx-auto">
                            <img src="./assets/images/no-record.png" class="mt-3"
                                height="100%" width="100%">
                        </div>
                        <div class="col-md- 4mx-auto">
        
                            <p class="text-center text-muted mt-2 font-weight-bold mb-1">
                                {{staticText?.common.no_data_found}}
                            </p>
                        </div>
                    </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>



<ng-template #info>
    <div class="modal-dialog custom-width m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="infoQuadrantName"> </app-modal-header-common>

            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="custom-height infoQuadrant">
                            <div class="custom-table table-responsive" style="max-height: calc(100vh - 400px);">
                                <table class="table table-bordered">
                                    <thead style="position: sticky; z-index: 1">
                                        <tr>
                                            <th>Metrics</th>
                                            <th>Description</th>
                                            <th>Unit Name</th>
                                            <th>MOS Type Name</th>
                                            <th>On Track Value</th>
                                            <th>Off Track Value</th>
                                            <th>At Risk</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of quadrantInfoDetails;let i= index">
                                            <td>{{item?.display_name}}</td>
                                            <td>{{item?.description}}</td>
                                            <td>{{item?.unit_name}}</td>
                                            <td>{{item?.mos_type_name}}</td>
                                            <td>{{item?.off_track_value}}</td>
                                            <td>{{item?.on_track_value}}</td>
                                            <td>{{item?.at_risk_value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button [disabled]="disableFields" mat-dialog-close (click)="dialogClose()"
                            class="bg-primary text-white">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #whatIf>
    <div class="modal-dialog what-if-modal m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="'What-if Analysis'"> </app-modal-header-common>

            <div class="modal-body">
                <div class="row mb-2">
                    <div class="col-sm-3">
                        <h6>Primary Metric</h6>
                        <div class="metric-info-holder">
                            <p class="metric-name">{{cardMatric?.name}}  <span *ngIf="cardMatric?.unit_name">({{cardMatric?.unit_name}})</span></p>
                            <p class="metric-value-txt">Current Value:</p>
                            <p class="metric-value">{{cardMatric?.actual_value ? cardMatric?.actual_value : 0}}</p>
                        </div>
                    </div>
                    <div class="col-sm-6" style="display: flex; align-items: center">
                        <div class="wrapper">
                            <input class="input-range" type="range" min="0" max="100" value="0" [(ngModel)]="sliderValue"
                            (input)="onInputChange($event)" />
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <div class="metric-info-holder" style="text-align: right;">
                            <p class="mb-1" style="line-height: 1.2; margin-top: 10px; font-size: 10px;">Increased by <br> <span style="font-size: 22px; font-weight: 700;">{{sliderValue > 0 ? sliderValue : 0}}%</span></p>
                            <p class="metric-value-txt">Change Value:</p>
                            <p class="metric-value">{{ (sliderValue > 0 ? cardMatric?.actual_value * sliderValue : cardMatric?.actual_value) | number:'1.2-2'  }}</p>
                        </div>
                    </div>
                </div>

                <hr>

                <h6>Linked Metrics</h6>

                <div style="display: flex; height: calc(100vh - 60vh); overflow-y: scroll; overflow-x: hidden;">
                    <div style="flex: auto;">
                        <div class="row mb-2" *ngFor = "let item of whatIfValues | slice: 1; let i = index">
                            <div class="col-sm-5">
                                <div class="lmetric-card">
                                    <div class="lmetric-name-holder">
                                        <h6>{{item?.name}} <span *ngIf="item?.unit_name">({{item?.unit_name}})</span></h6>
                                    </div>
                                    <div class="lmetric-value-holder">
                                        <h4>{{item?.actual_value ? item?.actual_value : 0 }}</h4>
                                        <p>Current Value</p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="col-sm-7">
                                <p class="item-change-value">{{(sliderValue > 0 ? i == 1 ? -(item?.actual_value * sliderValue) : (item?.actual_value * sliderValue) : item?.actual_value) | number:'1.2-2'}} <span style="display: block; font-size: 9px; font-weight: 100;">Change value</span></p>
                                <input type="range" min="-{{item?.actual_value * 100}}" max="{{item?.actual_value * 100}}" [value]="sliderValue > 0 ? i == 1 ? -(item?.actual_value * sliderValue) : (item?.actual_value * sliderValue) : item?.actual_value" class="range blue" disabled/>
                            </div>
                            
                        </div>
                    </div>
                </div>
<!--                 
                <div style="color:rgb(88, 90, 90);">Metrics slider</div>
                    <mat-slider color="primary"
                        thumbLabel="always"
                        #slider
                        [min]="minValue"
                        [max]="maxValue"
                        [(ngModel)]="sliderValue"
                        (input)="onInputChange($event)"  style="width:97%;color:#fff;">
                    </mat-slider>
                    <div style="margin-top:-20px;margin-left:10px;margin-right:10px;" class="text-center">
                        <div class="d-flex justify-content-between" >
                            <div>{{minValue}}</div>
                            <div>{{ sliderValue }}</div>
                            <div>{{maxValue}}</div>
                        </div>
                    </div>
                <div class="custom-height">
                    <div class="custom-table table-responsive" style="max-height: calc(100vh - 400px);">
                        <table class="table table-bordered">
                            <thead style="position: sticky; z-index: 1">
                                <tr>
                                    <th>Metrics</th>
                                    <th>Current Value</th>
                                    <th>Change Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor = "let item of whatIfValues">
                                    <td><span class="fw-bold" matTooltipClass="example-tooltip">{{item?.name}} <span *ngIf="item?.unit_name">({{item?.unit_name}})</span></span></td>
                                    
                                    <td>
                                        <span class="fw-bold" *ngIf="item?.actual_value != 'false'">{{item?.actual_value ? item?.actual_value : 0 }}</span>
                                    </td>
                                    <td>
                                        <span class="fw-bold" *ngIf="item?.actual_value != 'false'">{{ item?.actual_value*sliderValue | number:'1.2-2'}}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
                 -->
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button [disabled]="disableFields" mat-dialog-close (click)="dialogClose()"
                            class="bg-primary text-white">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>    
</ng-template>