<div id="toeconvergence">
    <header class="toeconvergence-Header">
        <div class="w-100 bg-lavendar mt-80">
            <app-header></app-header>
        </div>
        <div class="d-flex flex-column">
            <div class="bg-cont-overlay">
                <div class="container-fluid container-xxl">
                    <div class="row">
                        <div class="col">
                            <h5 class="text-white">{{staticText?.toe_master.title}}</h5>
                        </div>
                        <div class="col">
                            <div class="text-right">
                                <ul class="m-0 p-0 fs-12 breadcrumb-bs">
                                    <li><span routerLink="/home"
                                            class="color-Aquamarine cursor-pointer mr-2">{{staticText?.menu.home}}</span>
                                    </li>
                                    <li><span class="text-white">{{staticText?.toe_master.title}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <section class="toeconvergence-body" id="toeforms">
        <div class="container-fluid container-xxl  mb-3">
            <div class="row row-cols-2 margin-minus-50">
                <!------------------Left pane------------>
                <div class="col-3 leftpane">
                    <mat-card class="mat-card-shadow p-2 pb-0 mb-2 bg-transparent">
                        <div class="img-container d-flex flex-column h-100">
                            <div class="d-flex justify-content-between align-items-center p-3">
                                <p class="h5 fw-500 fs-18 mb-0">{{staticText?.toe_master.level1_title}}</p>
                                <span class="material-icons-outlined fs-30 cursor-pointer" matTooltip="Add Category" *ngIf="!showGoalInput"
                                    (click)="showGoalInput=true;">add_circle</span>
                            </div>
                            <ng-container *ngIf="showGoalInput">
                                <form [formGroup]="toeCategoryForm">
                                    <div class="comment-Text-Goal p-2">
                                        <div class="input-container">
                                            <input class="border-bottom" type="text" placeholder="{{staticText?.toe_master.level1_title}}"
                                                formControlName="categoryName" required>

                                            <span class="d-flex justify-content-start"
                                                *ngIf="toeCategoryForm.get('categoryName').errors && toeCategoryFormSubmit">
                                                <small class="text-danger text-capitalize"
                                                    *ngIf="toeCategoryForm.get('categoryName').errors.required">
                                                    {{staticText?.toe_master.level1_title_err}}</small>
                                            </span>
                                            <div class="action-btns mt-2 d-flex flex-row justify-content-end align-items-center cursor-pointer">
                                                <button (click)="showGoalInput=false;" status="primary"
                                                    mat-button mat-flat-button
                                                    class="me-2 bg-light border">{{staticText?.buttons.cancel_btn}}
                                                </button>
                                                <button class="addtoeSubCategory text-white text-capitalize float-right"
                                                    mat-raised-button (click)="createtoeCategory();showGoalInput=false">
                                                    {{staticText?.buttons?.save_btn}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-container>
                        </div>
                    </mat-card>
                    <mat-card>                        
                        <mat-card-content class="toeLeftList scroll-container" [ngClass]="showGoalInput ? 'showgoal':'hidegoal'">
                            <div class="left-ExpansionPanel">
                                <div class="py-0" *ngFor="let toeCategory of toeList;index as toeCategoryIndex">
                                    <div class="accordion accordion-flush goalaccordion"
                                        [id]="'toeLeftParentGoal'+toeCategoryIndex">
                                        <div class=" goalitem">
                                            <div class="accordion-header p-1 goalheader">
                                                <div class="d-flex flex-column justify-content-around">
                                                    <div class="d-flex flex-row align-items-center justify-content-between">                                                       
                                                        <div class="d-flex align-items-center accordion-item cursor-pointer border-0" (click)="onClickLeftPane(toeCategory,toeCategoryIndex)" data-bs-toggle="collapse"
                                                        [attr.data-bs-target]="'#goal'+'toeLeftParentGoal'+toeCategoryIndex"
                                                        aria-expanded="true" [attr.aria-controls]="'goal'+toeCategoryIndex">
                                                            <div class="treearrowgoal d-flex align-items-center">
                                                                <span class="material-icons-outlined fs-30"
                                                                    *ngIf="!parentTree[toeCategoryIndex]">
                                                                    arrow_right
                                                                </span>
                                                                <span class="material-icons-outlined fs-30"
                                                                    *ngIf="parentTree[toeCategoryIndex]">
                                                                    arrow_drop_down
                                                                </span>
                                                            </div>
                                                            <div >
                                                                <h5 class="fw-bold goalhead">
                                                                    {{toeCategory?.category_display_name | titlecase}}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex align-items-center">                                                            
                                                            <div *ngIf="parentTree[toeCategoryIndex]">
                                                                <span class="material-icons-outlined fs-30 cursor-pointer" matTooltip="Add SubCategory"
                                                                    (click)="showSubCategoryInput[toeCategoryIndex]=true;">add_circle</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordion-collapse collapse"
                                            [id]="'goal'+'toeLeftParentGoal'+toeCategoryIndex"
                                            [attr.aria-labelledby]="'toeLeftParentGoal'+toeCategoryIndex"
                                            [attr.data-bs-parent]="'toeLeftParentGoal'+toeCategoryIndex"
                                            [ngClass]="{'show': toeCategoryIndex === toeCategoryIndexVal}">
                                            <div class="accordion-body goalbody pt-0 pb-0 pe-0 ps-0 ">
                                                <div class="d-flex flex-column p-1">
                                                    <ng-container *ngIf="showSubCategoryInput[toeCategoryIndex]">
                                                        <form [formGroup]="toeSubCategoryForm">
                                                            <mat-card class="mb-3">
                                                                <div class="d-flex flex-column">
                                                                    <div class="comment-Text-SubCategory p-1">
                                                                        <div class="input-container">
                                                                            <input type="text"
                                                                                placeholder="{{staticText?.toe_master.level2_title}}"
                                                                                formControlName="subCategoryName">
                                                                            <span class="d-flex justify-content-start"
                                                                                *ngIf="toeSubCategoryForm.get('subCategoryName').errors && toeSubCategoryFormSubmit">
                                                                                <small class="text-danger text-capitalize"
                                                                                    *ngIf="toeSubCategoryForm.get('subCategoryName').errors.required">
                                                                                    {{staticText?.toe_master.level2_title}}</small>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex flex-row justify-content-start p-1 mt-2">
                                                                        <div class="px-1">
                                                                            <button
                                                                                class="addtoeSubCategory text-white text-capitalize float-right"
                                                                                mat-raised-button
                                                                                (click)="createtoeSubCategory();showSubCategoryInput[toeCategoryIndex]=false">
                                                                                {{staticText?.common?.add}}
                                                                            </button>
                                                                        </div>                                                                    <div class="px-1">
                                                                            <button type="button"
                                                                                class="btn btn-light close-btn mr-2 cursor-pointer"
                                                                                (click)="showSubCategoryInput[toeCategoryIndex]=false;">
                                                                                Cancel
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </mat-card>
                                                        </form>
                                                    </ng-container>
                                                    <div class="toe-subParent-container pe-2 ps-2 mx-3 cursor-pointer" 
                                                        *ngFor="let subParent of toeCategory?.term_sub_category;index as toeSubParentIndex;let first=first" [ngClass]="{'mt-3':!first}"
                                                        (click)="showCenterPaneData($event,subParent,toeSubParentIndex,toeCategory.category_uuid);">
                                                        <div class="card p-0 shadow-none sub-parentcontext"
                                                            [ngStyle]="{'background-color': selectedCardIndex == toeSubParentIndex ? (toeSubParentIndex|getRandomColor) : '#ffffff','color':selectedCardIndex == toeSubParentIndex ? '#ffffff': '#000000'}">
                                                            <div class="bubble-top"
                                                                [ngStyle]="{'background-color':(toeSubParentIndex|getRandomColor)}">
                                                            </div>
                                                            <div class="card-body px-2 py-1">
                                                                <h5 class="subParent-Heading fs-16 threeline-sentence mb-0">
                                                                    <span class="fw-400"
                                                                        matTooltip={{subParent?.sub_category_display_name}}
                                                                        matTooltipClass="example-tooltip">
                                                                        {{subParent?.sub_category_display_name}}
                                                                    </span>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <div class="text-center no-Records" *ngIf="!toeList?.length">
                            <p class="my-2"> {{staticText.common.no_records_found}}</p>
                        </div>
                    </mat-card>
                </div>
                <div class="col-9">
                    <div class="row row-cols-2">
                        <!------------------center pane------------>
                        <div class="col-12">
                            <mat-card class="centerpane mat-card-shadow border-radius-15 p-0">
                                <div class="centerPane-header d-flex pt-3" *ngIf="mastertoeList?.sub_category_uuid">
                                    <span class="bubble-left pt-2 pb-2"
                                        [ngStyle]="{'background-color': mastertoeList?.selectedCardBgColor}"></span>
                                    <div class="container-fluid  pe-3 ps-3 w-100">
                                        <div class="row">
                                            <h5 class="subParent-Heading fs-16 pt-1 pb-1 twoline-sentence col-8">
                                                {{staticText.toe_master.level3_title}}
                                            </h5>
                                        </div>
                                    </div>
                                </div>

                                <div class="container-fluid pe-3 ps-3 py-2">
                                    <div class="centerPane-body mb-2 pb-2" *ngIf="mastertoeList?.sub_category_uuid">
                                        <div class="d-flex justify-content-between align-items-center" >
                                            <div class="fs-14 color-river-bed"
                                                    matTooltip={{mastertoeList?.sub_category_display_name}}
                                                    matTooltipClass="example-tooltip">
                                                    <b>{{mastertoeList?.sub_category_display_name}}</b>
                                        </div>
                                            <p class="mb-0 text-capitalize addtoeOwners cursor-pointer color-green d-flex align-items-center fw-500 flex-column"
                                                style="font-size: 15px;"
                                                (click)="onClickAddToE()"
                                                [ngStyle]="{'opacity': showToeForm ? 0.37 : 'unset'}"
                                                [ngClass]="{'text-muted': !mastertoeList?.sub_category_uuid}">
                                                <span class="material-icons-outlined fs-30 flex-column">
                                                    add_circle
                                                </span>
                                                <span>add {{staticText.toe_master.level3_title}}</span>
                                            </p>
                                        </div>
                                        <ng-container *ngIf="showToeForm">
                                            <div class="pt-1 field-adjust">
                                                <form [formGroup]="toeForm">
                                                    <div class="mb-3 d-flex">
                                                        <div class="bubble-top bg-1 mr-2"></div>
                                                        <div class="d-flex">
                                                            <label class="labelstyle">{{staticText?.toe_master?.sub_title1}}</label>
                                                        </div>
                                                        <hr>
                                                    </div>                    
                                                    <div class="row col-12 px-0 details-bg-1 mx-0">                    
                                                        <div class="col-5">
                                                            <mat-form-field appearance="outline" class="w-100 h-25">
                                                                <mat-label>{{staticText?.toe_master.field1}}</mat-label>
                                                                <input matInput type="text"
                                                                    formControlName="title"
                                                                    placeholder="{{staticText.toe_master.field1}}">
                                                            </mat-form-field>
                                                        </div>
                                                        
                                                        <div class="col-7">
                                                            <mat-form-field appearance="outline" class="w-100" maxlength="255" [ngClass]="{ 'mat-form-field-invalid':toeFormSubmit && toeForm.get('description')?.errors?.required}">
                                                                <mat-label>{{staticText?.toe_master.field2}}</mat-label>
                                                                <textarea matInput formControlName="description"
                                                                    cdkTextareaAutosize placeholder="{{staticText?.toe_master.field2}}"
                                                                    ></textarea>
                                                            </mat-form-field>
                                                        </div>
                                                        <div  class="col-4">                                                                                                    
                                                            <mat-form-field appearance="outline" class="w-100" [ngClass]="{ 'mat-form-field-invalid':toeFormSubmit && toeForm.get('function')?.errors?.required}">
                                                                <mat-label> {{staticText?.toe_master.field3}}</mat-label>
                                                                <input matInput [matAutocomplete]="auto1" type="text"
                                                                formControlName="function">
                                                                <mat-icon matSuffix class="muted">search</mat-icon>
                                                                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFnName">
                                                                    <mat-option *ngFor="let option of filterFunctionTracker | async"
                                                                        [value]="option">
                                                                        {{option.display_name| titlecase}}
                                                                    </mat-option>
                                                                </mat-autocomplete>
                                                                <!-- <mat-select formControlName="function"
                                                                    placeholder="{{staticText?.toe_master.field3}}">
                                                                    <mat-option *ngFor="let opt of unitOptions" class="mosauto"
                                                                        [value]="opt.display_name">{{opt?.display_name}}
                                                                    </mat-option>
                                                                </mat-select> -->
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-4 pr-0">                                                                                                    
                                                            <mat-form-field appearance="outline" class="w-100" [class.mat-form-field-invalid]="toeFormSubmit && toeForm.get('fulfilmentBy')?.errors?.required">
                                                                <mat-label> {{staticText?.toe_master.field5}}</mat-label>
                                                                <mat-select formControlName="fulfilmentBy" placeholder="{{staticText?.toe_master.field5}}">
                                                                     <mat-option class="mosauto" value="Self">Self</mat-option>
                                                                     <mat-option class="mosauto" value="Vendor">Vendor</mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                        </div>
                                                        <div class="col-4 px-5">   
                                                            <label class="fs-14 mx-1 mt-2 mb-1 w-100" [class.field-invalid]="toeFormSubmit && toeForm.get('isMandatory')?.errors?.required">{{staticText?.toe_master.field4}}</label>
                                                            <mat-radio-group  class="m-1 mt-0 d-flex align-items-center p-0" formControlName="isMandatory" >
                                                                <mat-radio-button class="fs-6 fw-500" [value]='true'>Yes
                                                                </mat-radio-button>
                                                                <mat-radio-button class="fs-6 pl-3 ml-3 fw-500" [value]='false'>No
                                                                </mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3 d-flex mt-2">
                                                        <div class="bubble-top bg-2 mr-2"></div>
                                                        <div class="d-flex">
                                                            <label class="labelstyle">{{staticText?.toe_master?.sub_title2}}</label>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                    <div class="row col-12 px-0 details-bg-2 mx-0">                                                        
                                                        <div class="d-flex col-6">
                                                            <div class="col-10 p-0">
                                                                <mat-form-field appearance="outline" class="w-100">
                                                                    <mat-label>{{staticText?.toe_master.field6}}</mat-label>
                                                                    <input matInput [matAutocomplete]="automos" type="text" formControlName="searchMetric" (change)="getMetricDetails($event?.target?.value)" (keyup.enter)="getMetricDetails($event?.target?.value);$event.preventDefault()">
                                                                    <mat-icon matSuffix class="muted" >search</mat-icon>
                                                                    <mat-autocomplete #automos="matAutocomplete" [displayWith]="displayFnName" (optionSelected)="onSelectionChange($event,addMoS)">
                                                                        <mat-option *ngFor="let option of filterOptionType | async"  [value]="option">
                                                                            {{option.name}}
                                                                        </mat-option>
                                                                    </mat-autocomplete>
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-2 p-0 d-flex align-items-center">
                                                                <span class="material-icons-outlined fs-30 color-green d-flex w-100 justify-content-center cursor-pointer mt-2" matTooltip="Add Metric" (click)="editObject={};openMoSDialog(addMoS,child)">add_circle</span>
                                                            </div>
                                                        </div> 
                                                        <div class="col-12"  *ngIf="metricsArray && metricsArray?.length>0">
                                                            <div class="accordion mt-3 overflow-hidden" id="accordionExample">
                                                                <div class="accordion-item my-2" *ngFor="let metricITem of metricsArray;let m=index">
                                                                    <h2 class="accordion-header" id="headingOne{{m}}">
                                                                        <button class="accordion-button pl-2" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+m" aria-expanded="true">
                                                                            <div class="col-10 p-0">{{metricITem?.name}}</div>
                                                                            <div class="col-1 p-0 d-flex justify-content-around align-items-center">
                                                                                <img class="edit-icon" src="/assets/Icons/table-card/edit.svg" (click)="$event.stopPropagation();onClickEdit(metricITem,addMoS)"/>
                                                                                <img class="edit-icon" src="/assets/images/comment-delete.svg" (click)="$event.stopPropagation();deleteMetric(m)" alt="delete">
                                                                            </div>
                                                                        </button>    
                                                                    </h2>
                                                                    <div id="collapseOne{{m}}" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                        <div class="accordion-body p-0 pt-3">
                                                                            <div class="row">
                                                                                <div class="col-md-3 mb-3">
                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field1}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.name||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3">
                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field2}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.description||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3">
                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field3}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.mos_type_name||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3">
                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field4}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.frequency||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field5}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.unit_name||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3" *ngIf=" metricITem?.mos_type_name=='HTB' || metricITem?.mos_type_name=='LTB'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field11}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.mos_type_name=='HTB'?(metricITem?.max_value||''):(metricITem?.min_value||'')}}</p>
                                                                                </div> 
                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field6}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.from_value||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field7}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.on_track_value||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field8}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.at_risk_value||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field9}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.off_track_value||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field10}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.to_value||''}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Binary(Adherence)'">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field15}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.binary_value?'Yes':'No'}}</p>
                                                                                </div>
                                                                                <div class="col-md-3 mb-3">
                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field16}}</p>
                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.remarks||''}}</p>
                                                                                </div>
                                                                            </div>                                                                
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3 d-flex mt-2">
                                                        <div class="bubble-top bg-3 mr-2"></div>
                                                        <div class="d-flex">
                                                            <label class="labelstyle">{{staticText?.toe_master?.field7}}</label>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                    <div class="row col-12 px-0 details-bg-3 mx-0">
                                                        <div class="col-12 pr-0">
                                                            <!-- <label class="fs-14 mx-1 mt-2 mb-1" >{{staticText?.toe_master.}}</label> -->
                                                            <div class="d-flex">
                                                                <div class="col-4 pl-0" *ngFor="let applicability of mastersArray?.applicability">                                                                                                    
                                                                    <!-- <mat-form-field appearance="outline" class="w-100" [class.mat-form-field-invalid]="submitted && toeForm.get(applicability?.name)?.errors?.required">
                                                                        <mat-label> {{applicability?.display_name}}</mat-label>
                                                                        <mat-select formControlName="{{applicability?.name}}"
                                                                            placeholder="{{applicability?.display_name}}">
                                                                            <mat-option *ngFor="let opt of applicability?.child" class="mosauto"
                                                                                [value]="opt">{{opt?.display_name}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field> -->
                                                                    <mat-form-field class="example-chip-list w-100 h-25" appearance="outline" [class.mat-form-field-invalid]="submitted && toeForm.get(applicability?.name)?.errors?.required">
                                                                        <mat-chip-list #chipList >
                                                                            <mat-chip class="d-flex flex-row"
                                                                                *ngFor="let selectedList of toeForm.get(applicability?.name).value | slice:0:2"
                                                                                [selectable]="true" [removable]="true" (removed)="removeOption(applicability?.name,selectedList?.name)">
                                                                                <span class="words-ellipse">{{selectedList?.display_name}}</span>
                                                                                <button matChipRemove>
                                                                                    <mat-icon>cancel</mat-icon>
                                                                                </button>
                                                                            </mat-chip>
                                                                            <span class="ownersCountBadge" matTooltip="{{(toeForm.get(applicability?.name).value | slice:2)|joinArrayValues:'display_name'}}"
                                                                                *ngIf="toeForm.get(applicability?.name).value?.length > 2">
                                                                                <span>
                                                                                    &plus;{{(toeForm.get(applicability?.name).value?.length-2) ||
                                                                                    0}}
                                                                                </span>
                                                                            </span>
                                                                            <input placeholder="{{applicability?.display_name}}" 
                                                                                [matChipInputFor]="chipList" onkeypress="return false"
                                                                                [matAutocomplete]="supportOwners">
                                                                            <!-- <mat-icon matSuffix class="muted">search</mat-icon> -->
                                                                        </mat-chip-list>
                                                                        <mat-autocomplete #supportOwners="matAutocomplete" (optionSelected)="selected(applicability?.name,$event)" >
                                                                            <mat-option *ngFor="let opt of applicability?.child" [disabled]="jsonString(toeForm.get(applicability?.name).value)?.includes(opt?.display_name)"
                                                                                [value]="opt">
                                                                                {{opt.display_name}}
                                                                            </mat-option>
                                                                        </mat-autocomplete>
                                                                    </mat-form-field>
                                                                </div>
                                                            </div>
                                                        </div>   
                                                    </div>   
                                                    <div class="mb-3 d-flex mt-2">
                                                        <div class="bubble-top bg-4 mr-2"></div>
                                                        <div class="d-flex">
                                                            <label class="labelstyle">{{staticText?.toe_master?.field8}}</label>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                    <div class="row col-12 px-0 details-bg-4 mx-0">                                               
                                                        <div class="col pr-0">                                                              
                                                            <div>
                                                                <div class="d-flex" *ngFor="let consequence of mastersArray?.consequences">
                                                                    <div class="col-2 d-flex align-items-center"><mat-checkbox (change)="onSelectCheckbox(consequence?.name,$event)" formControlName="{{consequence?.name}}">{{consequence?.display_name}}</mat-checkbox></div>
                                                                    <div class="col-5 pl-0">
                                                                        <mat-form-field appearance="outline" class="w-100" [ngClass]="{ 'mat-form-field-invalid':submitted && toeForm.get(consequence?.name+'description')?.errors?.required}">
                                                                            <mat-label>{{staticText?.toe_master.field8_4}}</mat-label>
                                                                            <textarea matInput formControlName="{{consequence?.name+'_description'}}" maxlength="255" 
                                                                                cdkTextareaAutosize placeholder="{{staticText?.toe_master.field8_4}}"
                                                                                ></textarea>
                                                                        </mat-form-field>
                                                                    </div>
                                                                </div>                                                                
                                                            </div>
                                                        </div>
                                                    </div>                                                    
                                                    <div class="mb-3 d-flex mt-2">
                                                        <div class="bubble-top bg-5 mr-2"></div>
                                                        <div class="d-flex">
                                                            <label class="labelstyle">{{staticText?.toe_master?.field9}}</label>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                    <div class="row col-12 px-0 details-bg-5 mx-0">
                                                        <div class="col-12">
                                                            <section class="example-section col-7 p-0 mb-0">
                                                                <div class="d-flex justify-content-between px-3">
                                                                    <p *ngFor="let evidence of mastersArray?.evidence"><mat-checkbox  formControlName="{{evidence?.name}}">{{evidence?.display_name}}</mat-checkbox></p>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div class="mb-3 d-flex mt-2">
                                                        <div class="bubble-top bg-6 mr-2"></div>
                                                        <div class="d-flex">
                                                            <label class="labelstyle">{{staticText?.toe_master?.field10}}</label>
                                                        </div>
                                                        <hr>
                                                    </div>
                                                    <div class="row col-12 px-0 details-bg-6 mx-0">
                                                        <div class="col-5 pr-0">
                                                            <section class="example-section my-0">
                                                                <div class="d-flex px-3">
                                                                    <mat-checkbox *ngFor="let compliance of mastersArray?.compliance;let first=first" [ngClass]="{'ml-5':!first}" formControlName="{{compliance?.name}}">{{compliance?.display_name}}</mat-checkbox>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div class=" mt-3 col-12 d-flex justify-content-end align-items-center" >
                                                        <div class="mr-2">
                                                            <button mat-flat-button  type="button" class="text-blue" (click)="showToeForm=false">
                                                                {{staticText?.buttons?.cancel_btn}}</button>
                                                        </div>
                                                        <button class="addOwners text-white text-capitalize cursor-pointer"
                                                            mat-button  type="button"
                                                            (click)="createToE()">
                                                            {{staticText.buttons.save_btn}}
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <mat-card-content class="toeCenterList scroll-container pt-1" *ngIf="noDataShow">
                                        <div class="pt-1 pb-1"
                                            *ngFor="let child of mastertoeList?.term;index as toeChildIndex">
                                            <div class="accordion toeChildAccordion"
                                                [id]="'toeChildMaster'+toeChildIndex">
                                                <div class="accordion-item b-none">
                                                    <div class="accordion-header py-2">
                                                        <div class="d-flex">
                                                            <div class="flex-fill container-fluid p-0" >
                                                                <div class="d-flex align-items-center">
                                                                    <div class="d-flex cursor-pointer" (click)="showRightPaneData(child,toeChildIndex);" data-bs-toggle="collapse" aria-expanded="true"
                                                                    [attr.data-bs-target]="'#toeChild'+toeChildIndex"
                                                                    [attr.aria-controls]="'toeChild'+toeChildIndex">
                                                                        <div class="treearrowgoal d-flex align-items-center">
                                                                            <span class="material-icons-outlined fs-30"
                                                                                *ngIf="!childTree[toeChildIndex]">
                                                                                arrow_right
                                                                            </span>
                                                                            <span class="material-icons-outlined fs-30"
                                                                                *ngIf="childTree[toeChildIndex]">
                                                                                arrow_drop_down
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="d-flex col pl-0 cursor-pointer" data-bs-toggle="collapse" aria-expanded="true"
                                                                    [attr.data-bs-target]="'#toeChild'+toeChildIndex"
                                                                    [attr.aria-controls]="'toeChild'+toeChildIndex" (click)="showRightPaneData(child,toeChildIndex);">
                                                                        <h5 class="fs-16 threeline-sentence mb-0 tag-hold" (click)="$event.stopPropagation()">
                                                                            <span matTooltip={{child?.title}}
                                                                                matTooltipClass="example-tooltip">
                                                                                {{child?.title}}

                                                                                <app-tag [dataObj]="getTagObject(child.id)" [tagsIds]="child?.tag_ids" class="ml-1 d-flex-none" (click)="$event.stopPropagation()"></app-tag>
                                                                            </span>
                                                                            
                                                                        </h5>
                                                                    </div>
                                                                    <!-- <div class="d-flex mr-4">
                                                                        <img class="edit-icon" src="/assets/Icons/table-card/view.svg" (click)="$event.stopPropagation();"/>
                                                                        <img class="delete-icon" src="/assets/images/comment-delete.svg" (click)="$event.stopPropagation();opentoeDeleteDialog(deletetoeModal,child)" alt="delete">
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div [id]="'toeChild'+toeChildIndex" class="accordion-collapse collapse"
                                                    [ngClass]="{'show': toeChildIndex === selectedtoeChildIndex}"
                                                    [attr.aria-labelledby]="'toeChildMaster'+toeChildIndex"
                                                    [attr.data-bs-parent]="'toeChildMaster'+toeChildIndex">
                                                    <div class="accordion-body py-0">
                                                        <div class="d-flex flex-column border-bottom">
                                                            <div class="pt-1" >
                                                                <form [formGroup]="toeForm">
                                                                    <div class="mb-3 d-flex">
                                                                        <div class="bubble-top bg-1 mr-2"></div>
                                                                        <div class="d-flex">
                                                                            <label class="labelstyle">{{staticText?.toe_master?.sub_title1}}</label>
                                                                        </div>
                                                                        <hr>
                                                                    </div>                    
                                                                    <div class="row col-12 px-0 details-bg-1 mx-0">                    
                                                                        <div class="col-5">
                                                                            <mat-form-field appearance="outline" class="w-100 h-25">
                                                                                <mat-label>{{staticText?.toe_master.field1}}</mat-label>
                                                                                <input matInput type="text"
                                                                                    formControlName="title"
                                                                                    placeholder="{{staticText.toe_master.field1}}">
                                                                            </mat-form-field>
                                                                        </div>
                                                                        
                                                                        <div class="col-7">
                                                                            <mat-form-field appearance="outline" class="w-100" maxlength="255" [ngClass]="{ 'mat-form-field-invalid':toeFormSubmit && toeForm.get('description')?.errors?.required}">
                                                                                <mat-label>{{staticText?.toe_master.field2}}</mat-label>
                                                                                <textarea matInput formControlName="description"
                                                                                    cdkTextareaAutosize placeholder="{{staticText?.toe_master.field2}}"
                                                                                    ></textarea>
                                                                            </mat-form-field>
                                                                        </div> 
                                                                        <div  class="col-4">                                            
                                                                            <mat-form-field appearance="outline" class="w-100" [ngClass]="{ 'mat-form-field-invalid':toeFormSubmit && toeForm.get('function')?.errors?.required}">
                                                                                <mat-label> {{staticText?.toe_master.field3}}</mat-label>
                                                                                <input matInput [matAutocomplete]="auto1" type="text"
                                                                                formControlName="function">
                                                                                <mat-icon matSuffix class="muted">search</mat-icon>
                                                                                <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayFnName">
                                                                                    <mat-option *ngFor="let option of filterFunctionTracker | async"
                                                                                        [value]="option">
                                                                                        {{option.display_name| titlecase}}
                                                                                    </mat-option>
                                                                                </mat-autocomplete>
                                                                                <!-- <mat-select formControlName="function"
                                                                                    placeholder="{{staticText?.toe_master.field3}}">
                                                                                    <mat-option *ngFor="let opt of unitOptions" class="mosauto"
                                                                                        [value]="opt.display_name">{{opt?.display_name}}
                                                                                    </mat-option>
                                                                                </mat-select> -->
                                                                            </mat-form-field>
                                                                        </div>
                                                                        <div class="col-4 pr-0">                                                                                                    
                                                                            <mat-form-field appearance="outline" class="w-100" [ngClass]="{ 'mat-form-field-invalid':toeFormSubmit && toeForm.get('fulfilmentBy')?.errors?.required}">
                                                                                <mat-label> {{staticText?.toe_master.field5}}</mat-label>
                                                                                <mat-select formControlName="fulfilmentBy" placeholder="{{staticText?.toe_master.field5}}">
                                                                                    <mat-option class="mosauto" value="Self">Self</mat-option>                                                                    
                                                                                    <mat-option class="mosauto" value="Vendor">Vendor</mat-option>                                                                    
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                        </div>
                                                                        <div class="col-4 px-5">   
                                                                            <label class="fs-14 mx-1 mt-2 mb-1 w-100" [class.field-invalid]="toeFormSubmit && toeForm.get('isMandatory')?.errors?.required">{{staticText?.toe_master.field4}}</label>
                                                                            <mat-radio-group  class="m-1 mt-0 d-flex align-items-center p-0" formControlName="isMandatory" >
                                                                                <mat-radio-button class="fs-6 fw-500" [value]='true'>Yes
                                                                                </mat-radio-button>
                                                                                <mat-radio-button class="fs-6 pl-3 ml-3 fw-500" [value]='false'>No
                                                                                </mat-radio-button>
                                                                            </mat-radio-group>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-3 d-flex mt-2">
                                                                        <div class="bubble-top bg-2 mr-2"></div>
                                                                        <div class="d-flex">
                                                                            <label class="labelstyle">{{staticText?.toe_master?.sub_title2}}</label>
                                                                        </div>
                                                                        <hr>
                                                                    </div>
                                                                    <div class="row col-12 px-0 details-bg-2 mx-0">                                                        
                                                                        <div class="d-flex col-6">
                                                                            <div class="col-10 p-0">
                                                                                <mat-form-field appearance="outline" class="w-100">
                                                                                    <mat-label>{{staticText?.toe_master.field6}}</mat-label>
                                                                                    <input matInput [matAutocomplete]="automos1" type="text" formControlName="searchMetric" (change)="getMetricDetails($event?.target?.value)">
                                                                                    <mat-icon matSuffix class="muted">search</mat-icon>
                                                                                    <mat-autocomplete #automos1="matAutocomplete" [displayWith]="displayFnName" (optionSelected)="onSelectionChange($event,addMoS)">
                                                                                        <mat-option *ngFor="let option of filterOptionType | async" class="mosauto" [value]="option">
                                                                                            {{option.name}}
                                                                                        </mat-option>
                                                                                    </mat-autocomplete>
                                                                                </mat-form-field>
                                                                            </div>
                                                                            <div class="col-2 p-0 d-flex align-items-center">
                                                                                <span class="material-icons-outlined fs-30 color-green d-flex w-100 justify-content-center cursor-pointer mt-2" (click)="editObject={};openMoSDialog(addMoS,child)">add_circle</span>
                                                                            </div>
                                                                        </div> 
                                                                        <div class="col-12" *ngIf="metricsArray && metricsArray?.length>0">
                                                                            <div class="accordion mt-3 overflow-hidden" id="accordionExample">
                                                                                <div class="accordion-item my-2" *ngFor="let metricITem of metricsArray;let m=index">
                                                                                    <h2 class="accordion-header" id="headingOne{{m}}">
                                                                                        <button class="accordion-button pl-2" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+m" aria-expanded="true">
                                                                                            <div class="col-10 p-0">{{metricITem?.name}}</div>
                                                                                            <div class="col-1 p-0 d-flex justify-content-around align-items-center">
                                                                                                <img class="edit-icon" src="/assets/Icons/table-card/edit.svg" (click)="$event.stopPropagation();onClickEdit(metricITem,addMoS)"/>
                                                                                                <img class="edit-icon" src="/assets/images/comment-delete.svg" (click)="$event.stopPropagation();deleteMetric(m)" alt="delete">
                                                                                            </div>
                                                                                        </button>    
                                                                                    </h2>
                                                                                    <div id="collapseOne{{m}}" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                                                        <div class="accordion-body p-0 pt-3">
                                                                                            <div class="row">
                                                                                                <div class="col-md-3 mb-3">
                                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field1}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.name||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3">
                                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field2}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.description||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3">
                                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field3}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.mos_type_name||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3">
                                                                                                    <p class="fs-14 title-color fw-normal mb-0 mx-2 px-3">{{staticText?.okr_convergence.modal_field4}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.frequency||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field5}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.unit_name||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3" *ngIf=" metricITem?.mos_type_name=='HTB' || metricITem?.mos_type_name=='LTB'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field11}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.mos_type_name=='HTB'?(metricITem?.max_value||''):(metricITem?.min_value||'')}}</p>
                                                                                                </div> 
                                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field6}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.from_value||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field7}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.on_track_value||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field8}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.at_risk_value||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'|| metricITem?.mos_type_name=='LTB'|| metricITem?.mos_type_name=='HTB'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field9}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.off_track_value||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Need Improvement'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field10}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.to_value||''}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3" *ngIf="metricITem?.mos_type_name=='Binary(Adherence)'">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field15}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.binary_value?'Yes':'No'}}</p>
                                                                                                </div>
                                                                                                <div class="col-md-3 mb-3">
                                                                                                    <p class="fs-14 title-color fw-normal mx-2 mb-0 px-3">{{staticText?.okr_convergence.modal_field16}}</p>
                                                                                                    <p class="mx-2 ms-4 fs-16 text-black mb-0">{{metricITem?.remarks||''}}</p>
                                                                                                </div>
                                                                                            </div>                                                                
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-3 d-flex mt-2">
                                                                        <div class="bubble-top bg-3 mr-2"></div>
                                                                        <div class="d-flex">
                                                                            <label class="labelstyle">{{staticText?.toe_master?.field7}}</label>
                                                                        </div>
                                                                        <hr>
                                                                    </div>
                                                                    <div class="row col-12 px-0 details-bg-3 mx-0">
                                                                        <div class="col-12 pr-0">
                                                                            <div class="d-flex">
                                                                                <!-- <div class="col-4 pl-0" *ngFor="let applicability of mastersArray?.applicability">                                                                                                    
                                                                                    <mat-form-field appearance="outline" class="w-100" [class.mat-form-field-invalid]="submitted && toeForm.get(applicability?.name)?.errors?.required">
                                                                                        <mat-label> {{applicability?.display_name}}</mat-label>
                                                                                        <mat-select formControlName="{{applicability?.name}}"
                                                                                            placeholder="{{applicability?.display_name}}">
                                                                                            <mat-option *ngFor="let opt of applicability?.child" class="mosauto"
                                                                                                [value]="opt?.id">{{opt?.display_name}}
                                                                                            </mat-option>
                                                                                        </mat-select>
                                                                                    </mat-form-field>
                                                                                </div> -->
                                                                                <div class="col-4 pl-0" *ngFor="let applicability of mastersArray?.applicability"> 
                                                                                    <mat-form-field class="example-chip-list w-100 h-25" appearance="outline" [class.mat-form-field-invalid]="submitted && toeForm.get(applicability?.name)?.errors?.required">
                                                                                        <mat-chip-list #chipList >
                                                                                            <mat-chip class="d-flex flex-row"
                                                                                                *ngFor="let selectedList of toeForm.get(applicability?.name).value | slice:0:2"
                                                                                                [selectable]="true" [removable]="true" (removed)="removeOption(applicability?.name,selectedList?.name)">
                                                                                                <span class="words-ellipse">{{selectedList?.display_name}}</span>
                                                                                                <button matChipRemove>
                                                                                                    <mat-icon>cancel</mat-icon>
                                                                                                </button>
                                                                                            </mat-chip>
                                                                                            <span class="ownersCountBadge" matTooltip="{{(toeForm.get(applicability?.name).value | slice:2)|joinArrayValues:'display_name'}}"
                                                                                                *ngIf="toeForm.get(applicability?.name).value?.length > 2">
                                                                                                <span>
                                                                                                    &plus;{{(toeForm.get(applicability?.name).value?.length-2) ||
                                                                                                    0}}
                                                                                                </span>
                                                                                            </span>
                                                                                            <input placeholder="{{applicability?.display_name}}" 
                                                                                                [matChipInputFor]="chipList" onkeypress="return false"
                                                                                                [matAutocomplete]="supportOwners1">
                                                                                            <!-- <mat-icon matSuffix class="muted">search</mat-icon> -->
                                                                                        </mat-chip-list>
                                                                                        <mat-autocomplete #supportOwners1="matAutocomplete" (optionSelected)="selected(applicability?.name,$event)" >
                                                                                            <mat-option *ngFor="let opt of applicability?.child" [disabled]="jsonString(toeForm.get(applicability?.name).value)?.includes(opt?.display_name)"
                                                                                                [value]="opt">
                                                                                                {{opt.display_name}}
                                                                                            </mat-option>
                                                                                        </mat-autocomplete>
                                                                                    </mat-form-field>
                                                                                </div>
                                                                            </div>
                                                                        </div>   
                                                                    </div>   
                                                                    <div class="mb-3 d-flex mt-2">
                                                                        <div class="bubble-top bg-4 mr-2"></div>
                                                                        <div class="d-flex">
                                                                            <label class="labelstyle">{{staticText?.toe_master?.field8}}</label>
                                                                        </div>
                                                                        <hr>
                                                                    </div>
                                                                    <div class="row col-12 px-0 details-bg-4 mx-0">                                               
                                                                        <div class="col pr-0">                                                              
                                                                            <div>
                                                                                <div class="d-flex" *ngFor="let consequence of mastersArray?.consequences">
                                                                                    <div class="col-2 d-flex align-items-center"><mat-checkbox formControlName="{{consequence?.name}}">{{consequence?.display_name}}</mat-checkbox></div>
                                                                                    <div class="col-5 pl-0">
                                                                                        <mat-form-field appearance="outline" class="w-100" [ngClass]="{ 'mat-form-field-invalid':submitted && toeForm.get(consequence?.name+'description')?.errors?.required}">
                                                                                            <mat-label>{{staticText?.toe_master.field8_4}}</mat-label>
                                                                                            <textarea matInput formControlName="{{consequence?.name+'_description'}}" maxlength="255" 
                                                                                                cdkTextareaAutosize placeholder="{{staticText?.toe_master.field8_4}}"
                                                                                                ></textarea>
                                                                                        </mat-form-field>
                                                                                    </div>
                                                                                </div>                                                               
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                    
                                                                    <div class="mb-3 d-flex mt-2">
                                                                        <div class="bubble-top bg-5 mr-2"></div>
                                                                        <div class="d-flex">
                                                                            <label class="labelstyle">{{staticText?.toe_master?.field9}}</label>
                                                                        </div>
                                                                        <hr>
                                                                    </div>
                                                                    <div class="row col-12 px-0 details-bg-5 mx-0">
                                                                        <div class="col-12">
                                                                            <section class="example-section col-7 p-0 mb-0">
                                                                                <div class="d-flex justify-content-between px-3">
                                                                                    <p *ngFor="let evidence of mastersArray?.evidence"><mat-checkbox  formControlName="{{evidence?.name}}">{{evidence?.display_name}}</mat-checkbox></p>
                                                                                </div>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div class="mb-3 d-flex mt-2">
                                                                        <div class="bubble-top bg-6 mr-2"></div>
                                                                        <div class="d-flex">
                                                                            <label class="labelstyle">{{staticText?.toe_master?.field10}}</label>
                                                                        </div>
                                                                        <hr>
                                                                    </div>
                                                                    <div class="row col-12 px-0 details-bg-6 mx-0">
                                                                        <div class="col-5 pr-0">
                                                                            <section class="example-section my-0">
                                                                                <div class="d-flex px-3">
                                                                                    <mat-checkbox *ngFor="let compliance of mastersArray?.compliance;let first=first" [ngClass]="{'ml-5':!first}" formControlName="{{compliance?.name}}">{{compliance?.display_name}}</mat-checkbox>
                                                                                </div>
                                                                            </section>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row col-12 p-0" >                                                                        
                                                                        <div class=" mt-3 mb-2 col-12 d-flex justify-content-end align-items-center" >
                                                                            <button  type="button" class="addOwners text-white text-capitalize cursor-pointer"
                                                                                mat-button (click)="createToE(true)">
                                                                                {{staticText.buttons.update_btn}}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </mat-card-content>
                                    <div class="row noData" *ngIf="!noDataShow">
                                        <div class="col-12 mx-auto">
                                            <img src="./assets/images/no-record.png" class="no-records"
                                                height="100%" width="100%">
                                        </div>
                                        <div class="col-8 mx-auto">
                                            <p class="text-center fw-bold text-muted">
                                                {{staticText.common.no_records_found}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer class="toeconvergence-Footer">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-muted">
                        <p align="center">Copyright © {{currentYear}} {{staticText.footer.text}}</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>


<ng-template #addMoS>
    <div class="modal-dialog m-0 field-adjust" #menu2 [formGroup]="metricForm" id="mosForms">
        <div class="modal-content pt-1">
            <div class="modal-body">
                <div class="row">
                    <mat-form-field appearance="outline" class="col-5" [ngClass]="{ 'mat-form-field-invalid':mosSubmitted && metricForm.get('metricName')?.errors?.required}">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field1}}
                        </mat-label>
                        <input type="text" matInput fullWidth status="basic" formControlName="metricName" >
                    </mat-form-field >
                    <mat-form-field appearance="outline" class="col-7 pl-0" [ngClass]="{ 'mat-form-field-invalid':mosSubmitted && metricForm.get('metricDesc')?.errors?.required}">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field2}}
                        </mat-label>
                        <textarea matInput formControlName="metricDesc"
                            cdkTextareaAutosize placeholder="{{staticText?.okr_convergence.modal_field2}}"
                            ></textarea>
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="outline" class="col-4" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('mosType')?.errors?.required">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field3}}
                        </mat-label>
                        <mat-select formControlName="mosType"
                            placeholder="{{staticText?.okr_convergence.modal_field3}}"
                            (selectionChange)="onSelectMosType();">
                            <mat-option *ngFor="let opt of mosTypes" class="mosauto" [value]="opt">
                                {{opt}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-4" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('frequency')?.errors?.required">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field4}}
                        </mat-label>
                        <mat-select formControlName="frequency"
                            placeholder="{{staticText?.okr_convergence.modal_field4}}" >
                            <mat-option *ngFor="let opt of frequencyOptions" class="mosauto" [value]="opt.display_name">
                                {{opt?.display_name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-4" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('unit')?.errors?.required"
                     *ngIf="metricForm.value.mosType == 'Need Improvement' || metricForm.value.mosType == 'LTB'|| metricForm.value.mosType == 'HTB'">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field5}}
                        </mat-label>
                        <mat-select formControlName="unit"
                            placeholder="{{staticText?.okr_convergence.modal_field5}}">
                            <mat-option *ngFor="let opt of unitOptions" class="mosauto"
                                [value]="opt.display_name">{{opt?.display_name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="row">                    
                    <mat-form-field appearance="outline" class="col-3" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('from')?.errors?.required" 
                    *ngIf="metricForm.value.mosType == 'Need Improvement'">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field6}}
                        </mat-label>
                        <input matInput type="number" status="basic" formControlName="from"
                            placeholder="{{staticText?.okr_convergence.modal_field6}}" autocomplete="off">
                    </mat-form-field>
                    
                    <ng-container *ngIf="metricForm.value.mosType == 'Binary(Adherence)'">
                        <div class="col-4">
                            <label class="fs-14 mx-1 mt-2 mb-1" [class.field-invalid]="mosSubmitted && metricForm.get('yesNo')?.errors?.required">{{staticText?.okr_convergence.modal_field11}}</label>
                            <mat-radio-group aria-label="Binary Value" class="m-1 mt-0 d-flex align-items-center p-0 col-3" formControlName="yesNo" >
                                <mat-radio-button class="fs-6 fw-500" value='Yes'>Yes
                                </mat-radio-button>
                                <mat-radio-button class="fs-6 pl-3 fw-500" value='No'>No
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="metricForm.value.mosType == 'LTB' ||metricForm.value.mosType == 'HTB'">
                        <mat-form-field appearance="outline" class="col-3" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get(metricForm.value.mosType == 'LTB'?'minValue':'maxValue')?.errors?.required" >
                            <mat-label>
                                {{staticText?.okr_convergence.modal_field11}}
                            </mat-label>
                            <input matInput type="number" status="basic" [formControlName]="metricForm.value.mosType == 'LTB'?'minValue':'maxValue'"
                                placeholder="{{staticText?.okr_convergence.modal_field11}}" autocomplete="off">
                        </mat-form-field> 
                    </ng-container>
                    <ng-container *ngIf="metricForm.value.mosType == 'Need Improvement' || metricForm.value.mosType == 'LTB'|| metricForm.value.mosType == 'HTB'">
                    <mat-form-field appearance="outline" class="col-3"  [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('onTrack')?.errors?.required">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field7}}
                        </mat-label>
                        <input matInput type="text" formControlName="onTrack" ondrop="return false;" onpaste="return false;" onKeyPress="return (event.charCode === 0 || /[0-9-.]/.test(String.fromCharCode(event.charCode)) )" status="basic" 
                         placeholder="{{staticText?.okr_convergence.modal_field7}}" autocomplete="off" >
                    </mat-form-field>
                </ng-container>
                    <mat-form-field appearance="outline" class="col-3" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('atRisk')?.errors?.required" *ngIf="metricForm.value.mosType == 'Need Improvement' || metricForm.value.mosType == 'LTB'|| metricForm.value.mosType == 'HTB'">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field8}}
                        </mat-label>
                        <input matInput type="text" status="basic" formControlName="atRisk" ondrop="return false;" onpaste="return false;" onKeyPress="return (event.charCode === 0 || /[0-9-.]/.test(String.fromCharCode(event.charCode)) )"
                            placeholder="{{staticText?.okr_convergence.modal_field8}}" autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col-3" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('offTrack')?.errors?.required" *ngIf="metricForm.value.mosType == 'Need Improvement' || metricForm.value.mosType == 'LTB'|| metricForm.value.mosType == 'HTB'">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field9}}
                        </mat-label>
                        <input matInput type="text" status="basic" formControlName="offTrack" ondrop="return false;" onpaste="return false;" onKeyPress="return (event.charCode === 0 || /[0-9-.]/.test(String.fromCharCode(event.charCode)) )"
                            placeholder="{{staticText?.okr_convergence.modal_field9}}" autocomplete="off">
                    </mat-form-field>   
                    <mat-form-field appearance="outline" class="col-3" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('to')?.errors?.required" *ngIf="metricForm.value.mosType == 'Need Improvement'">
                        <mat-label>
                            {{staticText?.okr_convergence.modal_field10}}
                        </mat-label>
                        <input matInput type="number" status="basic" formControlName="to"
                            placeholder="{{staticText?.okr_convergence.modal_field10}}" autocomplete="off">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="col" [class.mat-form-field-invalid]="mosSubmitted && metricForm.get('remarks')?.errors?.required">
                        <mat-label for="exampleFormControl">
                            {{staticText?.okr_convergence.modal_field16}}
                        </mat-label>
                        <textarea cdkTextareaAutosize matInput formControlName="remarks" class=" fw-500" required></textarea>
                    </mat-form-field>
                    <div class="col d-flex align-items-end justify-content-end mt-3">
                        <div class="mr-2">
                            <button mat-button mat-dialog-close class="text-blue" (click)="closeDialog()">
                                {{staticText?.buttons?.cancel_btn}}</button>
                        </div>
                        <div >
                            <button mat-button class="text-white bg-red" (click)="addMetric()"
                                >{{staticText?.buttons?.save_btn}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deletetoeModal>
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>

            <div class="modal-body">
                <p class=" fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-blue" (click)="closeDialog()">
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-white bg-red"
                            (click)="deletetoeChild()">{{staticText?.buttons?.delete_btn}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deletetoeForm>
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
            
            <div class="modal-body">
                <p class=" fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-blue" (click)="closeDialog()">
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-white bg-red"
                            (click)="deleteFrequencyGoal()">{{staticText?.buttons?.delete_btn}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #deleteMeasure>
    <div class="modal-dialog m-0">
        <div class="modal-content">
            <app-modal-header-common [title]="staticText?.modal_popup?.confirmation_title"> </app-modal-header-common>
            
            <div class="modal-body">
                <p class=" fs-18 py-3 text-center primary m-0 fw-bold">{{staticText?.modal_popup?.del_msg}}</p>
                <p>{{staticText?.modal_popup?.cant_retrieve}}</p>
            </div>
            <div class="modal-footer border-0">
                <div class="row">
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-blue" (click)="closeDialog()">
                            {{staticText?.buttons?.cancel_btn}}</button>
                    </div>
                    <div class="col-md-6">
                        <button mat-button mat-dialog-close class="text-white bg-red" (click)="deleteMeasureofSuccess()"
                            *ngxPermissionsOnly="['delivery.delete_riskmeasureofsuccess']">{{staticText?.buttons?.delete_btn}}</button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
