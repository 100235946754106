<div class="nested-accordion">
  <div *ngFor="let item of items; let indx = index" class="nested-accordion-loop">
    <div class="accordion-item" *ngIf="(item.level != 'goal' && item.level != 'term' && item.level != 'risk') && ((context?.componentName?.name=='PreviewComponent' && item.selected) || !context?.componentName)">
      <div class="accordion-header" (click)="toggleAccordion(item, indx) && $event.stopPropagation()" [ngStyle]="{'backgroundColor': item.expanded ? getColor(item) : ''}">
        <div class="title-hol" [ngStyle]="{'flex' : fromPage == 'toe' ? 'auto' : '', 'align-items' : fromPage == 'toe' ? 'center' : ''}">
          <input #checkbox type="checkbox" [checked]="item.selected" [disabled]="disabled" (change)="item.selected = !item.selected; selectGoals(item);" (click)="$event.stopPropagation()">
          <span class="log-number">{{indx + 1}}</span>
          <input type="text" class="form-control" [(ngModel)]="item.name" *ngIf="fromPage == 'toe' && item.id == ''">
          <div class="title" *ngIf="fromPage == 'toe' && item.id != ''"> {{ item.name }}</div>
          <div class="title" *ngIf="fromPage != 'toe'"> {{ item.name }}</div>
          <div *ngIf="fromPage == 'toe' && (item.level == 'category' || item.level == 'subcategory') && !user?.is_vendor">
            <mat-icon aria-hidden="false" aria-label="material-icons-filled" (click)="add(item) && $event.stopPropagation()" *ngIf="item.id != ''"
              class="cursor-pointer fs-5 px-3">add
            </mat-icon>
            <mat-icon aria-hidden="false" aria-label="material-icons-filled" (click)="save(item) && $event.stopPropagation()" *ngIf="item.id == ''"
                  class="material-icons-outlined cursor-pointer fs-5 px-3">save
            </mat-icon>
          </div>
        </div>

        <div class="formParentArrow p-2 d-flex align-items-center">
          <span class="material-icons-outlined arrow-icon" *ngIf="!item.expanded">
              keyboard_arrow_down
          </span>
          <span class="material-icons-outlined arrow-icon" *ngIf="item.expanded">
              keyboard_arrow_up
          </span>
      </div>
      </div>
      <div class="accordion-content" [hidden]="!item.expanded">
        <app-loading *ngIf="loading"></app-loading>
        <app-selection-tree-listing [tree]="tree" *ngIf="item.children" [disabled]="disabled" [(items)]="item.children" [context]="context" [fromPage]="fromPage"></app-selection-tree-listing>
      </div>
    </div>
    <div *ngIf="(item.level == 'goal' || item.level == 'term' || item.level == 'risk') && ((context?.componentName?.name=='PreviewComponent' && item.selected) || !context?.componentName)" class="hov">
      <div [ngStyle]="{'backgroundColor': getColor(item)}" class="last-level" (click)="item.selected = disabled ? item.selected : !item.selected; selectGoals(item);" [ngStyle]="{'display': fromPage == 'toe' ? 'block' : 'inherit'}">
        <div style="display: flex; align-items: flex-start;">
          <input #checkboxes type="checkbox" [checked]="item.selected" [disabled]="disabled" (change)="item.selected = !item.selected; selectGoals(item);" (click)="$event.stopPropagation()">
          <div class="leveltext" *ngIf="fromPage != 'toe'">{{item?.goal_name || item?.name}} </div>
          <div class="leveltext" *ngIf="fromPage == 'toe' && item.id != ''">{{item?.goal_name || item?.name}} </div>
          <input type="text"  class="form-control" [(ngModel)]="item.name" *ngIf="fromPage == 'toe' && item.id == ''">
          <div *ngIf="fromPage == 'toe'">
            <mat-icon aria-hidden="false" aria-label="material-icons-filled" (click)="saveTerm(item) && $event.stopPropagation()" *ngIf="item.id == ''"
                  class="material-icons-outlined cursor-pointer fs-5 px-3">save
              </mat-icon>
          </div>

           <mat-icon *ngIf="item?.is_recomended" matTooltip="Recommended"
           matTooltipClass="example-tooltip" style="margin-top:-8px;color:rgb(255, 184, 5)">star</mat-icon>
        </div>
        <div *ngIf="fromPage == 'toe' && item.selected" class="comment-input-sec">
          <!-- <label>Context</label> -->
          <input type="text" class="form-control" [value]="item.comments" (click)="$event.stopPropagation()" (keyup)="emitCommentValue($event,item)" placeholder="Context" >
        </div>
      </div>
    </div>
  </div>

</div>