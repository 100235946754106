<div class="iholder" [matMenuTriggerFor]="menu" [ngClass]="{ 'disabled' : !editable }" *ngIf="context == ''">
    <span *ngIf="selectedOwners.length > 2">
        <span class="span-circle span-1"></span>
        <span class="span-circle span-2"></span>
        <span class="span-circle span-3 span-text">{{getIconNameShort(selectedOwners[selectedOwners.length -
            1])}}</span>
    </span>
    <span *ngIf="selectedOwners.length == 2">
        <span class="span-circle span-1"></span>
        <span class="span-circle span-2 span-text">{{getIconNameShort(selectedOwners[selectedOwners.length -
            1])}}</span>
    </span>
    <span *ngIf="selectedOwners.length == 1">
        <span class="span-circle span-1 span-text">{{getIconNameShort(selectedOwners[selectedOwners.length -
            1])}}</span>
    </span>
    <span *ngIf="selectedOwners.length == 0" class="no-user">
        <button mat-icon-button class="user-icon-button">
            <mat-icon>perm_identity</mat-icon>
        </button>
    </span>
</div>
<div *ngIf="context != ''">
    <mat-chip-list class="example-chip">
        <mat-chip class="example-box" [matMenuTriggerFor]="menu" *ngFor="let data of selectedOwners">
           {{data.first_name}} {{data.last_name}}
        </mat-chip>
        <mat-chip class="example-box" [matMenuTriggerFor]="menu" *ngIf="selectedOwners.length == 0">
           Select
         </mat-chip>
    </mat-chip-list>
</div>
<mat-menu #menu="matMenu" class="my-menu">
    <div (click)="$event.stopPropagation();" *ngIf="owners">
        <form class="example-form">
            <mat-form-field class="example-full-width" floatLabel="always" style="width: 100%;">
                <input type="text" placeholder="Search users" matInput [formControl]="myControl"
                    [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)='addOwner($event.option.value)'>
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                        <b>{{option.first_name}} {{option.last_name}}</b> <br>
                        {{option.email}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </form>

        <div class="selected-holder">
            <div *ngFor="let sel of selectedOwners; let i = index" class="selected-item">
                <div>
                    <span style="font-weight: 700;">{{sel.first_name}} {{sel.last_name}}</span> <br>
                    <span style="font-size: 10px;">{{sel.email}}</span>
                </div>
                <button mat-icon-button color="warn" (click)="removeUser(i)" class="small-icon-button">
                    <mat-icon>clear</mat-icon>
                </button>
            </div>
        </div>
    </div>
</mat-menu>