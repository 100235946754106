<section id="filterAutocomplete">
    <div class="d-flex flex-column filter-style field-adjust">
        <div class="d-flex  align-items-center">
            <div class="col-md-11 pr-2 pl-0" [ngClass]="((screen_name=='proposal-screen') && !portfoliodata)?'col-md-12':'col-md-11'">
                <app-carousel [customcarousel]="tabcontentdynamic" (selectedPortfolio)="selectedPortfolioValues($event)"
                    [swiperview]="4" [stylecss]="clearcarousel" [swiperspace]="carouselSpace">
                </app-carousel>
            </div>

            <div class="col-md-1 p-0" [hidden]="( screen_name=='proposal-screen') && !portfoliodata" >
                <button mat-button class="float-end border primary lightSecondary download" (click)="autocomplete()">
                    <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="filter">filter_list
                    </mat-icon>Filter
                </button>
            </div>
        </div>

        <div class="row row-cols-5 mt-2" [formGroup]="parentForm">

            <div class="tabAutocomplete col p-0" *ngIf="show && portfoliodata">
                <mat-form-field class="selectbox ml-2 mr-1" appearance="outline" >
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Subportfolio</mat-label>
                    <input matInput [matAutocomplete]="auto" type="text" formControlName="subportfolio1">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="subPortfolio($event)">
                        <mat-option *ngFor="let option of subportfolio"
                            [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            
            <ng-container *ngFor="let child of subPortFolioChild" >
                <div class="mb-2" >
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>{{child.name}}</mat-label>
                        <input matInput [matAutocomplete]="auto3" type="text" formControlName="{{child.key}}">
                        <mat-icon matSuffix class="muted">search</mat-icon>
                        <mat-autocomplete #auto3="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of child.arrList" [value]="option"
                                (onSelectionChange)="onSelectionChangeSubportPortfolioChild($event)">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                        <mat-error *ngIf="(submitted|| f[child.key].touched) && f[child.key].errors">
                            <small class="text-danger" *ngIf="f[child.key].errors.required">{{child.name}}
                                is required</small>
                            <small class="text-danger" *ngIf="f[child.key].errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>

            <div class="tabAutocomplete col p-0" *ngIf="showProjectFirst">
                <mat-form-field class="selectbox ml-2 mr-1" appearance="outline"
                    *ngIf="show && projectList?.length > 0">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Project</mat-label>
                    <input matInput [matAutocomplete]="auto1" type="text" [formControl]="searchProjectCtrl" formControlName="project" >
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto1="matAutocomplete" [displayWith]="displayProjectFn" (optionSelected)="changeProject($event)">
                        <mat-option *ngFor="let option of filterProjectList "
                             [value]="option">
                            {{option.project_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="tabAutocomplete col p-0" *ngIf="show && actionCategory?.length > 0">
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Action Category</mat-label>
                    <input matInput [matAutocomplete]="auto" type="text" [formControl]="searchActionCategory" formControlName="action_category">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="changeActionCategory($event)">
                        <mat-option *ngFor="let option of filterActionCategory | async"
                             [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="tabAutocomplete col p-0" *ngIf="show && function?.length > 0">
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Function</mat-label>
                    <input matInput [matAutocomplete]="auto" type="text" formControlName="function">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="changeFunction($event)">
                        <mat-option *ngFor="let option of function" 
                            [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="tabAutocomplete col p-0" *ngIf="show && initiative?.length > 0">
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Initiative Type</mat-label>
                    <input matInput [matAutocomplete]="auto" type="text" [formControl]="searchInitiativeTypeCtrl" formControlName="initiative_type">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"  (optionSelected)="changeInitiative($event)">
                        <mat-option *ngFor="let option of filterInitiativeTypeList | async"
                             [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="tabAutocomplete col p-0" *ngIf="show && benefits?.length > 0">
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Benefits</mat-label>
                    <input matInput [matAutocomplete]="auto" type="text" formControlName="benefits">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="changeBenefits($event)">
                        <mat-option *ngFor="let option of benefits" 
                            [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="tabAutocomplete col p-0" *ngIf="!showProjectFirst && show && projectList?.length > 0">
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Project</mat-label>
                    <input matInput [matAutocomplete]="auto" type="text" [formControl]="searchProjectCtrl" formControlName="project" (keyup)="searchProjectName($event)">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProjectFn" (optionSelected)="changeProject($event)">
                        <mat-option *ngFor="let option of filterProjectList | async"
                             [value]="option">
                            {{option.project_name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="tabAutocomplete col p-0" *ngIf="show && showOpportunity">
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Opportunity</mat-label>
                    <input matInput  type="text" [formControl]="searchOppCtrl" formControlName="opportunity" (change)="changeOpportunity($event)">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProjectFn" >
                        <mat-option *ngFor="let option of filterOppList | async"
                             [value]="option">
                            {{option.project_name}}
                        </mat-option>
                    </mat-autocomplete> -->
                </mat-form-field>
            </div>
          
            <div class="tabAutocomplete col p-0" *ngIf="show && showProjectFilters && selectedSubPortFolio" >
                <mat-form-field class="selectbox ml-2 mr-1" appearance="outline" >
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Program Name</mat-label>
                    <input matInput [matAutocomplete]="autop" type="text" formControlName="programName">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #autop="matAutocomplete" [displayWith]="displayNameFn" (optionSelected)="onProgramSelect($event)">
                        <mat-option *ngFor="let option of programList"
                            [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
               
            </div>
            <div class="tabAutocomplete col p-0" *ngIf="show && showProjectFilters && this.selectedProgram" >
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Project Id</mat-label>
                    <input matInput  type="text"  formControlName="projectId"  (keyup)="searchProjectId($event)">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <!-- <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProjectFn" >
                        <mat-option *ngFor="let option of filterOppList | async"
                             [value]="option">
                            {{option.project_name}}
                        </mat-option>
                    </mat-autocomplete> -->
                </mat-form-field>
            </div>

         
           
          
            <div class="tabAutocomplete col p-0 selectstatus"
                *ngIf="status?.length > 0 &&  screen_name == 'proposal-screen'">
                <mat-form-field class="selectbox ml-3" appearance="outline" *ngIf="show">
                    <mat-label>Status</mat-label>
                    <mat-select (selectionChange)="statusChange($event)" formControlName="status">
                        <mat-option *ngFor='let i of status' [value]="i">
                            <span>{{i.action_complete_label}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <!-- <div class="tabAutocomplete col p-0 selectstatus" *ngIf="screen_name == 'proposal-screen'">
                <mat-form-field class="selectbox ml-3" appearance="outline" *ngIf="show">
                    <mat-label>Status</mat-label>
                    <mat-select (selectionChange)="statusOnChange($event)"  formControlName="status">
                        <mat-option *ngFor='let i of status_values' [value]="i">
                            <span>{{i.display_name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->
            <div class="tabAutocomplete col p-0" *ngIf="show && GDPList?.length > 0">
                <mat-form-field class="selectbox ml-2" appearance="outline">
                    <img class="img-fluid" matPrefix src="/assets/images/balancescore/balanceheader/filter.svg"
                        alt="filter">
                    <mat-label>Select OU/GDP</mat-label>
                    <input matInput [matAutocomplete]="auto" type="text" [formControl]="searchGDPCtrl" formControlName="gdp">
                    <img class="img-fluid" matSuffix src="/assets/images/balancescore/balanceheader/search.svg"
                        alt="filter">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayNameFn"  (optionSelected)="changeGDP($event)">
                        <mat-option *ngFor="let option of filterGDPOptions | async"
                             [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-auto" *ngIf="show">
                <button mat-button class="float-end border primary lightSecondary clear-filter" (click)="clearFilter()"
                    mat-flat-button>
                    <mat-icon aria-hidden="false" aria-label="material-icons-filled " class="filter">clear
                    </mat-icon>Clear Filter
                </button>
            </div>
        </div>
    </div>
</section>