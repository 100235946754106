<div class="mb-4" *ngIf="screen_name != 'Innovation' && screen_name != 'actionTracker' && screen_name != 'Survey'   ">
  <div class="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
    <div class="col mt-4" *ngFor="let items of proposalLists; index as i">
      <div class="card card-wrapper " *ngIf="screen_name == 'Proposal' ">
        <div class="top-border" [ngClass]="{
          'red-border': items.status?.label == 'Draft'|| items.status?.label == 'Cancelled' , 
          'hold-border': ( items.status?.label === 'Create' || items.status?.label ==='Modified') , 
          'empty': items?.status?.label == '', 
          'initiated-border':items.status?.label =='Published',
          'partially-closed-border':items.status?.label =='Evaluating', 
          'green-border' : items.status?.label == 'SoW Signed' 
         }"></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProposal(items.id,items.status?.label)">{{items.opportunity_number}}</h5>
          </div>
          <div class="col">
            <img src="./assets/Icons/table-card/view.svg" (click)="viewProposal(items.id,items.status?.label)">
            <img class="edit-icon" src="./assets/Icons/table-card/edit.svg" (click)="viewProposal(items.id,items.status?.label)">
          </div>
        </div>
        <div class="col-12 sub-heading">{{items.portfolio_details?.[1]?.name || 'N/A'}}</div>
        <div class="inside-card">
          <div class="col-12 status-text">
            <p class="status-wrap" [ngClass]="{ 'red': items.status?.label == 'Draft'|| items.status?.label == 'Cancelled' , 'empty': items.status?.label == '' ,
            'initiated': items.status?.label == 'Published' ,  'partially-closed': items.status?.label == 'Evaluating' ,  
            'hold': items.status?.label === 'Create'|| items.status?.label === 'Modified' , 
            'green' : items.status?.label == 'SoW Signed'}">
              {{items.status}}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12">
              <img src="./assets/Icons/table-card/portfolio.svg">
              <span>Channels</span>
            </div>
            <div class="col-12">
              <img src="./assets/Icons/table-card/reservations.svg">
              <span>Reservations</span>
            </div>
            <div class="col-12" *ngIf="items.start_date">
              <img class="mr-2 date-icons" src="./assets/Icons/table-card/start_date.svg">
              <span>{{items.start_date | date:"dd-MM-yyyy  HH:mm"}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-wrapper project-wrapper" *ngIf="screen_name == 'projects' ">
        <div class="top-border" [ngClass]="{'empty': items?.status_details?.label == '', 
          'initiated-border':items.status_details?.label =='Initiated',
          'partially-closed-border' : items.status_details?.label == 'Delivery',
          'green-border' : items.status_details?.label == 'Delivered', 
          'hold-border' : items.status_details?.label == 'Suspended', 
          'red-border' : items.status_details?.label == 'Terminated'
         }"></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProject(items.id)">{{items.project_id}} sdfsfsdfsdf</h5>
          </div>
          <div class="col pr-2">
            <img src="./assets/Icons/table-card/view.svg" (click)="projectViewPopup(items.id , projectsview)">
            <!--  <img class="edit-icon" src="./assets/Icons/table-card/edit.svg" (click)="viewProject(items.id)"> -->
          </div>
        </div>
        <div class="col-12 sub-heading">{{items?.project_name}}</div>
        <div class="inside-card">
          <div class="col-12 status-text p-0">
            <p class="status-wrap" [ngClass]="{ 'initiated' : items.status_details?.label == 'Initiated',
            'partially-closed' : items.status_details?.label == 'Delivery','hold' : items.status_details?.label == 'Suspended',
            'red' : items.status_details?.label == 'Terminated','green' : items.status_details?.label == 'Delivered'}">
              {{items?.status_details?.label}}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12 ">
              <div class="d-flex mt-4">
                <div>
                  <img src="./assets/images/delivery-projects.svg" class="mr-3 project-name">
                </div>
                <div>
                  <span matTooltip="Portfolio Name" matTooltipClass="example-tooltip">{{items?.portfolio || 'N/A'}}</span>
                </div>
              </div>
            </div>
            <div class="col-12 pb-3 d-flex mt-2" >
              <div class="d-flex flex-column" *ngIf="items?.begin_date || items?.expected_end_date;else noData">
                <div class="d-flex">
                  <img class="mr-2 date-icons" src="./assets/Icons/table-card/start_date.svg">
                  <div *ngIf="items?.begin_date" matTooltip="Project Begin Date">
                    <span class="field-text px-2">{{items?.begin_date | date:"dd-MM-yyyy" }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <img class="mr-2 date-icons" src="./assets/Icons/table-card/end_date.svg">
                  <div *ngIf="items?.expected_end_date" matTooltip="Project End Date">
                    <span class="field-text px-2">{{items?.expected_end_date | date:"dd-MM-yyyy"}}</span>
                  </div>
                </div>
              </div>
              <ng-template #noData>
                <div class="d-flex">
                  <img class="mr-2" src="./assets/Icons/table-card/calendar.svg">
                  <span class="px-2">N/A</span>
                </div></ng-template>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-wrapper" *ngIf="screen_name == 'toe' ">
        <div class="top-border" [ngClass]="{
          'red-border': items.status?.label == 'Draft' || items.status?.label == 'Cancelled' , 
          'hold-border': ( items.status?.label === 'Create' || items.status?.label ==='Modified') , 
          'empty': items?.status?.label == '', 
          'initiated-border':items.status?.label =='Published',
          'partially-closed-border':items.status?.label =='Evaluating', 
          'green-border' : items.status?.label == 'SoW Signed' 
         }"></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="editToe(items.id,items.status?.label)">{{items.opportunity_number}}</h5>
          </div>
          <div class="col" hidden>
            <img src="./assets/Icons/table-card/view.svg" (click)="viewToe(items.id , view)">
          </div>
        </div>

        <div class="col-12 sub-heading" matTooltip="Opportunity name">{{items.opportunity_name}}</div>
        <div class="inside-card">
          <div class="col-12 status-text p-0">
            <!--  <mat-select class="dropdown-toe-status" (selectionChange)="selectToeStatus($event , items)"
              placeholder="Select" [(ngModel)]="items.status.slug"
              [ngClass]="{'approved': items.status.slug == 'toe_open' , 'progress': items.status.slug == 'toe_inprogress' ,
                     'rejected': items.status.slug == 'toe_contract_signed'}" class="dropdown-toe-status ">
              <mat-option *ngFor='let i of toeStatus' [value]="i.slug">
                <span >{{i.action_complete_label | titlecase}}</span>
              </mat-option>
            </mat-select>
 -->

            <!-- <mat-select (selectionChange)="selectToeStatus($event , items)" placeholder="Select"
              [(ngModel)]="items.status.slug" [ngClass]="{'approved': items.status.slug == 'toe_open' , 'progress': items.status.slug == 'toe_inprogress' ,
                     'rejected': items.status.slug == 'toe_contract_signed'}" class="dropdown-toe-status ">
              <mat-option *ngFor='let i of toeStatus' [value]="i.slug">
                <span>{{i.action_complete_label | titlecase}}</span>
              </mat-option>
            </mat-select> -->
            <p class="status-wrap" [ngClass]="{ 'red': items.status?.label == 'Draft' || items.status?.label == 'Cancelled' , 'empty': items.status?.label == '' ,
              'initiated': items.status?.label == 'Published' ,  'partially-closed': items.status?.label == 'Evaluating' ,  
              'hold': items.status?.label === 'Create'|| items.status?.label === 'Modified' , 
              'green' : items.status?.label == 'SoW Signed'}">
              {{items.status?.action_complete_label}}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12">
              <div class="d-flex mt-4">
                <div>
                  <img class="mr-3" src="./assets/Icons/table-card/portfolio.svg">
                </div>
                <div>
                  <span matTooltip="Portfolio"
                    matTooltipClass="example-tooltip">{{items?.portfolio_details?.[0]?.display_name||'N/A'}}</span>
                </div>
              </div>
            </div>
            
            <div class="col-12 mb-1">
              <div class="d-flex">
                <div>
                  <img class="mr-3" src="./assets/Icons/table-card/reservations.svg">
                </div>
                <div>
                  <span matTooltip="Sub Portfolio"
                    matTooltipClass="example-tooltip">{{items?.portfolio_details?.[1]?.display_name || 'N/A'}}</span>
                </div>
              </div>
            </div>
            <div class="col-12 pb-3 d-flex mt-2" >
              <div class="d-flex flex-column" *ngIf="items?.initiation_date || items?.last_updated_on;else noData">
                <div class="d-flex">
                  <img class="mr-2 date-icons" src="./assets/Icons/table-card/start_date.svg">
                  <div *ngIf="items?.initiation_date" matTooltip="Initiation Date">
                    <span class="field-text px-2">{{items?.initiation_date | date:"dd-MM-yyyy" }}</span>
                  </div>
                </div>
                <div class="d-flex">
                  <img class="mr-2 date-icons" src="./assets/Icons/table-card/calendar.svg">
                  <div *ngIf="items?.last_updated_on" matTooltip="Last updated On">
                    <span class="field-text px-2">{{items?.last_updated_on | date:"dd-MM-yyyy"}}</span>
                  </div>
                </div>
              </div>
              <ng-template #noData>
                <div class="d-flex" matTooltip="Initiation Date">
                  <img class="mr-2 date-icons" src="./assets/Icons/table-card/start_date.svg">
                  <span class="px-2">N/A</span>
                </div>
              </ng-template>
            </div>
            <!-- <div class="col-12 p-0  mat-datepicker datepicker" matTooltip="Last Modified Date"
              matTooltipClass="example-tooltip">
              <div class="d-flex date-picker-mat">
                <mat-datepicker-toggle matPrefix [for]="picker">
                </mat-datepicker-toggle>
                <mat-datepicker #picker>
                </mat-datepicker>
                <input matInput [matDatepicker]="picker" readonly status="basic" class="py-0" placeholder="Select"
                  (dateChange)="onDateChange($event, items)" (click)="picker.open()" [(ngModel)]="items.initiation_date"
                  class="text-grey">
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="card card-wrapper" *ngIf="screen_name == 'proposal-screen' ">
        <div class="top-border" [ngClass]="{ 'initiated-border':items.status =='Published'}"></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProposal(items.id,items.status?.label)">{{items.opportunity_number}}</h5>
          </div>
          <div class="col">
            <img src="./assets/Icons/table-card/view.svg" (click)="openProposal(items.id, viewProposalPopup)">
          </div>
        </div>
        <div class="col-12 sub-heading">{{items?.opportunity_name}}</div>
        <div class="opportunity-inside-card">
          <div class="col-12 status-text p-0">
            <p class="status-wrap" [ngClass]="{ 'initiated':items.status =='Published'}">
              {{items.status}}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12 ">
              <div class="d-flex mt-4">
                <div>
                  <img class="mr-3" src="./assets/Icons/table-card/portfolio.svg">
                </div>
                <div>
                  <span matTooltip="Portfolio"
                    matTooltipClass="example-tooltip">{{items?.portfolio_details?.[0]?.display_name}}</span>
                </div>
              </div>
            </div>
            <div class="col-12 mb-1">
              <div class="d-flex">
                <div>
                  <img class="mr-3" src="./assets/Icons/table-card/reservations.svg">
                </div>
                <div>
                  <span matTooltip="Sub Portfolio"
                    matTooltipClass="example-tooltip">{{items?.portfolio_details?.[1]?.display_name || 'N/A'}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-1" *ngIf="items.received_date">
              <img class="date-icons" src="./assets/Icons/table-card/end_date.svg">
              <span class="pl-3" matTooltip="Received On"
                matTooltipClass="example-tooltip">{{items.received_date}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card card-wrapper h-100" *ngIf="screen_name == 'opportunity' ">
        <div class="top-border" [ngClass]="{
          'red-border': items.status?.label == 'Draft' || items.status?.label == 'Cancelled', 
          'hold-border': ( items.status?.label === 'Create' || items.status?.label ==='Modified') , 
          'empty': items?.status?.label == '', 
          'initiated-border':items.status?.label =='Published',
          'partially-closed-border':items.status?.label =='Evaluating', 
          'green-border' : items.status?.label == 'SoW Signed' 
         }"></div>
        <div class="row header-wrapper">
          <div class="col">
            <h5 (click)="viewProgram(items.id,items?.status?.label)">{{items?.opportunity_number}}</h5>
          </div>
          <div class="col" hidden>
            <img src="./assets/Icons/table-card/view.svg"
              (click)="openOpportunity(items.id, viewOpportunity)">
          </div>
        </div>
        <div class="col-12 sub-heading">{{items.opportunity_name}}</div>
        <div class="opportunity-inside-card cursor-pointer">
          <div class="col-12 status-text p-0">
            <p class="status-wrap" [ngClass]="{ 'red': items.status?.label == 'Draft'||items.status?.label == 'Cancelled' , 'empty': items.status?.label == '' ,
              'initiated': items.status?.label == 'Published' ,  'partially-closed': items.status?.label == 'Evaluating' ,  
              'hold': items.status?.label === 'Create'|| items.status?.label === 'Modified' , 
              'green' : items.status?.label == 'SoW Signed'}">
              {{items.status?.action_complete_label}}
            </p>
          </div>
          <div class="last-wrapper">
            <div class="col-12 ">
              <div class="d-flex mt-4">
                <div>
                  <img class="mr-3" src="./assets/Icons/table-card/portfolio.svg">
                </div>
                <div>
                  <span matTooltip="Portfolio"
                    matTooltipClass="example-tooltip">{{items?.portfolio_details?.[0]?.display_name}}</span>
                </div>
              </div>
            </div>
            <div class="col-12 mb-1">
              <div class="d-flex">
                <div>
                  <img class="mr-3" src="./assets/Icons/table-card/reservations.svg">
                </div>
                <div>
                  <span matTooltip=" SubPortfolio"
                    matTooltipClass="example-tooltip">{{items?.portfolio_details?.[1]?.display_name || 'N/A'}}</span>
                </div>
              </div>
            </div>
            <div class="col-md-12 mb-1 pl-3 pr-1 " *ngIf="items.created_on">
              <img class="date-icons" src="./assets/Icons/table-card/start_date.svg">
              <span class="pl-3" matTooltip="Created On" matTooltipClass="example-tooltip">{{items.created_on | date:
                'dd-MM-yyyy'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>  
  <div class="row" *ngIf="proposalLists?.length == 0">
    <div class="col-md-12 mx-auto">
      <img src="./assets/images/no-record.png" class="no-records" height="100%" width="100%">
    </div>
    <div class="col-md-8 mx-auto">
      <p class="text-center fw-bold text-muted">
        {{staticText.common.no_records_found}}
      </p>
    </div>
  </div>
</div>

<div *ngIf="screen_name == 'actionTracker'">
  <div class="row row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-7">
    <div class="col " *ngFor="let items of proposalLists; index as i">
      <div class="card card-wrapper mb-4" (click)="actionTracker(items?.id)">
        <div class="top-border" [ngClass]="{
          'red-border': items.status?.label == 'Closed' , 
          'contract-signed': items.status?.label == 'Contract Signed' , 
          'empty': items.status?.label === '',
          'green-border': items.status?.label === 'Open' , 
          'inprogress' : items.status?.label == 'Inprogress' , 'hold-border': items.status?.label == 'Hold'
         }"></div>
        <div class="d-flex justify-content-between align-items-center py-3 cursor-pointer">
          <div class="">
            <h5 class="ticket">{{items.unique_ticket_id}}</h5>
          </div>
          <div class="status-text mx-2">
            <div class="col-12 status-text p-0">
              <p class="status-style px-2" [ngClass]="{ 'red': items.status?.label == 'Closed' , 'empty': items.status?.label == '' ,
              'initiated': items.status?.label == 'Contract Signed' ,  'hold' : items.status?.label == 'Hold'  ,
              'created': items.status?.label === 'Open' , 
              'partially-closed' : items.status?.label == 'Inprogress'}">
                {{items.status?.label}}
              </p>
            </div>
          </div>
        </div>
        <div class="col-12">
          <p class=" sub-heading m-0"><span triggers="mouseenter:mouseleave" container="body" placement="bottom || auto"
              [ngbPopover]="items?.title" popoverClass="darkTheme">{{items?.title}}</span></p>
        </div>
        <div class="card-details">
          <div class="last-wrapper">
            <div>

              <div class="mt-2 d-flex align-items-center col">
                <div class="mr-1 mt-4">
                  <span class="name-1 cursor-pointer" [ngStyle]="{'background': getRandomcolors(i)}">
                    {{getName(items?.assignee?.first_name)}} {{getName(items?.assignee?.last_name)}}
                  </span>
                </div>
                <div>
                  <span class="assignee">Assignee</span>
                  <div class="ml-1">
                    <p class="text-dark fw-medium  text-truncate card-title cursor-pointer m-0">
                      {{items?.assignee?.first_name}} {{items?.assignee?.last_name}}
                    </p>
                  </div>
                </div>
              </div>
              <div class="my-2 ">

                <div class="d-flex align-items-center  col">
                  <div class="mr-1 mt-4">
                    <span class="name-1 cursor-pointer" [ngStyle]="{'background': getRandomBg(i)}">
                      {{getName(items?.created_by?.first_name)}} {{getName(items?.created_by?.last_name)}}
                    </span>
                  </div>
                  <div>
                    <span class="reportedBy">Reported By</span>
                    <div class="ml-1">
                      <p class="text-dark fw-medium text-truncate card-title cursor-pointer m-0">
                        {{items?.created_by?.first_name}} {{items?.created_by?.last_name}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="proposalLists?.length == 0">
    <div class="col-md-12 mx-auto">
      <img src="./assets/images/no-record.png" class="no-records" height="100%" width="100%">
    </div>
    <div class="col-md-8 mx-auto">
      <p class="text-center fw-bold text-muted">
        {{staticText.common.no_records_found}}
      </p>
    </div>
  </div>
</div>

<div class="mb-4 px-2" *ngIf="screen_name == 'Innovation'">
  <div class="row  row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-7">
    <div class="col mt-4 p-0" *ngFor="let items of proposalLists; index as i ">
      <div class="card card-wrapper mx-2" (click)="ideaDetails(items?.id)">
        <div class="top-border" [ngClass]="{
              'red-border': items.status?.label == 'Rejected', 
              'initiated-border': items.status?.label == 'Approved', 
              'empty': items?.status == '',
              'green-border': items.status?.label === 'Open' , 
              'partially-closed-border' : items.status?.label == 'Under Review' 
           }">
        </div>
        <div class="row header-wrapper cursor-pointer">
          <div class="d-flex align-items-center  col">
            <div class="mr-1" *ngIf="items?.vendor_details == false ">
              <span class="name-1 cursor-pointer" [ngStyle]="{'backgroundColor': i|getRandomColor}">
                {{getName(items?.user_details)}}
              </span>
            </div>
            <div class="mr-1" *ngIf="items?.vendor_details != false">
              <span class="name-1 cursor-pointer" [ngStyle]="{'backgroundColor': i|getRandomColor:'chart'}">
                {{getName(items?.vendor_details.primary_contact_details.username)}}
              </span>
            </div>
            <div>
              <div *ngIf="items?.vendor_details == false">
                <p class="text-dark fw-bold text-truncate card-title cursor-pointer"
                  matTooltip="{{items?.user_details}}" matTooltipClass="example-tooltip">
                  Delta {{items?.user_details}}
                </p>
              </div>
              <div *ngIf="items?.vendor_details != false ">
                <p class="text-dark fw-bold text-truncate card-title cursor-pointer"
                  matTooltip="{{items?.vendor_details?.primary_contact_details?.username}}"
                  matTooltipClass="example-tooltip">
                  {{items?.vendor_details?.primary_contact_details?.username}}
                </p>
              </div>

              <small class="text-muted">{{items.created_on | date: 'd MMM y'}}</small>
            </div>
          </div>
        </div>
        <div class="col-12 sub-heading">
        </div>
        <div class="innovation-inside-card">
          <div class="last-wrapper">
            <div class="col-md-12 px-0">
              <div class="d-flex">
                <p class="text fw-bold idea-name" matTooltip="{{items?.name}}" matTooltipClass="example-tooltip">
                  {{items.name}}
                </p>
              </div>
              <div class="d-flex">
                <small class="benefits-details">
                  {{items?.benefits_details?.display_name}}
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-3 px-3">
          <div>
            <div class="d-flex align-items-center">
              <img src="./assets/images/people.svg"
                *ngIf="items?.initiative_type_details.display_name == 'People'">
              <img src="./assets/images/technology.svg"
                *ngIf="items?.initiative_type_details.display_name == 'Technology'">
              <img src="./assets/images/Business_Excellence.svg"
                *ngIf="items?.initiative_type_details.display_name == 'Business Excellence'">
              <img src="./assets/images/Process.svg"
                *ngIf="items?.initiative_type_details.display_name == 'Process'">
              <p class="p-0 text-nowrap mb-0"
                [ngClass]="{'people-initiative': items?.initiative_type_details.display_name == 'People' ,  'technology-initiative ': items?.initiative_type_details.display_name == 'Technology' , 'process-initiative': items?.initiative_type_details.display_name == 'Process', 'business-initiative': items?.initiative_type_details.display_name == 'Business Excellence'  }">
                {{items?.initiative_type_details.display_name}}</p>
            </div>
          </div>
          <div class=" status-text p-0">
            <p class="innovation-status" [ngClass]="{ 'red': items.status?.label == 'Rejected' , 'empty': items.status?.label == '' ,
        'initiated': items.status?.label == 'Approved' ,  
        'green': items.status?.label === 'Open' , 
        'partially-closed' : items.status?.label == 'Under Review'}">
              {{items.status?.label}}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="proposalLists?.length == 0">
    <div class="col-md-12 mx-auto">
      <img src="./assets/images/no-record.png" class="no-records" height="100%" width="100%">
    </div>
    <div class="col-md-8 mx-auto">
      <p class="text-center fw-bold text-muted">
        {{staticText.common.no_records_found}}
      </p>
    </div>
  </div>
</div>

<div class="mb-4 px-2" *ngIf="screen_name == 'Survey' ">
  <div class="row  row-cols-sm-2 row-cols-md-4 row-cols-lg-4 row-cols-xl-7">
    <div class="col mt-4 p-0" *ngFor="let items of proposalLists; index as i ">
      <div class="card card-wrapper mx-2" (click)="viewSurveyDetails(items,$event)">
        <div class="top-border" [ngClass]="{
              'red-border': items?.status?.label == 'Rejected' || items?.status?.label ==null, 
              'initiated-border': items?.status?.label == 'Approved', 
              'empty': items?.status == '',
              'green-border': items?.status?.label === 'Open' , 
              'partially-closed-border' : items?.status?.label == 'Under Review' 
           }">
        </div>
        <div class="row header-wrapper cursor-pointer">
          <div class="d-flex col">
            <div class="align-items-center ">
              <div>
                <p class="text-dark fw-bold text-truncate card-title cursor-pointer">
                  {{items?.name}}
                </p>
              </div>
              <!-- <small class="text-muted">{{items.date_modified | date: 'd MMM y'}}</small> -->
            </div>
            <!-- <ng-container *ngIf="!items.survey_published">
              <div class="col" *ngxPermissionsOnly="['logger.add_publish']">
                <img src="./assets/Icons/table-card/view.svg" (click)="openSurveyModal(items.formid,$event)">
              </div>
            </ng-container> -->
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-3 mt-3 flex-row">
          <div class="survey-inside-card">
            <div class="last-wrapper">
              <div class="col-12 d-flex mb-1" *ngIf="items.start_date">
                <img src="./assets/Icons/table-card/calendar.svg">
                <span class="pl-2">{{items.start_date | date: 'dd MMM yyyy'}}</span>
              </div>
              <div class="col-12 d-flex" *ngIf="items.end_date">
                <img src="./assets/Icons/table-card/calendar.svg">
                <span class="pl-2">{{items.end_date | date: 'dd MMM yyyy'}}</span>
              </div>
            </div>
          </div>
          <div class="status-text p-0 mr-3 mt-4 w-40">
            <p class="innovation-status" 
            [ngClass]="{ 'red': items.status?.label == 'Rejected' ||  items.status?.label == null  , 'empty': items.status?.label == '' ,
        'initiated': items.status?.label == 'Approved' ,  
        'green': items.status?.label === 'Open' , 
        'partially-closed' : items.status?.label == 'Under Review'}">
              {{items.status?.label ?? 'open' | titlecase }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="proposalLists?.length == 0">
    <div class="col-md-12 mx-auto">
      <img src="./assets/images/no-record.png" class="no-records" height="100%" width="100%">
    </div>
    <div class="col-md-8 mx-auto">
      <p class="text-center fw-bold text-muted">
        {{staticText.common.no_records_found}}
      </p>
    </div>
  </div>
</div>
<ng-template #projectsview>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div class="d-flex justify-content-between align-items-center header-wrapper px-2 pr-0">
            <div class="">
              <h5 class="font-weight-bold">{{card_data?.project_id}}</h5>
            </div>
            <div>
              <div class="col-12 status-text p-0">
                <p class="status-wrap px-2" [ngClass]="{ 'empty': card_data.status_details?.label == '' ,
                'initiated' : card_data.status_details?.label == 'Initiated',
                'partially-closed' : card_data.status_details?.label == 'Delivery','hold' : card_data.status_details?.label == 'Suspended',
                'red' : card_data.status_details?.label == 'Terminated','green' : card_data.status_details?.label == 'Delivered'}">
                  {{card_data.status_details?.action_complete_label}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-xl-auto section-portfolio pb-2 mt-3" matTooltip="Project Name">
            <img src="./assets/Icons/table-card/portfolio.svg" class="image-portfolio">
            <span class="content">{{card_data?.project_name}}</span>
          </div>
          <div class="col-xl-auto section-portfolio pb-2" matTooltip="Portfolio">
            <img src="./assets/Icons/table-card/portfolio.svg" class="image-portfolio">
            <span class="content">{{card_data?.portfolio ||'N/A'}}</span>
          </div>
          
          <div class="col-12 ">
            <div class="d-flex">
              <div>
                <img src="./assets/images/delivery-projects.svg" class="mr-3 project-name">
              </div>
              <div class="d-flex w-100">
                <div matTooltip="OU/GDP" class="col-6 pl-0">{{card_data?.vendor_details?.name|| 'N/A'}}</div>
                <div class="col-6">
                  <span matTooltip="Project Value" matTooltipClass="example-tooltip">{{card_data?.project_value || 'N/A'}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 d-flex mt-2" >
            <div class="d-flex " *ngIf="card_data?.begin_date || card_data?.expected_end_date;else noData">
              <img class="mr-2 date-icons" src="./assets/Icons/table-card/start_date.svg">
              <div *ngIf="card_data?.begin_date" matTooltip="Project Begin Date">
                <span class="field-text px-2">{{card_data?.begin_date | date:"dd-MM-yyyy" }}</span>
              </div>
              &nbsp;-&nbsp;
              <div *ngIf="card_data?.expected_end_date" matTooltip="Project End Date">
                <img class="mr-2 date-icons" src="./assets/Icons/table-card/end_date.svg">
                <span class="field-text px-2">{{card_data?.expected_end_date | date:"dd-MM-yyyy"}}</span>
              </div>
            </div>
            <ng-template #noData><span class="px-2">N/A</span></ng-template>
          </div>
          <hr class="m-0">
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="row">
          <div class="col-md-6">
            <button type="button" class="btn font-weight-bold text-primary " (click)="closeDialog()"> Close</button>

          </div>
          <div class="col-md-6 pl-0">
            <button type="button" class="btn btn-primary" (click)="screenInfo(card_data?.id)">Overview</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #viewProposalPopup>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div class="d-flex justify-content-between align-items-center header-wrapper pr-0">
            <div class="px-2">
              <h5 class="font-weight-bold">{{card_data?.opportunity_number}}</h5>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="">
                <div class="col-12 status-text p-0">
                  <p class="status-wrap px-2" [ngClass]="{ 'initiated': card_data.status =='Published'}">
                    {{card_data.status}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 sub-heading">{{card_data?.opportunity_name}}</div>
          <div class="col-xl-auto section-portfolio pb-2">
            <img src="./assets/Icons/table-card/portfolio.svg" class="image-portfolio">
            <span class="content">{{card_data?.portfolio_details?.[0]?.display_name}}</span>
          </div>
          <div class="col-12 pb-2">
            <img src="./assets/Icons/table-card/reservations.svg" class="image-sub-portfolio">
            <span class="content">{{card_data?.portfolio_details?.[1]?.display_name || 'N/A'}}</span>
          </div>

          <div class="col-12 pb-3" >
            <div class="d-flex">
              <!-- <span class="content">{{card_data?.last_updated_on | date: 'd MMM y, h:mm' }}</span> -->
              <div *ngIf="card_data?.start_date" matTooltip="Opportunity Start Date">
                <img src="./assets/Icons/table-card/start_date.svg">
                <span class="field-text px-2">{{card_data?.start_date | date: 'd MMM y, h:mm' }}</span>
              </div>
              <!-- <div *ngIf="!card_data?.last_updated_on">
                <img src="./assets/Icons/table-card/calendar.svg">
                <span class="field-text px-2">N/A</span>
              </div> -->
            </div>
          </div>
          
          <table class="table vendor-tbl" *ngIf="card_data?.vendor_published_details && card_data?.vendor_published_details?.length>0">
            <thead>
              <tr class="row-hide" >
                <th>GDP/Vendor</th>
                <th >Published Date</th>
                <th >Response Date</th>
                <th >Elapsed Days</th>
                <th>Status</th>
              </tr> 
            </thead>
            <tbody>
              <tr *ngFor="let item of card_data?.vendor_published_details">
                <td class="content"><div class="fs-14">{{item.vendor}}</div></td>
                <td class="input-fld"><div class="fs-14">{{(item.published_date | date:'dd MMM yyyy')||'-'}}</div></td>
                <td class="input-fld"><div class="fs-14">{{(item.response_date | date:'dd MMM yyyy')||'-'}}</div></td>
                <td class="content input-fld number"><div class="fs-14">{{item.elapsed_days}}</div></td>
                <td class="p-0 vendor-status">
                  <div>
                    <span class="link" *ngIf="item.vendor_status == 'SoW Signed';else otherStatus">{{item.vendor_status}}</span>
                    <!--(click)="navigateToProject()"-->
                    <ng-template #otherStatus>{{item.vendor_status}}</ng-template>
                  </div>
                </td>
              </tr>        
            </tbody>
         </table>
          <hr class="mb-3">
          <div class="col-12 pb-3">
            <div class="pb-3 row justify-content-between align-items-center">
              <div class="col-md-6 pb-2">
                <div>
                  <label class="field-label">Start Date</label>
                </div>
                <div *ngIf="card_data?.start_date">
                  <span class="field-text">{{card_data?.start_date | date:"dd-MM-yyyy  HH:mm"}}</span>
                </div>
                <div *ngIf="!card_data?.start_date">
                  <span class="field-text">N/A</span>
                </div>
              </div>
              <div class="col-md-6 pb-2">
                <div>
                  <label class="field-label">Closure Date</label>
                </div>
                <div *ngIf="card_data?.closure_date">
                  <span class="field-text">{{card_data?.closure_date}}</span>
                </div>
                <div *ngIf="!card_data?.closure_date">
                  <span class="field-text">N/A</span>
                </div>
              </div>
              <div class="col-md-6">
                <div>
                  <label class="field-label">Manager</label>
                </div>
                <span class="field-text">{{card_data?.proposal_manager_details?.full_name || 'N/A'}}</span>
              </div>
              <div class="col-md-6">
                <div>
                  <label class="field-label">Initiate by </label>
                </div>
                <span class="field-text"> {{card_data?.initiated_by.full_name || 'N/A'}}</span>
              </div>
            </div>
          </div>
          <hr class="m-0">
        </div>
      </div>
      <div class="modal-footer border-0">

        <div class="px-2">
          <button type="button" class="btn font-weight-bold text-primary " (click)="closeDialog()"> Close</button>
        </div>
        <div class="px-2">
          <button type="button" class="btn btn-primary" (click)="navigateTo(card_data?.id)">Overview</button>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #viewOpportunity>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div class="d-flex justify-content-between align-items-center header-wrapper pr-0">
            <div class="px-2">
              <h5 class="font-weight-bold">{{card_data?.opportunity_number}}</h5>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="">
                <div class="col-12 status-text p-0">
                  <p class="status-wrap" [ngClass]="{ 'red': card_data.status?.label == 'Draft'|| card_data.status?.label == 'Cancelled' , 'empty': card_data.status?.label == '' ,
                    'hold': (card_data.status?.label == 'Create' || card_data.status?.label == 'Modified') ,  
                    'partially-closed': card_data.status?.label == 'Evaluating' , 
                    'initiated' : card_data.status?.label == 'Published',
                    'green' : card_data.status?.label == 'SoW Signed'}">
                    {{card_data?.status?.action_complete_label}}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 sub-heading">{{card_data?.opportunity_name}}</div>
          <div class="col-xl-auto section-portfolio pb-2">
            <img src="./assets/Icons/table-card/portfolio.svg" class="image-portfolio">
            <span class="content">{{card_data?.portfolio_details?.[0]?.display_name}}</span>
          </div>
          <div class="col-12 pb-2">
            <img src="./assets/Icons/table-card/reservations.svg" class="image-sub-portfolio">
            <span class="content">{{card_data?.portfolio_details?.[1]?.display_name || 'N/A'}}</span>
          </div>

          <div class="col-12 pb-3" matTooltip="Opportunity Start Date">
            <img class="mr-2 date-icons" src="./assets/Icons/table-card/start_date.svg">
            <span class="content">{{card_data?.created_on | date: 'd MMM y, hh:mm' }}</span>
          </div>

          <table class="table vendor-tbl" *ngIf="card_data?.vendor_published_details && card_data?.vendor_published_details?.length>0">
            <thead>
              <tr class="row-hide" >
                <th>GDP/Vendor</th>
                <th >Published Date</th>
                <th >Response Date</th>
                <th >Elapsed Days</th>
                <th>Status</th>
              </tr> 
            </thead>
            <tbody>
              <tr *ngFor="let item of card_data?.vendor_published_details">
                <td class="content"><div class="fs-14">{{item.vendor}}</div></td>
                <td class="input-fld"><div class="fs-14">{{(item.published_date | date:'dd MMM yyyy')||'-'}}</div></td>
                <td class="input-fld"><div class="fs-14">{{(item.response_date | date:'dd MMM yyyy')||'-'}}</div></td>
                <td class="content input-fld number"><div class="fs-14">{{item.elapsed_days}}</div></td>
                <!-- <td class="date-fld"><div>
                  <mat-form-field appearance="outline" class="w-100 p-0">
                    <input matInput [matDatepicker]="picker" readonly [value]="item.published_date"
                        fullWidth status="basic" (click)="picker.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                  </div>
                </td>
                <td class="date-fld">
                  <div>
                  <mat-form-field appearance="outline" class="w-100 p-0">
                    <input matInput [matDatepicker]="picker2" readonly [value]="item.response_date"
                        fullWidth status="basic" (click)="picker2.open()">
                    <mat-datepicker-toggle matSuffix [for]="picker2"> </mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  </div>
                </td>
                <td class="input-fld number"><div><input type="number" [value]="item.elapsed_days"></div></td> -->
                <td class="p-0 vendor-status">
                  <div>
                    <span class="link" *ngIf="item.vendor_status == 'SoW Signed';else otherStatus">{{item.vendor_status}}</span>
                    <!--(click)="navigateToProject()"-->
                    <ng-template #otherStatus>{{item.vendor_status}}</ng-template>
                  </div>
                </td>
              </tr>        
            </tbody>
         </table>
          <hr class="mb-3">
          <div class="col-12 pb-3">
            <div class="pb-3 d-flex justify-content-between align-items-center">
              <div>
                <div class="d-flex">
                  <label class="field-label">Created by</label>
                </div>
                <span class="field-text">{{card_data?.created_user_details?.full_name}}</span>
              </div>
              <div class="datepicker">
                <div class="d-flex">
                  <label class="field-label">Last Modified </label>
                </div>
                <span class="field-text"> {{card_data?.last_updated_on | date: 'd MMM y, hh:mm' }}</span>
              </div>
            </div>
          </div>
          <hr class="m-0">
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="d-inline-flex">
          <div class="px-2">
            <button type="button" class="btn font-weight-bold text-primary " (click)="closeDialog()"> Close</button>
          </div>
          <div class="col-md-6 pl-0">
            <button type="button" class="btn btn-primary" (click)="viewPreview(card_data?.id)">Overview</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #view>
  <div class="modal-dialog view-screen custom-width m-0">
    <div class="modal-content">
      <div class="modal-body">
        <div class="modal-popup">
          <div class="d-flex justify-content-between align-items-center header-wrapper pr-0">
            <div class="px-2">
              <h5 class="font-weight-bold">{{card_data?.opportunity_number}}</h5>
            </div>
            <div class="d-flex align-items-center justify-content-center">
              <div class="">
                <p class="status-wrap" [ngClass]="{ 'red': card_data?.status?.label == 'Draft'|| card_data?.status?.label == 'Cancelled' , 'empty': card_data?.status?.label == '' ,
                'initiated': card_data?.status?.label == 'Published' ,  'partially-closed': card_data?.status?.label == 'Evaluating' ,  
                'hold': card_data?.status?.label === 'Create'|| card_data?.status?.label === 'Modified' , 
                'green' : card_data?.status?.label == 'SoW Signed'}">
                {{card_data.status?.action_complete_label}}
              </p>
              </div>
            </div>
          </div>
          <div class="col-12 pb-3 sub-heading">{{card_data?.opportunity_name}}</div>
          <div class="col-xl-auto section-portfolio pb-2">
            <img src="./assets/Icons/table-card/portfolio.svg" class="image-portfolio">
            <span class="content">{{card_data?.portfolio_details?.[0].display_name}}</span>
          </div>

          <!-- <div class="col-12 p-0  mat-datepicker datepicker-popup pb-2" matTooltip="Initiation date">
            <div class="d-flex date-picker-mat">
              <mat-datepicker-toggle matPrefix [for]="picker">
              </mat-datepicker-toggle>
              <mat-datepicker #picker>
              </mat-datepicker>
              <input matInput [matDatepicker]="picker" readonly status="basic" class="py-0" placeholder="Select"
                [value]="card_data.initiation_date" (dateChange)="onDateChange($event, card_data)"
                (click)="picker.open()" [(ngModel)]="card_data.initiation_date" class="text-grey">
            </div>
          </div> -->
          <div class="col-12 pb-3">
            <img src="./assets/Icons/table-card/reservations.svg" class="image-sub-portfolio">
            <span class="content">{{card_data?.portfolio_details?.[1]?.display_name || 'N/A'}}</span>
          </div>
          <div class="col-12 pb-3" >
            <div class="d-flex">
              <div matTooltip="Initiation Date">
                <img src="./assets/Icons/table-card/start_date.svg">
                <span class="content">{{(card_data?.initiation_date | date: 'd MMM y, h:mm' )||'N/A'}}</span>
              </div>
            </div>
          </div>
          
          <table class="table vendor-tbl" *ngIf="card_data?.vendor_published_details && card_data?.vendor_published_details?.length>0">
            <thead>
              <tr class="row-hide" >
                <th>GDP/Vendor</th>
                <th >Published Date</th>
                <th >Response Date</th>
                <th >Elapsed Days</th>
                <th>Status</th>
              </tr> 
            </thead>
            <tbody>
              <tr *ngFor="let item of card_data?.vendor_published_details">
                <td class="content"><div class="fs-14">{{item.vendor}}</div></td>
                <td class="input-fld"><div class="fs-14">{{(item.published_date | date:'dd MMM yyyy')||'-'}}</div></td>
                <td class="input-fld"><div class="fs-14">{{(item.response_date | date:'dd MMM yyyy')||'-'}}</div></td>
                <td class="content input-fld number"><div class="fs-14">{{item.elapsed_days}}</div></td>
                <td class="p-0 vendor-status">
                  <div>
                    <span class="link" *ngIf="item.vendor_status == 'SoW Signed';else otherStatus">{{item.vendor_status}}</span>
                    <!--(click)="navigateToProject()"-->
                    <ng-template #otherStatus>{{item.vendor_status}}</ng-template>
                  </div>
                </td>
              </tr>        
            </tbody>
         </table>
          <hr class="mb-3">
          <div class="col-12 pb-3">
            <div class="pb-3 d-flex justify-content-between align-items-center">
              <div>
                <div class="d-flex">
                  <label class="field-label">Initiated by</label>
                </div>
                <p class="field-text text-truncate mb-0" matTooltip={{card_data?.initiated_by?.username}}>
                  {{card_data?.initiated_by?.first_name || 'N/A'}} {{card_data?.initiated_by?.last_name}}</p>
              </div>
              <div class="datepicker">
                <div class="d-flex">
                  <label class="field-label">Last Modified </label>
                </div>
                <span class="field-text"> {{(card_data?.last_updated_on| date: 'd MMM y, hh:mm')|| 'N/A'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="d-flex">
                <label class="field-label">Last Modified by</label>
              </div>
              <p class="field-text text-truncate mb-0" matTooltip={{card_data?.last_updated_by?.first_name}}>
                {{card_data?.last_updated_by?.first_name|| 'N/A'}} {{card_data?.last_updated_by?.last_name}}</p>
            </div>
          </div>
          <hr class="m-0">
        </div>
      </div>
      <div class="modal-footer border-0">
        <div class="d-flex">
          <div class="px-2">
            <button type="button" class="btn font-weight-bold text-primary " (click)="closeDialog()"> Close</button>

          </div>
          <div class="px-2">
            <button type="button" class="btn btn-primary" (click)="screenInfo(card_data?.id)">Patner Approval</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>