import { Component, EventEmitter, HostListener, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { NgxSpinnerService } from 'ngx-spinner';
import { Constants } from '../../constant/constant';
import { ModalComponent } from '../modal/modal.component';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { NotificationsService } from '../../services/notifications.service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActionTrackerService } from '../../services/action-tracker.service';
import { ToastrService } from 'ngx-toastr';
import { map, startWith, take } from 'rxjs/operators';
import { ViewEncapsulation } from '@angular/core';
import { FormValidatorService } from '../../services/form-validator/form-validator.service';
import { ProgramService } from '../../services/program.service';
import { ProjectService } from '../../services/project.service';
import { AuthService } from '../../services/auth.service';
import { ComingSoonDialog } from '../coming-soon/coming-soon-dialog';
import { environment } from 'src/environments/environment';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [DatePipe],
  encapsulation: ViewEncapsulation.Emulated
})
export class HeaderComponent implements OnInit {
  tips = true;
  showIdea = false;
  user: any;
  @Output() refereshParentData = new EventEmitter<string>();
  toggle = true;
  toggle1 = true;
  enableSuitablility = false;
  enableDelivery = false;
  enableEngagement = false;
  enableInsights = false;
  enableProposals = false;
  customStyle = {
    backgroundColor: "#92c353",
    borderRadius: "50%",
    color: "#ffffff",
    cursor: "pointer",
    fontWeight: 'bolder'
  };
  enableInnovation = false;
  showNotifications: boolean = false;
  showProfilePopUp: boolean = false;
  showProfilePopUpMain: boolean =false;

  showLoginPopUp:boolean=false;
  staticText: any = (textConfiguration as any).default;
  notficationsList: any[] = [];
  vendorList: any;
  selected: any;
  authenticated: boolean = false;



  files: any[] = [];
  fileList: any[] = [];
  actionTrackerForm: FormGroup | any;
  actionCategory: any;
  assignee: any;
  filterActionTracker: any;
  filterAssignee: any;
  public trackerFormData: any = new FormData();
  trackerDetailsName: any;
  userDetails: any;
  currentDate = new Date();
  vendorDetail: any;
  gdpList: any;
  filterGDP: any;
  showAdditionalDetails = false;
  pageID: any;
  projectPaths = ['/overview', '/resource', '/scorecard', '/change-request', '/okrlog', '/risklog', '/training', '/release', '/toe', '/credentials']
  opportunityPaths = ['/setup', '/scope', '/evaluation', '/terms', '/more-info', '/preview', '/proposal-evaluation', '/summary', '/award-deal'];
  
  ideapitchUrl: any;
  iframeEmbedded: boolean = false;

  constructor(
    private msalAuthService: MsalService,
    private dialog: MatDialog,
    private auth: AuthService,
    private notificationService: NotificationsService, private renderer: Renderer2,
    private router: Router, private datepipe: DatePipe, public fb: FormBuilder, private activatedRoute: ActivatedRoute, private spinner: NgxSpinnerService, private toastrService: ToastrService, private actionTracker: ActionTrackerService,
    private _formValidator: FormValidatorService, private ps: ProgramService, private projectService: ProjectService,
    private messageService: MessageService
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.activatedRoute.params.subscribe((res: any) => {
      if (res?.id)
        this.pageID = atob(res.id);
    })
  }

  ngOnInit() {
    this.messageService.getIframeData().subscribe((res: any) => {
        this.iframeEmbedded = typeof res != 'object' ? true : false;
    })
    if(localStorage.getItem('token')){
      this.authenticated = true;
      this.user = JSON.parse(localStorage.getItem('permission') || '{}');
      if(!this.user?.is_vendor)
        this.getAllUnReadNotificaitons();
    }

    this.renderer.listen('window', 'click',(e:Event)=>{ 
      this.showNotifications = false
    })

    this.ideapitchUrl = environment.ideapitchURL;
  }

  signIn(){
    const currentState = this.router.getCurrentNavigation()?.extras.state;
    const newState = { ...currentState };
    if(!newState){
      this.router.navigateByUrl('/auth/login');
    }else{
      this.router.navigateByUrl('/auth/login', { state: newState });
    }
  }

  signUp(){
    const currentState = this.router.getCurrentNavigation()?.extras.state;
    const newState = { ...currentState };
    if(!newState){
      this.router.navigateByUrl('/auth/signup');
    }else{
      this.router.navigateByUrl('/auth/signup', { state: newState });
    } 
  }

  logOut() {
    this.showProfilePopUp = false;
    this.showProfilePopUpMain = false;
    //It will solve respective issue when we click logout btn it's opening popup as mutiple instance it's kinda problem.
    this.dialog.closeAll();
    const dialog = this.dialog.open(ModalComponent, { hasBackdrop: true });
    dialog.componentInstance.type = 'Logout';
    dialog.afterClosed().subscribe(data => {
      if (!!data) {
        if (localStorage.getItem('sso-login') == 'true') {
          this.msalAuthService.logoutRedirect();
        }
        localStorage.clear();
        window.location.reload();
        
      }
    })
  }

//   @HostListener('document:click', ['$event'])
  // onGlobalClick(event: any): void {
  //   if (!document.getElementById('notificationbell')?.contains(event?.target) && !document.getElementById('notificationbox')?.contains(event?.target)) {
  //     this.showNotifications = false;
  //   }
  //   if (!document.getElementById('profile')?.contains(event?.target) && !document.getElementById('profileBox')?.contains(event?.target)) {
  //     this.showProfilePopUp = false;
  //     this.showProfilePopUpMain = false;
  //   }
  //   if (!document.getElementById('profile_main')?.contains(event?.target) && !document.getElementById('profileBox')?.contains(event?.target)) {
  //     this.showProfilePopUpMain = false;
  //   }
  // }
  getVendor() {
    this.ps.getOpportunityFormData('opportunity_empanelled_vendors').subscribe((res: any) => {
      this.vendorList = JSON.parse(JSON.stringify(res.records[0].field_values));
      this.selected = this.vendorList[0].id;
      localStorage.setItem('vendorId', this.selected);
    
      //localStorage.setItem('vendorList', this.vendorList);
      localStorage.setItem('vendorList', JSON.stringify(this.vendorList));  
    }, error => {
      this.spinner.hide();
      throw error;
    })
  }

  enableDisableRule() {
    this.toggle = !this.toggle;
  }

  enableDisableRule1() {
    this.toggle1 = !this.toggle1;
  }

  clickOpportunity() {
    this.router.navigate(['program']);
  }

  clickDelivery() {
    this.router.navigate(['delivery/projects']);
  }

  clickProposals() {
    this.router.navigate(['proposals']);
  }

  clickEngagement() {
    this.router.navigate(['deal-structure']);
  }

  clickIdeaPage() {
    this.router.navigate(['innovation/idea-page']);
  }

  navigateTo(link: string) {
    this.router.navigate([link])
  }
  menuOpened(type: any) {
    if (type == 'suitability') {
      this.enableSuitablility = true;
    }
    else if (type == 'delivery') {
      this.enableDelivery = true;
    }
    else if (type == 'engagement') {
      this.enableEngagement = true;
    }
    else if (type == 'opportunity') {
      this.enableProposals = true;
    }
    else if (type == 'insights') {
      this.enableInsights = true;
    }


  }
  menuClosed(type: any) {
    if (type == 'suitability') {
      this.enableSuitablility = false;
    }
    else if (type == 'delivery') {
      this.enableDelivery = false;
    }
    else if (type == 'engagement') {
      this.enableEngagement = false;
    }
    else if (type == 'opportunity') {
      this.enableProposals = false;
    }
    else if (type == 'insights') {
      this.enableInsights = false;
    }

  }

  openSite() {
    // this.router.navigate(['website']);
    window.open('https://spdevimp.sharepoint.com/sites/PwCITServicesdev/Pages/home.aspx');
  }

  openNewSite() {
    window.open('https://spdevimp.sharepoint.com/sites/PwCDms2/Pages/Home.aspx');
  }

  navigateToHome() {
    this.router.navigate(['auth/landing']);
  }

  navigateToSP() {
    window.open(Constants.SHAREPOINT_URL, '_blank');
  }

  addActionTracker(popupname: any) {
    if (!this.actionTrackerForm?.controls) {
      this.getTrackerDetails();
      this.createForm();
      this.getActionCategory();
      this.getAssignee();
     // this.getGDP();
    }

    this.actionTrackerForm.get("status").setValue('Open');
    this.actionTrackerForm.get("status").disable();
    //this.actionTrackerForm.get('vendor').disable();
    this.dialog.open(popupname, {
      disableClose: true,
    }
    )
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  openBlank(link: any){
    window.open(link, "_blank")
  }

  getAllUnReadNotificaitons() {
    this.notificationService.getAllNotifications('unread').subscribe((getUnreadList: any) => {
      this.notficationsList = getUnreadList.notifications;
      this.notficationsList = this.notficationsList.map((mapData: any) => {
        mapData['routepath'] = this.routePage(mapData?.subject?.toLowerCase(), mapData?.details?.id);
        return mapData;
      })
    })
  }

  routePage(Page: any, routeId: any) {
    let routepath: any;
    switch (Page) {
      case 'proposal':
        routepath = `/getproposal/${btoa(routeId)}/proposal-overview`
        break;
      case 'opportunity':
        routepath = `/rfx/` + btoa(routeId) + `/opportunity/preview`
        break;
      case 'delivery':
        routepath = `/balancedscorecard/dashboard?project=` + btoa(routeId)
        break;
      case 'bvt':
        routepath = '/BVT/list'
        break;
      case 'okr':
        routepath = '/okrconvergence'
        break;
      case 'workforcedashboard':
        routepath = `/workforce/view/` + btoa(routeId)
        break;
      case 'ci':
        routepath = `/innovation/detail-ideas/` + btoa(routeId)
        break;
      default:
        routepath = ''
        break;
    }
    return routepath;
  }


  MarkAsReadNotification(id: number) {
    this.notificationService.getAllReadNotifications(id, '').subscribe((getReadStatus: any) => {
      if (getReadStatus?.status === 200) {
        this.notficationsList = getReadStatus?.body?.notifications;
      }
    });
  }


  getTrackerDetails() {
    if (localStorage.getItem("permission") != null && localStorage.getItem("permission") != undefined) {
      let logindata: any = localStorage.getItem("permission");
      if (logindata != null && logindata != 'undefined') {
        var loginDetails = JSON.parse(logindata);
        this.trackerDetailsName = loginDetails.username
        if (this.trackerDetailsName != '') {
          this.getUserDetails();
        }
      }
    }
  }


  getUserDetails() {
    const data = {
      username: this.trackerDetailsName
    }
    this.actionTracker.userDetails(data).subscribe((response: any) => {
      this.userDetails = response[0].user;
    })
  }

  createForm() {
    this.actionTrackerForm = this.fb.group({
      title: [, { validators: [Validators.required, Validators.maxLength(100),] }],
      assignee: [, { validators: [Validators.required, this._formValidator.autocompleteObjectValidator()] }],
      vendor: [''],
      description: [, { validators: [Validators.required] }],
      action_category: [, { validators: [Validators.required, this._formValidator.autocompleteObjectValidator()] }],
      due_date: [, { validators: [Validators.required] }],
      documents: [],
      additional_detail: [''],
      additional_detail1: [''],
      status: []
    });
  }

  submitActionTracker() {
    if (this.actionTrackerForm.valid) {
      this.trackerFormData = new FormData();
      this.spinner.show();
      this.trackerFormData.append('title', this.actionTrackerForm.value.title)
      this.trackerFormData.append('description', this.actionTrackerForm.value.description)
      this.trackerFormData.append('assignee', this.actionTrackerForm.value.assignee?.mail);
      this.trackerFormData.append('action_tracker_type', this.actionTrackerForm.get("action_category").value?.id)
      //this.trackerFormData.append('vendor', this.actionTrackerForm.get("vendor").value?.[0]?.id)
      this.trackerFormData.append('status', this.actionTrackerForm.get("status").value)
      if (this.showAdditionalDetails && this.actionTrackerForm.get("action_category").value?.name == 'Project') {
        this.trackerFormData.append('project', this.pageID);
      } else if (this.showAdditionalDetails && this.actionTrackerForm.get("action_category").value?.name == 'Opportunity') {
        this.trackerFormData.append('opportunity', this.pageID);
      }
      this.trackerFormData.append('due_date', this.datepipe.transform(this.actionTrackerForm.value.due_date, 'yyyy-MM-dd'))
      for (var i = 0; i < this.files.length; i++) {
        this.trackerFormData.append('documents', this.files[i]);
      }
      this.actionTracker.submitForm(this.trackerFormData).subscribe((res: any) => {

        if (res) {
          this.toastrService.success("Action Created Succesfully");
          this.router.navigateByUrl('/actiontracker');
          this.dialog.closeAll();
          this.refereshParentData.emit();
          this.actionTrackerForm.reset();
          this.spinner.hide();
        }
        else {
          this.toastrService.warning("Please Enter mandatory Fields");
          this.spinner.hide();
        }
      });
    }
    else {
      return
    }

  }


  getActionCategory() {
    this.actionTracker.getActionCategoryList().subscribe((response: any) => {
      this.actionCategory = response;
      let data = '';
      let urls: any = this.router.url.split("/");
      let currentRoute = "/" + urls.pop();

      if (this.projectPaths.includes(currentRoute)) {
        data = this.actionCategory?.find((e: any) => e.name == 'Project');
        this.showAdditionalDetails = true;
      } else if (this.opportunityPaths.includes(currentRoute)) {
        data = this.actionCategory?.find((e: any) => e.name == 'Opportunity');
        this.showAdditionalDetails = true;
      }
      if (this.showAdditionalDetails) {
        this.actionTrackerForm.get("action_category").setValue(data);
        this.actionTrackerForm.get("action_category").disable();
        this.actionCategory = [data];
        if (this.actionTrackerForm.get("action_category").value?.name == 'Project') {
          this.projectService.getprojectdetailsOverview(this.pageID).subscribe((res: any) => {
            this.actionTrackerForm.get("additional_detail").setValue(res?.project_name);
            this.actionTrackerForm.get("additional_detail1").setValue(res?.project_id);
            this.actionTrackerForm.get("vendor").setValue([res?.vendor?.name]);
          });
        } else {
          this.ps.getOppStatus(this.pageID).subscribe((res: any) => {
            this.actionTrackerForm.get("additional_detail").setValue(res?.opportunity_name);
            this.actionTrackerForm.get("additional_detail1").setValue(res?.opportunity_number);
            res.opportunity_vendors = res?.opportunity_vendors.map((e: any) => e.vendor_details?.name);
            this.actionTrackerForm.get("vendor").setValue(res?.opportunity_vendors?.join(","));
          });
        }
        this.actionTrackerForm.get("additional_detail").disable();
        this.actionTrackerForm.get("additional_detail1").disable();
      }
      this.filterActionTracker = this.actionTrackerForm.get('action_category')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((name: any) => name ? this._filterActionTracker(name) : this.actionCategory.slice())
        );
    });
  }

  getAssignee() {
    this.actionTracker.getAssigneeList().subscribe((response: any) => {
      this.assignee = response['records'].find((i: any) => i.field_name == "opportunity_team").field_values;
      this.filterAssignee = this.actionTrackerForm.get('assignee')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.display_name),
          map((name: any) => name ? this._filterAssignee(name) : this.assignee.slice())
        );
    });
  }

  getGDP() {
    this.actionTracker.getVendorList().subscribe((res: any) => {
      if (res) {
        this.gdpList = res.records[0].field_values;
        this.filterGDP = this.actionTrackerForm.get('vendor')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this._filterGDP(name) : this.gdpList.slice())
          );
      }
    });
  }

  private _filterGDP(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.gdpList.filter((tag: any) => tag?.name?.toLowerCase().includes(filterValue));
  }

  displayFnGDP(data: any) {
    return data && data.name ? data.name : '';
  }


  private _filterActionTracker(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.actionCategory.filter((data: any) => data?.display_name?.toLowerCase().includes(filterValue));
  }
  private _filterAssignee(value: any): string[] {
    const filterValue = value.toLowerCase();
    return this.assignee.filter((e: any) => e?.first_name?.toLowerCase().includes(filterValue));
  }

  displayFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }
  displayActionCatFn(data: any) {
    return data && data.display_name ? data.display_name : '';
  }
  displayAssigneFn(data: any) {
    return data && data.name ? data.name : '';
  }

  onFileSelected(e: any) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.files.push(e.target.files[i]);
      this.fileList.push(e.target.files[i].name,);
    }
  }

  deleteFile(data: any) {
    this.files = this.files.filter(item => item.name !== data.name);
  }

  getName(name: any) {
    return name?.split(' ').slice(0, 2).map((n: any) => n[0],).join('');
  }

  openComingSoon(){
    this.dialog.open(ComingSoonDialog);
  }
  
}
