import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScorecardService {
  private messageSource = new BehaviorSubject<any>([]);
  cardDetails = this.messageSource.asObservable();

  private okrSource = new BehaviorSubject<any>([]);
  okrDetails$ = this.okrSource.asObservable();

  private riskSource = new BehaviorSubject<any>([]);
  riskDetails$ = this.riskSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  okrSpread(data:any){
    this.okrSource.next(data);
  }

  riskSpread(data:any){
    this.riskSource.next(data);
  }


}
