import { T } from '@angular/cdk/keycodes';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { ComingSoonDialog } from '../coming-soon/coming-soon-dialog';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from '../../constant/constant';
import { MatStepper } from '@angular/material/stepper';
import { forEach } from 'lodash';

const TREE_DATA: any = [
    {
      name: 'CoSInE',
      children: [
        {name: 'Opportunity Workbench', url : '/program'},
        {name: 'Deal Structure', url : '/deal-structure'},
        {name: 'Challenge Workbench', url: '/challenge-workbench/challenge-statements'},
        {name: 'Idea Pitch', url: '/ideapitch'},
      ]
    }, {
        name: 'Kaleidoscope',
        children: [
          {name: 'Balanced Scorecard', url : '/balancedscorecard/dashboard'},
          {name: 'Risk Performance', url : '/riskconvergence'},
          {name: 'People Insights', url : '/workforce/dashboard'},
          {name: 'Hubble', url: '/performanceInsights/hubble'},
        ]
    }, {
        name: 'Polestar',
        children: [
          {name: 'OKR Convergence', url : '/okrconvergence'},
          {name: 'Assessment Central', url: 'strategicRelevance/assessment-central'},
          {name: 'Governance Lighthouse', url: 'strategicRelevance/governancect'},
          {name: 'Maven', url: 'strategicRelevance/maven'},
        ]
    }, {
        name: 'Confluence',
        children: [
          {name: 'Delivery Accelerator', url : '/delivery-accelerator/dashboard'},
          {name: 'Plan Central', url: '/confluence/teamplantracker'},
          {name: 'Parade', url: '/parade'},
          {name: 'Community of Practice', url: 'external'},
          
        ]
    },{
      name: 'Prism',
      children: [
        {name: 'Idea Pitch Login', url: '/prospect-provider/login'},
        {name: 'Project Central', url: '/delivery/projects'},
        {name: 'IPDB', url: 'prism/ipdb'},
        {name: 'Action Central', url: '/actiontracker'},
        {name: 'GRC Workbench', url: '/incidentracker'},
      ]
  }
];

const VENDOR_MENU_DATA: any = [
  {
    name: 'CoSInE',
    children: [
      {name: 'Opportunity Workbench', url : '/program'},
      {name: 'Challenge Workbench', url: '/challenge-workbench/challenge-statements'},
    ]
  }, {
      name: 'Confluence',
      children: [
        {name: 'Community of Practice', url: 'external'},
        
      ]
  },{
    name: 'Prism',
    children: [
      {name: 'Project Central', url: '/delivery/projects'},
      {name: 'GRC Workbench', url: '/incidentracker'},
    ]
}
];

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation:ViewEncapsulation.Emulated,
})
export class SideMenuComponent {
  mainmenu: any;
  @ViewChild('stepper') stepper: MatStepper | any;
  user: any;

  constructor(private route: Router, private dialog: MatDialog,    private activeroute: ActivatedRoute,
    ) {
    this.user = JSON.parse(localStorage.getItem('permission') || '{}');
    this.mainmenu = !this.user?.is_vendor ? TREE_DATA : VENDOR_MENU_DATA
  }
  ngOnInit(): void {
    

    this.activeroute.params.subscribe((res: any) => {
      this.stepSeleted();
 
    })

  }

  stepSeleted(){
    setTimeout(() => {
      for (var i in this.mainmenu) {
        this.mainmenu[i].children.forEach((element:any) => {
          if(this.route?.url?.includes('survey-details')){
            if(element.url.includes('hubble')){
              this.stepper.selectedIndex = i; 
            }
          }else if(this.route?.url?.includes('challenge-workbench')){
            if(element.url.includes('challenge-workbench')){
              this.stepper.selectedIndex = i; 
            }
          }else if(this.route?.url?.includes('delivery-accelerator')){
            if(element.url.includes('delivery-accelerator')){
              this.stepper.selectedIndex = i; 
            }
          }else if(this.route?.url?.includes('sowmaster')){
            if(element.url.includes('prism/ipdb')){
              this.stepper.selectedIndex = i; 
            }
          }else if(this.route?.url?.includes('toemasterlist')){
            if(element.url.includes('prism/ipdb')){
              this.stepper.selectedIndex = i; 
            }
          }else if(this.route?.url?.includes('confluence')){
            if(element.url.includes('confluence/teamplantracker') || element.url.includes('confluence/teamplantemplates') || element.url.includes('confluence/plan')){
              this.stepper.selectedIndex = i; 
            }
          }else if(this.route?.url?.includes('vendor-master')){
            if(element.url.includes('prism/ipdb')){
              this.stepper.selectedIndex = i; 
            }
          }else{
            if(this.route.url.includes(element.url)){
            this.stepper.selectedIndex = i; 
            }
          }



          // if(this.route.url != '/BVT/survey-details/results'){
          //   if(this.route.url.includes(element.url)){
          //     this.stepper.selectedIndex = i; 
          //   }
          // }else{
          //   this.stepper.selectedIndex = 1; 
          // }
  
        });
     }  
    }, 100);

   

  }


  onStepChange(evt:any){
    console.log(evt);
  }

  hasChild = (_: number, node: any) => !!node.children && node.children.length > 0;

  navigateUrl(url: any){
    if(url&&url.indexOf('external')>-1){
      this.navigateToSP();
    }
    // else if(url&&url.indexOf('ipdb')>-1){
    //   let temp_host = window.location.host;
    //   window.open('/server/admin', '_blank');
    // }
    else if(url){
      this.route.navigateByUrl(url)
    }else{
      this.dialog.open(ComingSoonDialog);
    }
   
  }
  navigateToSP() {
    window.open(Constants.SHAREPOINT_URL, '_blank');
  }
}

