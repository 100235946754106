<div class="nested-accordion" [ngStyle]="{'padding': rightpane ? '3px 4px 0 25px' : ''}">
  <div *ngFor="let item of items; let indx = index" class="nested-accordion-loop">
    <div class="accordion-item" *ngIf="item.level != 'last'">
      <div class="accordion-header" (click)="toggleAccordion(item, indx)" [ngStyle]="{'backgroundColor': item.expanded ? getColor(item) : '', 'color': item.expanded ? 'white' : '',
      'padding': rightpane ? '10px' : '', 'font-size': rightpane ? '12px' : ''}">
        <div class="title">{{ item.name }}</div>

        <div *ngIf="item?.owner?.full_name" class="owner-hold" [ngStyle]="{'color': item.expanded ? 'white' : ''}">
          Owner: {{item.owner.full_name}}
        </div>

        <div *ngIf="item?.level == 'program' && !rightpane && !item?.noArchiveFlag" class="owner-hold" [matTooltip]="'View Archives'"
        matTooltipPosition="right" [ngStyle]="{'color': item.expanded ? 'white' : ''}">
        

          <span class="cursor-pointer mx-1"
            (click)="archiveList(item);$event.stopPropagation();">
            <mat-icon>save_alt</mat-icon>
              <!-- <img src="./assets/images/archive-outline.svg"
                  style="width:20px;"> -->
          </span>
        </div>

        <div class="count-hold" [ngStyle]="{'color': !item.expanded ? 'black' : 'white'}">
          {{item?.opportunity_count|| item?.project_count}}
        </div>

        <div class="formParentArrow p-2 d-flex align-items-center" [ngStyle]="{'left': rightpane ? '-35px' : '', 'top': rightpane ? '0px': ''}">
          <span class="material-icons-outlined arrow-icon" *ngIf="!item.expanded" [ngStyle]="{'transform': rightpane ? 'scale(0.7)' : ''}">
              keyboard_arrow_down
          </span>
          <span class="material-icons-outlined arrow-icon" *ngIf="item.expanded" [ngStyle]="{'transform': rightpane ? 'scale(0.7)' : ''}">
              keyboard_arrow_up
          </span>
      </div>
      </div>
      <div class="accordion-content" [hidden]="!item.expanded">
        <app-loading *ngIf="loading"></app-loading>
        <app-tree-listing [tree]="tree" *ngIf="item.children" [rightpane]="rightpane" [items]="item.children" [context]="context"></app-tree-listing>
      </div>
    </div>
    <div *ngIf="item.level == 'last'" class="hov">
      <div [ngStyle]="{'backgroundColor': getColor(item)}" class="last-level" *ngIf="!rightpane" (click)="openUrl(item,$event);$event.stopPropagation()">
        <div class="levelnumb" >{{item?.opportunity_number || item?.project_id || item?.deal_number}}</div>
          <span class="hl"></span>
          <div >
            <div class="leveltext">{{item?.opportunity_name || item?.project_name || item?.deal_name}}
              <div style="font-size: 12px; font-weight: 100;">  
                <span *ngIf="item?.begin_date"> Begin Date: <b style="font-weight: 500"> {{item?.begin_date}}  </b> </span> 
                <span *ngIf="item?.resource_count"> Resource Count: <b style="font-weight: 500"> {{item?.resource_count}} </b> </span></div>
            </div>
            <div class="status-label">{{item?.status?.label}}</div>
          </div>
          <div class="function-btn" *ngIf="!user.is_vendor">
            <span class="cursor-pointer mx-1"
              (click)="deleteItem(item);$event.stopPropagation();">
                <img src="./assets/images/delete.svg"
                    style="width:14px;">
            </span>
            <span class="cursor-pointer mx-1" *ngIf="!item?.noArchiveFlag"
            (click)="archiveItem(item);$event.stopPropagation();">
              <img src="./assets/images/archive-outline.svg"
                  style="width:20px;">
          </span>
          </div>
      </div>
      <div [ngStyle]="{'backgroundColor': getColor(item)}" class="last-level" *ngIf="rightpane" (click)="openUrl(item,$event);$event.stopPropagation()">
        <div class="leveltext" style="font-size: 12px;">{{item?.project_name || item?.opportunity_name}}</div>
      </div>
    </div>
  </div>

</div>