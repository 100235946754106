import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { of ,Observable, Subject} from 'rxjs';
import {tap } from 'rxjs/operators';
import { K } from '@angular/cdk/keycodes';
const metricsListDatas = [
  { name: 'Java', displayName: 'Java' },
  { name: 'Angular', displayName: 'Angular' },
  { name: 'Dot Net', displayName: 'Dot Net' }
];

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  editId: any;
  private currentStatus: Subject<any> = new Subject();

  constructor(private http: HttpClient,private router:Router,private transferState: TransferState) { }
  resolve(route: ActivatedRouteSnapshot | any): Observable<any> {
    let elem:any = localStorage.getItem('program');
    elem = JSON.parse(elem);
    
   // console.log(localStorage.getItem('program').program_id);
    let id = route.params['id'];
    if (!id) {
      id = route?.parent.params['id'];
    }
    try{
      id = atob(id)
    }catch(e){
      this.router.navigate(["404"])
    }
    const detailKey = makeStateKey(`delivery/project-overview/?project_id=${id}&program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`);
    if(route.params['id']){
      return this.http.get(`delivery/project-overview/?project_id=${id}&program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`).pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      );
    }else{
      return of(this.transferState.get(detailKey, null));
    }
  }
  resolve1(route: ActivatedRouteSnapshot | any) {
    let id = route.params['id'];
    const detailKey = makeStateKey(`delivery/project-overview/?id=${id}/`);
    return this.transferState.get(detailKey,null);
  }
  public getStatusValue(): Observable<any> {
    return this.currentStatus.asObservable();
  }
  public setStatusValue(value: any): void {
    this.currentStatus.next(value);
  }
  getListProjects(limit?: any, offset: number = 0, sort?: any, search?: any, country?: any) {
    return this.http.get(`opportunity/?offset=${offset}&end=${limit + offset}`);
  }

  getOpportunityFormData(limit?: any, offset: number = 0, sort?: any, search?: any, country?: any) {
    //return this.http.get('/opportunity');
    return this.http.get('delivery/delivery-opportunity-list/');
  }
  
  downloadFile(payload: any, projectId: any) {
    return this.http.post(`delivery/sow-signed-contract-download/${projectId}/`, payload, { responseType: 'blob' });
  }

  deliveryProjectList() {
    return this.http.get('delivery/project-list');
  }

  projectmapping(data: any) {
    return this.http.post('delivery/map-project/', data);
  }

  saveProjectOkrLog(payload: any) {
    return this.http.post(`delivery/update-project-key-results/`, payload, { observe: 'response' });
  }
  getMappedProjectKeyresults(id:number){
    return this.http.get('delivery/get-mapped-project-key-results?project_id='+id);
  }
  
  getKeyresultsHistory(project_id:number,kr_mos_id:number){
    return this.http.get(`delivery/get-project-okr-history/${project_id}/${kr_mos_id}/`);
  }
  updateMetricsValue(payload: any){
    return this.http.post(`delivery/update-metrics-actual-value/`, payload);
  }  
  mapProjectKeyresults(payload: any){
    return this.http.post(`delivery/map-project-key-results/`, payload);
  }
  mapProjectToE(payload: any){
    return this.http.post(`toe/`, payload);
  }
  updateProjectToE(payload: any,uuid:string){
    return this.http.post(`toe/update-project-toe/${uuid}/`, payload);
  }
  getOpportunityData(query?: any) {
    if(query == 'delivery_risk_management'){
      return this.http.get(`project-dynamic-form/?form_name=${query}`);
    }else{
      return this.http.get(`dynamic-form/?form_name=${query}`);
    }
  }

  getOpportunityKeyResultsData(query?: any) {
   
      return this.http.get(`dynamic-form/get-opportunity-key-results/?form_name=${query}`);
    
  }
  getProjectKeyResultsData(){
    return this.http.get(`dynamic-form/get-key-results`);

  }

  getProjectKrData(projectId?:any){
    return this.http.get(`delivery/project/${projectId}/projectgoals/`);

  }


  getOpportunityRiskData(query?: any,projectId?:any) {
   
    return this.http.get(`delivery/get-project-risk/?form_name=${query}&project_id=${projectId}`);
  
}


getDomainList() {
   return this.http.get(`delivery/get-risk-domain/`);
}
getSubDomainList(domainId?:any) {
   return this.http.get(`delivery/get-risk-subdomain/?risk_domain=${domainId}`);
}
getSubDomainRiskList(subDomainId?:any) {
    return this.http.get(`delivery/get-risk/?risk_subdomain=${subDomainId}`);
}


getRiskImpact(){
  return this.http.get(`delivery/get-risk-impact/`);


}
getRiskLikelyhood(){
  return this.http.get(`delivery/get-risk-likelihood/`);

}
getRiskOwners(){
  return this.http.get(`delivery/get-risk-owners/`);

}
getProjectMappedRisk(projectId:any){
  return this.http.get(`delivery/get-project-mapped-risks/?project_id=${projectId}`);

}
























  getRiskMitigations(riskId?: any) {
    return this.http.get(`delivery/get_risk_mitigation/${riskId}`);
    
  }
  getProjectRiskMitigations(riskId?: any) {
   return this.http.get(`delivery/get_project_risk_mitigation/${riskId}`);
    
  }
  updateProjectRiskMitigations(riskId: any,data:any) {
    return this.http.post(`delivery/update_project_risk_mitigation/${riskId}/`,data);
     
   }

  getProjectRiskHeatMap(projectId?:any){
    return this.http.get(`delivery/get-project-risk-domain/?id=${projectId}`);
 
  }


  projectList(offset: number = 0, end?: any, subPortId?: any, searchText?: any, portId?: any, status?: any, order_by?: any) {
    let params = `offset=${offset}&end=${end}&order_by=${order_by}`;
    if (portId) {
      params = `${params}&portfolio=${portId}`
    } if (subPortId) {
      params = `${params}&portfolio=${subPortId}`
    } if (status) {
      params = `${params}&status=${status}`
    }if (searchText) {
      params = `${params}&search_text=${searchText}`
    }

    /* } if (order_by) {
      params = `order_by=${order_by}`
    } */
    console.log('params', params);
    return this.http.get(`delivery/?${params}`);
  }

  searchProjectList(projectName:any,projectId:any,programName:any,vendor:any,pStatus:any) {
    let params = ``;
    if (projectName) {
      params = `${params}project_name=${projectName}&`
    } if (projectId) {
      params = `${params}project_id=${projectId}&`
    } if (programName) {
      params = `${params}program=${programName}&`
    }if (vendor) {
      params = `${params}vendor=${vendor}&`
    }
    if (pStatus) {
      params = `${params}status=${pStatus}&`
    }
    params= params.substring(0,params.length-1);

    console.log('params', params);
    return this.http.get(`delivery/project-search/?${params}`);
  }



  deleteProject(id: any) {
    return this.http.delete('delivery/delete-project/' + id);
  }

  getprojectdetailsById(id: any) {
    const detailKey = makeStateKey(`delivery/get-project/?id=${id}`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get(`delivery/get-project/?id=${id}`).pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }
  getprojectdetailsOverview(id: any) {
    let elem:any = localStorage.getItem('program');
    elem = JSON.parse(elem);
    const detailKey = makeStateKey(`delivery/project-overview/?project_id=${id}&program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`);
    const cachedResponse = this.transferState.get(detailKey, null);
    if (!cachedResponse) {
      return this.http.get(`delivery/project-overview/?project_id=${id}&program_id=${elem.program_id}&subportfolio_id=${elem.subportfolio_id}&portfolio_id=${elem.portfolio_id}`).pipe(
        tap((res:any) => this.transferState.set(detailKey, res))
      )
    }
    return of(cachedResponse);
  }


  getToEData(id: any){
    return this.http.get(`toe?project_id=${id}`);
  }
  getToECategory(){
    return this.http.get(`toe/get-term-category/`);
  }

  getMappedProjectToEs(id:number){
    return this.http.get(`toe?project_id=${id}&only_mapped=true`);
  }
  getprojectDataById(id: any) {
    const detailKey = makeStateKey(`delivery/get-project/?id=${id}`);
    return this.http.get(`delivery/get-project/?id=${id}`).pipe(
      tap((res:any) => this.transferState.set(detailKey, res))
    );
  }
  updateProjectVendorDetails(payload:any){
    return this.http.post(`delivery/ppm-contract/`,payload);
  }
  updateProjectDetails(payload:any,id:any){
    return this.http.put(`delivery/${id}/`,payload);
  }

  uploadVendorContract(payload:any,id:any){
    return this.http.post(`delivery/sow-contract-upload/${id}/`,payload);
  }

  updateProjectDetailsNew(payload:any,id:any){
    return this.http.put(`delivery/update-project-data/${id}/`,payload);
  }
  getProjectSowContract(id:any){
    return this.http.get(`delivery/get-sow-project-file/${id}/`);
  }
  getResourceListOfSowContract(payload:any){
    const params = new HttpParams().set('project_id', payload?.project_id)
    .set('subportfolio_name', payload?.subportfolio_name)
    .set('portfolio_name', payload?.portfolio_name)
    .set('resource_pool_name',payload?.program_name)
    .set('vendor',payload?.vendor);
    return this.http.get('delivery/workforce/', {params});
  }
  deleteSowContractFile(id:any,payload:any){
    return this.http.post(`delivery/delete-sow-project-file/${id}/`,payload);
  }
  getProjectResourceChart(id:any,month:any){
    return this.http.get('delivery/get-resource-chart/?id='+id+"&month="+month);
  }
  addRightPaneData(payload:any,id:any){
    return this.http.put('delivery/edit-resource-right-pane-data/'+id+"/",payload);
  }
  // getResourceData(offset: number = 0, end: any,id:any){
  //   return this.http.get(`delivery/get-resource-data/?project=${id}&offset=${offset}&end=${end}`);
  // }
  getResourceRightData(id:any){
    return this.http.get('delivery/get-resource-data?id='+id);
  }
  bindEditId(data: any) {
    this.editId = data;
    console.log('check edit id recive', this.editId);

  }
  getProjectEditId() {
    return this.editId;
  }


  getMetrics(id: any) {
    let params = '';
    if(id)
      params = '?balance_score_card=' + id;

    /*     return this.http.get('metrics/metric/metric-list/'); */
    return this.http.get('metrics/metric/metric-list/'+params);
  }

  getUnitData() {
    const statusKey = makeStateKey('metrics/unit');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('metrics/unit/').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getselectedMetric(projectid: any, metricid: any) {
    let params = '';
    if(metricid)
      params = '&balance_score_card_id=' + metricid;
    return this.http.get('/delivery/project-metrics-list/?id=' + projectid + params );
  }


  addmetrics(id: any) {
    return this.http.get('metrics/metric/get-metric/?id=' + id);
  }

  getfrequencyList() {
    const statusKey = makeStateKey('metrics/frequency');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('metrics/frequency/').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }


  getMetricsListDatas() {
    return (metricsListDatas);
  }

  updateMetrics(data: any, projectId: any, metric_cat_id: any) {
    console.log('data service', data);
    return this.http.post('delivery/edit-metrics/' + projectId + '/' + metric_cat_id + '/', data);
  }

  /* 1/delivery/edit-metrics/298/1/ */


  createCustomScopeParameter(payload: any) {
    return this.http.post(`opportunity/create-custom-scopeparameter/`, payload);
  }


  /* Score Card Data  */

  scoreCardData(id: any) {
    // return this.http.get('/delivery/metric-categories-list/')
    return this.http.get('delivery/project-metrics-categorized-list/?id=' + id);
  }

  getscoreCardQuadrant(projectId:any,portofolio_id:any,subportfolio_id:any,program_id:any,vendor_id:any) {
    console.log(projectId);
    let qprm = '';
    if(projectId){
      qprm = "project_id="+projectId;
    }else if(portofolio_id){
      qprm = "portfolio_id="+portofolio_id;
    }else if(subportfolio_id){
      qprm = "portfolio_id="+subportfolio_id;
    }else if(program_id){
      qprm = "program_id="+program_id;
    }else if (vendor_id){
      qprm = "vendor_id="+vendor_id;
    }
    return this.http.get(`metrics/metric-value/?${qprm}`);
  }
  saveSortedQuadrant(data:any){
    return this.http.post(`metrics/sorting/`, data);
  }

  scoreCardSetupData(projectId: any, category_id: any) {
    return this.http.get('delivery/project-metrics-list/?id=' + projectId + '&metric_category_id=' + category_id);
  }

  /* http://tenant1.kepler.easyngo.com/server/v1/delivery/project-metrics-list/?id=517&metric_category_id=2 */

  /* Data Credentials screen API  */

  datacredientials(id: any) {
    return this.http.get('delivery/project-data-credentials/?id=' + id);
  }

  addCredientials(data: any) {
    return this.http.post(`delivery/create-data-credentials/`, data);
  }

  updateCredientials(data: any) {
    return this.http.post('delivery/update-data-credentials/', data);
  }

  getcredientialsById(id: any) {
    return this.http.get('delivery/get-data-credentials/?id=' + id)
  }

  getsourceTypeData() {
    return this.http.get('delivery/sourcetype-data-credentials-list/');
  }

  deleteCredientials(id: any) {
    return this.http.delete(`delivery/delete-data-credentials/` + id)
  }

  getSsoUsers() {
    const statusKey = makeStateKey('users/');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('users/').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  /*---Okr Comments service calls----*/

  createComment(id: number, payload: any) {
    return this.http.post(`delivery/add-update-comments-to-project-key-results/${id}/`, payload, { observe: 'response' });
  }

  deleteComment(id: number) {
    return this.http.delete(`delivery/delete-project-key-results-comment/${id}/`, { observe: 'response' });
  }

  /*------------------Risk Log--------------*/
  saveRiskLog(payload: any) {
    return this.http.post(`delivery/update-project-risks/`, payload, { observe: 'response' });
  }
  updateRiskHistory(payload: any) {
    return this.http.post(`delivery/update-risk-history/`, payload, { observe: 'response' });
  }
  getRiskHistory(project_risks_id:any){    
    return this.http.get(`delivery/get-project-risk-history/${project_risks_id}/`);
  }





  /* -------------------- Project Release Management -------------------- */



  getProjectReleaseList(offset: number = 0, end?: any, project_id?: any) {
    let params = `offset=${offset}&end=${end}&project_id=${project_id}`;

    return this.http.get(`delivery/get-project-release-list/?${params}`);
  }
  getProjectCRList(project_id?: any) {
    return this.http.get(`delivery/project/${project_id}/changerequest/`);
  }
  

  getProjectReleaseStatusList() {
    const statusKey = makeStateKey('delivery/project-release-status-list/');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('delivery/project-release-status-list/').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }
  getProjectCRStatusList() {
    const statusKey = makeStateKey('delivery/project-change-request-status-list/');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('delivery/project-change-request-status-list/').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  getProjectValueStatusList() {
    const statusKey = makeStateKey('delivery/get-valuelist?type=delivery_cr_state');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('delivery/get-valuelist?type=delivery_cr_state').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }
  updateProjectReleaseStatusList(projectId: any, releaseId: any) {

    return this.http.post('delivery/update-data-credentials/' + projectId + '/', releaseId);
  }

  submitReleaseForm(id: any, data: any) {
    return this.http.post('delivery/create-project-release/' + id + '/', data);
  }
  updateReleaseForm(id: any, releaseId: any, data: any) {
    return this.http.post('delivery/update-project-release/' + id + '/' + releaseId + '/', data);
  }

  getGoalsList(id:any) {
    return this.http.get('delivery/project/'+id+'/release/');
  }
  getGoalsForProject(id:any) {
    return this.http.get('delivery/project/'+id+'/projectgoals');
  }

  saveReleaseForProject(payload:any,id:any) {
    return this.http.post('delivery/project/'+id+'/update-releases/',payload);
  }

  deleteReleaseForProject(id:any) {
    return this.http.delete('delivery/'+id+'/'+'release/');
  }

  getTrainingProjectGoals(id:any) {
    return this.http.get('delivery/project/'+id+'/training/');
  }

  saveProjectGoalTrainings(payload:any,id:any){
    return this.http.post('delivery/project/'+id+'/update-training/',payload);
  }

  updateReleaseStatus( data: any) {
    return this.http.post('delivery/update-project-release-status/' , data);
  }
  submitCRForm(id: any, data: any) {
    return this.http.post('delivery/project/' + id + '/update-changerequest/', data);
  }
  updateCRForm(id: any, releaseId: any, data: any) {
    return this.http.post('delivery/update-change-request/' + id + '/' + releaseId + '/', data);
  }
  
  updateCRStatus( data: any) {
    return this.http.post('delivery/update-project-change-request-status/' , data);
  }
  
  downloadCRDocument(projectId:any,id: any,payload:any) {
    return this.http.post('delivery/change-request-file-download/'+projectId+"/" + id + '/',payload, {responseType: 'blob'});
  }
  getRiskStatus() {
    const statusKey = makeStateKey('delivery/project-risk-status-list/');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get('delivery/project-risk-status-list/').pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }
  getProjectStatus(id:number){
    return this.http.get('delivery/project-status-list/'+id+'/');
  }
  updateProjectStatus( data: any) {
    return this.http.post('delivery/update-project-status/' , data);
  }
  getRiskHeatmapData(project_id:number){
    return this.http.get(`delivery/get-project-risk-heat-map/${project_id}/`);
  }
  getRiskRadarData(project_id:number){
    return this.http.get(`delivery/risk-spider-map/${project_id}/`);
  }

  deleteProjectOkr(project_id:number, key_result_id:number, child_key_result_id:number){
    return this.http.delete(`delivery/delete-project-key-results/?project_id=${project_id}&key_result_id=${key_result_id}&child_key_result_id=${child_key_result_id}`);
  }
  getPortfolioList() {
    const dataKey = makeStateKey('get-all-portfolio');
    const cachedResponse = this.transferState.get(dataKey, null);
    if (!cachedResponse) {
      return this.http.get(`dynamic-form/get-all-portfolio/`).pipe(
        tap((res:any) => this.transferState.set(dataKey, res))
      )
    }
    return of(cachedResponse);
  }

  getAllPortfolioList(queryParams: any = ''){
    return this.http.get(`delivery/portfolio/`+queryParams).toPromise();
  }

  getSubPortfolioList(payload: any, queryParams: any = ''){
    return this.http.get(`delivery/sub-portfolio/?portfolio_id=${payload.id}` + queryParams).toPromise();
  }

  getPortfolioPrograms(payload: any, queryParams: any = ''){
    return this.http.get(`delivery/sub-porfolio-program/?sub_portfolio_id=${payload.id}` + queryParams).toPromise();
  }

  getOpportunityList(payload: any, queryParams: any = ''){
    return this.http.get(`delivery/program-project/?program_id=${payload.id}&sub_portfolio_id=${payload?.parent?.id}` + queryParams).toPromise();
  }

  selectProjectGoals(payload: any){
    return this.http.post(`delivery/select-projectgoals/`, payload);
  }

  getProjectRiskData(projectID: any){
    return this.http.get(`delivery/project/${projectID}/gettrisks/`);
  }

  selectProjectRisk(payload: any){
    return this.http.post(`delivery/select-risks/`, payload);
  }

  getFilteredList(payload: any, body: any){    
    // Object.keys(queryParam).forEach(key=>
    //   queryParam[key] = queryParam[key][0]
    //   )    
    // return this.http.get(`delivery/project-filter/?${Object.keys(queryParam)[0]}=${Object.values(queryParam)[0]}`, body).toPromise();
    return this.http.post('delivery/project-filter/',payload).toPromise()
  }

  getGoalMetrics(projectID: any, goalID: any){
    return this.http.get(`delivery/get-goal-metrics/?project_id=${projectID}&goal_id=${goalID}`);
  }

  setGoalMetrics(payload: any){
    return this.http.post(`delivery/goal-metrics/`, payload);
  }

  getRiskMetrics(projectID: any, goalID: any){
    return this.http.get(`delivery/get-goal-metrics/?project_id=${projectID}&goal_id=${goalID}`);
  }

  setRiskResponse(queryParam: any, payload: any){
    return this.http.put(`delivery/update-risk-response/`+queryParam, payload);
  }

  getRiskProjectMapped(projectID: any, riskId: any){
    return this.http.get(`delivery/project-risk/?project_id=${projectID}&risk_id=${riskId}`);
  }


  deleteProjectById(item: any){
    return this.http.delete(`delivery/delete-project/${item}/`).toPromise();
  }

  archiveProjecById(item: any){
    return this.http.put(`delivery/archive-project/${item}/`, []).toPromise();
  }

  getArchiveList(portfolio: any, subportfolio: any, program: any){
    return this.http.get(`delivery/list-archive-project?portfolio=${portfolio}&sub_portfolio=${subportfolio}&program=${program}`).toPromise();
  }

  unarchiveProj(payload: any){
    return this.http.put(`delivery/unarchive-project/`, payload).toPromise();
  }


  getAllSOWContractList(){
    return this.http.get(`delivery/contract/`);
  }

  getSpendTracker(projectId:any){
    return this.http.get(`delivery/spend/?project_id=${projectId}`)
  }

  updateSpendTracker(payload:any){
    let spends= {spends:payload};
    return this.http.post(`delivery/spend/`,spends);
  }

  getLinkedMatricsData(metricId:any,projectId:any,portofolio_id:any,subportfolio_id:any,program_id:any){
    let qprm = '';
    if(projectId){
      qprm = `independent_metric=${metricId}&project_id=${projectId}`;
    }else if(portofolio_id){
      qprm = `independent_metric=${metricId}&portfolio_id=${portofolio_id}`;
    }else if(subportfolio_id){
      qprm = `independent_metric=${metricId}&portfolio_id=${subportfolio_id}`;
    }else if(program_id){
      qprm = `independent_metric=${metricId}&program_id=${program_id}`;
    }else{
      qprm = `independent_metric=${metricId}`;
    }
    return this.http.get(`metrics/linked-matrix/?${qprm}`);
  }

  getPerformanceBenchMarkData(metricId:any,projectId:any,portofolio_id:any,subportfolio_id:any,program_id:any){
    let qprm = '';
    if(projectId){
      qprm = `metric_id=${metricId}&project_id=${projectId}`;
    }else if(portofolio_id){
      qprm = `metric_id=${metricId}&portfolio_id=${portofolio_id}`;
    }else if(subportfolio_id){
      qprm = `metric_id=${metricId}&subportfolio_id=${subportfolio_id}`;
    }else if(program_id){
      qprm = `metric_id=${metricId}&program_id=${program_id}`;
    }else{
      qprm = `metric_id=${metricId}`;
    }
    return this.http.get(`metrics/benchmark/?${qprm}`)
  }

  quadrantIfo(quadrant_id:any){
    return this.http.get(`/metrics/instance-metric/?phase_id=${quadrant_id}`);
  }

  getProjectType(){
    return this.http.get(`base_value/?type=project_type`);
  }
  getProjectFundingType(){
    return this.http.get(`base_value/?type=project_funding_type`);
  }

  addNewProject(payload:any){
    return this.http.post('delivery/project/',payload);
  }

  getProjectToeData(projectId: any){
    return this.http.get(`terms/category/?project_id=${projectId}`);
  }

  getProjectTerms(projectId: any){
    return this.http.get(`delivery/project-terms/?project_id=${projectId}`);
  }

  selectProjectToe(payload: any){
    return this.http.post(`delivery/project-terms/`, payload);
  }
} 


