<div class="table" style="margin-bottom: 0px">
    <div class="table-row" style="position: sticky; top: 0px; z-index: 3; background: white;">
        <div class="table-cell cursor-pointer domain fw-bold"
            style="max-width: 150px;min-width: 150px;width:150px;text-align: left;font-size:14px; background: white; position: sticky; top: 0;left:0; z-index: 3;">
            <div style="background-color: #0bb797; border-radius: 10px;"> Domain </div>

        </div>
        <div class="table-cell table-nest">
            <div class="table-row" style="position: relative; width: 100%; position: sticky; top: 0; left: 150px; z-index: 3;">
                <div class="table-cell cursor-pointer subdomain"
                    style="max-width: 150px;min-width: 150px;text-align: left;font-size:14px; background-color: white; position: sticky; top: 0;left:150px; z-index: 3;">
                    <div style="background-color: #0bb797; border-radius: 10px; padding: 8px;"> Sub Domain </div>
                    </div>
                <div class="table-cell"
                    style="width: 150px; position: relative; padding: 5px; cursor: pointer; background-color: white; color: white; position: sticky; top: 0; left: 0; z-index: 2;">
                    <span style="position: sticky;font-size: 14px;text-align: left;
                        left: 50%; transform: translate(-50%, -50%); width: 90%; "
                        > <div style="background-color: #0bb797; border-radius: 10px; padding: 8px;"> Risk </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="riskCenterList" style="overflow: scroll; height: calc(100vh - 425px);">
    <div class="container-fluid p-0">

        <div class="table">
            
            <div class="table-row" *ngFor="let domain of getFilteredArray(riskArrayHeatMap);let subindex=index;">
                <!-- <div class="table-cell cursor-pointer fw-bold"
                    style="font-size:13px;position: sticky;left:0;z-index:2; height: 0 ;max-width: 150px !important; padding-right: 0px;">
                    <div  class="domain" style="border-radius: 10px; height: 100%; max-width: 140px !important;">{{domain?.display_name }} </div>

                </div> -->
                <div class="table-cell cursor-pointer fw-bold" 
                                        style="max-width: 150px;min-width: 150px;width: 150px;font-size:14px;position: sticky;left:0;z-index: 1; height: 0">
                                        <div  class="domain" style="border-radius: 10px; height: 100%">{{domain?.display_name }} </div>
                                    </div>
                <div class="table-cell table-nest" *ngIf="domain.children">
                    <div class="table-row" style="position: relative;"
                        *ngFor="let subdomain of getFilteredArray(domain?.children); let subsubindex=index;">
                        <div class="table-cell cursor-pointer" id="subdomain_{{subsubindex}}"
                            style="max-width: 150px;min-width: 150px;font-size:13px;position: sticky;left:150px;z-index:2;height:0">
                            <div  class="subdomain" style="border-radius: 10px;padding: 8px; height: 100%"  [matTooltip]="subdomain?.display_name | titlecase">{{subdomain?.display_name}} </div></div>
                        <div class="table-cell"
                            style="max-width: 150px;min-width: 150px;position: relative; cursor: pointer;"
                            (click)="onClickRisk(domain, subdomain, risk, riskLogPop)"
                            *ngFor="let risk of getFilteredArray(subdomain?.children);let risksubindex=index;">
                            <span id="risksubdomain_{{subsubindex}}_{{risksubindex}}" style="position: absolute;font-size: 13px;text-align: center;top: 50%;
                                left: 50%; transform: translate(-50%, -50%); width: 90%; height: 100%; padding: 5px 0;" >
                                    <div [ngStyle]="{'backgroundColor': selected == risk.id ? '#86da64' : '#c2bec7'}" [matTooltip]="risk?.short_description | titlecase"
                                    style="border-radius: 10px; padding: 8px; height: 100%; display: flex; align-items: center; justify-content: center;">{{getTrucatedName(subdomain?.display_name,risk?.short_description)}} </div>
                                </span>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #riskLogPop>

    <div class="modal-dialog  m-0" style="max-width: 100%;">
        <div class="modal-content" style="border: none;">

            <app-modal-header-common [title]="'Risk Update'"> </app-modal-header-common>
            
            <div class="modal-body p-0">
                <div class="saw">
                    <div class="d-flex mb-2" style="align-items: center;">
                        <span class="sjec obj">Domain: </span>

                        <p class="mb-0">{{riskMetric.domain}}</p>
                    </div>
                    <div class="d-flex mb-2" style="align-items: center;">
                        <span class="sjec krq">Subdomain: </span>

                        <p class="mb-0">{{riskMetric.subdomain}}</p>
                    </div>
                    <div class="d-flex mb-2" style="align-items: center;">
                        <span class="sjec gol">Risk: </span>

                        <p class="mb-0">{{riskMetric.risk}}</p>
                    </div>

                    <div class="d-flex mb-2" style="align-items: center;">
                        <span class="sjec gol">Risk Response: </span>

                        <mat-select class="fw-500" [(ngModel)]="riskMetric.risk_response" class="msel">
                            <mat-option value="Accept">Accept</mat-option>
                            <mat-option value="Mitigate">Mitigate</mat-option>
                            <mat-option value="Transfer">Transfer</mat-option>
                        </mat-select>
                    </div>

                </div>
                <form [formGroup]="riskMetricAcceptCreateForm" style="width: 100%;" *ngIf="riskMetric.risk_response == 'Accept'">
                    <div style="width: 100%; padding: 0px 20px;">
                        <h6>Comments</h6>
                        <mat-form-field appearance="outline" class="w-100 py-1">
                            <mat-label class="label-design">
                            </mat-label>
                            <input matInput class="fw-500"
                            formControlName="comments" fullWidth
                                status="basic" name="comments">
                        </mat-form-field>
                    </div>
                </form>
                <form [formGroup]="riskMetricTranferCreateForm" style="width: 100%;" *ngIf="riskMetric.risk_response == 'Transfer'">
                    <div style="width: 100%; padding: 0px 20px;">
                        <h6>Transfer to</h6>
                        <mat-form-field appearance="outline" class="py-1">
                            <mat-label class="label-design">
                            </mat-label>
                            <mat-select class="fw-500" formControlName="transfer_to">
                                <mat-option [value]="owner.mail" *ngFor="let owner of riskOwners">{{owner.name}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <h6>Comments</h6>
                        <mat-form-field appearance="outline" class="w-100 py-1">
                            <mat-label class="label-design">
                            </mat-label>
                            <input matInput class="fw-500"
                            formControlName="comments" fullWidth
                                status="basic" name="comments">
                        </mat-form-field>
                    </div>
                </form>
                <form [formGroup]="riskMetricCreateForm" style="width: 100%;" *ngIf="riskMetric.risk_response == 'Mitigate'">
                    <div class="custom-table table-responsive">
                        <table class="table table-bordered">
                            <thead style="position: sticky;top: 0; z-index: 1">
                                <tr>
                                    <th class="fixed-header" scope="col">#</th>
                                    <th class="fixed-header" scope="col">Mitigation Task</th>
                                    <th class="fixed-header w-10" scope="col">Owner</th>
                                    <th class="fixed-header" scope="col">Due date</th>
                                    <th class="fixed-header" scope="col">Status</th>
                                    <th class="fixed-header" scope="col">Comments</th>
                                    <th class="fixed-header text-center w-10" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let m of riskMetricCreateForm.controls; let i = index" [formGroup]="m"> 
                                    <td class="align-middle">{{i + 1}}</td>
                                    <td class="align-middle"> 
                                        <mat-form-field appearance="outline" class="py-1">
                                            <mat-label class="label-design">
                                            </mat-label>
                                            <input matInput class="fw-500"
                                            formControlName="mitigation_task" fullWidth
                                                status="basic" name="mitigation_task">
                                        </mat-form-field>
                                    </td>
                                    <td class="align-middle"> 
                                        
                                        <mat-form-field appearance="outline" class="py-1">
                                            <mat-label class="label-design">
                                            </mat-label>
                                            <mat-select class="fw-500" formControlName="owner">
                                                <mat-option [value]="owner.mail" *ngFor="let owner of riskOwners">{{owner.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td class="align-middle w-10">
                                        <mat-form-field appearance="outline" class="py-1">
                                            <mat-label class="label-design">
                                            </mat-label>
                                            <input matInput
                                                [matDatepicker]="picker" readonly class="fw-500"
                                                formControlName="due_date" fullWidth dateFormat="yyyy-MM-dd"
                                                status="basic" (click)="picker.open()" name="due_date">
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker>
                                            </mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                    <td class="align-middle">
                                        <mat-form-field appearance="outline" class="py-1">
                                            <mat-label class="label-design">
                                            </mat-label>
                                            <input matInput class="fw-500"
                                            formControlName="status" fullWidth name="status">
                                        </mat-form-field>
                                    </td> 
                                    <td class="align-middle">
                                        <mat-form-field appearance="outline" class="py-1">
                                            <mat-label class="label-design">
                                            </mat-label>
                                            <input matInput class="fw-500"
                                            formControlName="comments" fullWidth name="comment">
                                        </mat-form-field>
                                    </td> 
                                    <td class="align-middle text-center w-10">
                                        <div style="display: flex; align-items: center; justify-content: space-around;">
                                            <span class="cursor-pointer" (click)="removeMetric(i)">
                                                <span class="cursor-pointer">
                                                    <img src="./assets/images/comment-delete.svg"
                                                        style="width:16px;">
                                                </span>
                                            </span>

                                            <span class="cursor-pointer" (click)="addMetricItems(i)"
                                            *ngIf="riskMetricCreateForm.controls.length - 1 === i">
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                    class="cursor-pointer fs-5" style="margin-top: 10px;">add
                                                </mat-icon>
                                            </span>
                                        </div>
                                    </td>  
                                </tr>
                                <tr *ngIf="riskMetricCreateForm?.controls?.length <= 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td class="align-middle w-25">
                                        <a class="add-btton"
                                            (click)="addMetricItems()">
                                            <mat-icon aria-hidden="false" aria-label="material-icons-filled"
                                                class="cursor-pointer fs-5">add
                                            </mat-icon>
                                            Add
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
                <div>
                    <button mat-button class="saveOkr text-white text-capitalize float-right mr-3 mt-2" (click)="saveMetric()" *ngIf="riskMetric.risk_response">Save</button>
                </div>

            </div>
        </div>
    </div>
</ng-template>