<div *ngIf="!iframeEmbedded">
<nav class="navbar navbar-expand-lg navbar-dark bg-lavendar py-0 justify-content-between fixed-top" >
    <div class="w-100 d-flex nav-lg-holder " >
        <div class="navbar-brand mr-1">
            <span class="pr-2 d-inline-block">
                <a href="https://www.kairhos.com/" target="_blank">
                    <img src="assets/images/logo.svg" class="img-header cursor-pointer" width="100" />
                </a>
            </span>
            <span class="pl-2 border-left-nav d-inline-block">
                <a href="/" class="text-decoration-none">
                    <img src="assets/images/icon.png" class="keplerLogo" />
                </a>
            </span>
        </div>
        <div>
            <div class="d-flex align-items-centered" style="height:40px">
                <div class="nav-item mt-2 mr-3 d-none d-sm-block d-lg-none display-xl-block" matTooltip="Project Central" matTooltip="Idea pitch Login" matTooltipClass="example-tooltip" (click)="openBlank(ideapitchUrl)">
                    <mat-icon>swap_horiz</mat-icon>
                </div>
                <div class="nav-item mt-2 mr-3 d-none d-sm-block d-lg-none display-xl-block" matTooltip="Project Central" matTooltipClass="example-tooltip" (click)="navigateTo('delivery/projects')">
                    <img src="assets/Icons/free_icon_1.svg" alt="image" width="20px">
                </div>
                <div class="nav-item mt-2 mr-3  d-none d-sm-block d-lg-none display-xl-block" matTooltip="IPDB" matTooltipClass="example-tooltip" (click)="navigateTo('prism/ipdb')">
                    <img src="assets/Icons/ipdb.svg" width="15px" alt="image">
                </div>

                <!-- <div class="nav-item mt-2 mr-3 d-none d-sm-block d-lg-none display-xl-block" matTooltip="Scrape.AI" (click)="navigateTo('prism/scrapeai')">
                    <a [matMenuTriggerFor]="comingSoonTips" class="d-flex justify-content-between align-items-center">
                        <img style="width: 13px;" src="./assets/images/lightbulb 1.svg" class="action-tracker-ico tips-size">
                    </a>
                    <mat-menu #comingSoonTips="matMenu" xPosition="before" >
                        <p class="p-4 m-0">AI-Backed Contextual Tips Coming Soon!</p>
                    </mat-menu>
                </div> -->
                <div class="nav-item mt-2 mr-3 d-none d-sm-block d-lg-none display-xl-block" matTooltip="Action Central" matTooltipClass="example-tooltip">
                    <img src="./assets/images/action-tracker.svg" class="action-tracker-ico"  (click)="navigateTo('actiontracker')">
                </div>
                <div class="nav-item mt-2 mr-3  d-none d-sm-block d-lg-none display-xl-block" matTooltip="GRC Workbench" matTooltipClass="example-tooltip" >
                    <mat-icon style="color: rgb(169, 225, 206);" (click)="navigateTo('incidentracker')">track_changes</mat-icon>
                </div>
                <div class="nav-item mt-2 mr-3 d-none d-sm-block d-lg-none display-xl-block" [matMenuTriggerFor]="comingSoonSearch" matTooltip="Airo" matTooltipClass="example-tooltip">
                    <img src="./assets/images/airo.png" class="action-tracker-ico">
                </div>
                <!-- <div class="nav-item mt-2 mr-3 d-none d-sm-block  d-lg-none display-xl-block"> 
                    <mat-icon aria-hidden="false" aria-label="material-icons-filled" [matMenuTriggerFor]="comingSoonSearch" class="cursor-pointer">
                        search
                    </mat-icon> -->
                    <mat-menu #comingSoonSearch="matMenu" xPosition="before" (click)="$event.stopPropagation();" style="z-index:9999; margin-top: 10px;">
                        <app-chat-bot-window (click)="$event.stopPropagation();"></app-chat-bot-window>
                    </mat-menu>
                <!-- </div> -->
                <!-- (click)="showNotifications=!showNotifications" -->
                <div class="nav-item mt-2 mr-2 d-lg-none display-xl-block"  *ngIf="authenticated">
                    <span class="p-1 notification-Status  bg-danger border border-light rounded-circle" *ngIf="notficationsList?.length"></span>
                    <mat-icon aria-hidden="false" aria-label="material-icons-filled" class="cursor-pointer" id='notificationbell' [matMenuTriggerFor]="notificationmenu">
                        notifications
                    </mat-icon>
                </div>
                <button *ngIf="authenticated" class="navbar-toggler mr-2 d-md-block d-lg-none display-xl-block" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation" >
                    <span class="navbar-toggler-icon"></span>
                </button>
                <ul class="pl-0 d-lg-none display-xl-block">
                    <li class="nav-item dropdown d-flex flex-row align-items-center" (click)="showProfilePopUp=!showProfilePopUp" id="profile"  *ngIf="authenticated">
                        <ngx-avatar class="text-truncate"  matTooltip="{{user?.full_name}}" matTooltipClass="example-tooltip" [style]="customStyle" size="40"  [name]="user?.full_name">
                        </ngx-avatar>
                        <mat-icon aria-label="material-icons-filled " class="cursor-pointer fs-5 ml-2 mt-2">
                            expand_more
                        </mat-icon>
                    </li>
                </ul>
                <a class="btn d-flex p-0 d-md-block d-lg-none display-xl-block mt-1" style="align-items: center;" *ngIf="!authenticated" (click)="showLoginPopUp=!showLoginPopUp">
                    <img src="assets/images/logout_trans.png" class="cursor-pointer fs-4" alt="logout" width="30" height="30">
                </a> 
            </div>
        </div>
        <!--Desktop-->
        <div class="collapse navbar-collapse d-none d-sm-none d-md-none d-lg-block display-xl-none ml-4 hsace"  *ngIf="authenticated">
            <div class="d-flex" style="width: 100%" [ngClass]="{'justify-content-center': !user.is_vendor}">
                <ul class="navbar-nav d-flex align-items-center menu-nav" [ngStyle]="{'flex': !user.is_vendor ? 'auto' : ''}">
                    <li class="nav-item d-flex justify-content-between align-items-center border-left-nav">
                        <span class="nav-link" (click)="navigateToHome()">
                            <img src="assets/images/header/home.svg" alt="image" width="18" />
                        </span>
                    </li>
                    <li class="nav-item dropdown d-flex justify-content-between align-items-center" style="width: 100%; justify-content: center !important;">
                        <a href="#" class="nav-link dropdown-toggle d-flex justify-content-between align-items-center" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/header/newopportunities.svg" alt="image" class="mr-2" width="17">
                            <span [innerHTML]="staticText.menu.mainMenu1"></span>
                        </a>
                        <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown1">
                            <div class="row m-0">
                                <div class="col-md-7 bg-nav-menu image-1 d-flex align-items-center">
                                    <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                        {{staticText.menu.mainMenuBannerText1}}
                                    </h5>
                                </div>
                                <div class="col-md-5">
                                    <div class="py-4">
                                        <p class="fs-16 fw-500 mb-2 menu-over nohover cp" (click)="clickOpportunity()">
                                            {{staticText.menu.subTitleMenu1}}
                                        </p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2 cp" (click)="clickEngagement()" *ngIf="!this.user?.is_vendor">
                                            {{staticText.menu.subTitleMenu2}}
                                        </p>
                                        <p class="fs-16 fw-500 mb-2 menu-over nohover cp" (click)="navigateTo('challenge-workbench/challenge-statements')">
                                            {{staticText.menu.Hackathon}}
                                        </p>
                                        <p class="fs-16 fw-500 mb-2 menu-over nohover cp" (click)="navigateTo('ideapitch')" *ngIf="!this.user?.is_vendor">
                                            {{staticText.menu.Idea_Pitch}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown d-flex justify-content-between align-items-center" style="width: 100%; justify-content: center !important;" *ngIf="!this.user?.is_vendor">
                        <a href="#" class="nav-link dropdown-toggle d-flex justify-content-between align-items-center" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/header/insights.svg" alt="image" class="mr-2" width="17">
                            <span [innerHTML]="staticText.menu.mainMenu2"></span>
                        </a>
                        <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown2">
                            <div class="row m-0">
                                <div class="col-md-7 bg-nav-menu image-2 d-flex align-items-center">
                                    <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                        {{staticText.menu.mainMenuBannerText2}}
                                    </h5>
                                </div>
                                <div class="col-md-5 pr-0">
                                    <div class="py-4">
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('balancedscorecard/dashboard')">
                                            {{staticText.menu.subTitleMenu3}}
                                        </p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('riskconvergence')">
                                            {{staticText.menu.subTitleMenu4}}
                                        </p>
                                        <!-- <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('toe-master')">
                                            {{staticText.menu.subTitleMenu11}}
                                        </p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('BVT/list')">
                                            {{staticText.menu.subTitleMenu5}}
                                        </p> -->
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('workforce')">
                                            {{staticText.menu.people_insights}}
                                        </p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('performanceInsights/hubble')">
                                            {{staticText.menu.qualitative_insights}}
                                        </p>
                                        <!-- <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('vendor/list')">
                                            Vendor
                                        </p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown d-flex justify-content-between align-items-center" style="width: 100%; justify-content: center !important;" *ngIf="!this.user?.is_vendor">
                        <a href="#" class="nav-link dropdown-toggle d-flex justify-content-between align-items-center" id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/header/strategicrelevance.svg" alt="image" class="mr-2" width="17">
                            <span [innerHTML]="staticText.menu.mainMenu3"></span>
                        </a>
                        <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown3" style="min-width: 430px;">
                            <div class="row m-0">
                                <div class="col-md-7 bg-nav-menu image-3 d-flex align-items-center">
                                    <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                        {{staticText.menu.mainMenuBannerText3}}
                                    </h5>
                                </div>
                                <div class="col-md-5">
                                    <div class="py-4">
                                        <p class="fs-16 fw-500 cursor-pointer mb-2"
                                            (click)="navigateTo('okrconvergence')">{{staticText.menu.subTitleMenu7}}</p>
                                            <p class="fs-16 fw-500 cursor-pointer mb-2"
                                            (click)="navigateTo('strategicRelevance/assessment-central')">{{staticText.menu.it_north_star}}</p>
                                            <p class="fs-16 fw-500 cursor-pointer mb-2"
                                            (click)="navigateTo('strategicRelevance/governancect')">{{staticText.menu.governance_CT}}</p>
                                            <p class="fs-16 fw-500 cursor-pointer mb-2"
                                            (click)="navigateTo('strategicRelevance/maven')">{{staticText.menu.maven}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="nav-item dropdown d-flex align-items-center" 
                    style="width: 100%;" [ngClass]="{'justify-content-center' : !user?.is_vendor }">
                        <a href="#" class="nav-link dropdown-toggle d-flex justify-content-between align-items-center" id="navbarDropdown4" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src="assets/images/header/learn.svg" alt="image" class="mr-2">
                            <span [innerHTML]="staticText.menu.mainMenu4"></span>
                        </a>
                        <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown4" style="min-width: 430px;">
                            <div class="row m-0">
                                <div class="col-md-6 bg-nav-menu image-4 d-flex align-items-center">
                                    <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                        {{staticText.menu.mainMenuBannerText4}}
                                    </h5>
                                </div>
                                <div class="col-md-6">
                                    <div class="py-4">
                                       <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;" (click)="navigateTo('delivery-accelerator/dashboard')" *ngIf="!this.user?.is_vendor">
                                            {{staticText.menu.subTitleMenu10}}
                                        </p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;" (click)="navigateTo('confluence/teamplantracker')" *ngIf="!this.user?.is_vendor">
                                            {{staticText.menu.team_plan_tracker}}
                                        </p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;" (click)="navigateTo('parade')" *ngIf="!this.user?.is_vendor">
                                            {{staticText.menu.intractive_reviews}}
                                        </p>
                                       
                                        <p class="fs-16 fw-500 cursor-pointer mb-2 " style="white-space: nowrap;" (click)="navigateToSP()">
                                            {{staticText.menu.subTitleMenu9}}
                                        </p>
                                        <!-- <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;" (click)="navigateTo('toemasterlist')">
                                            {{staticText.menu.key_terms}}
                                        </p> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="d-flex align-items-centered d-none d-sm-none d-md-none d-lg-none d-xl-block display-xl-none hsace" id="login">
            <ul class="navbar-nav d-flex align-items-center">
                <li class="nav-item" matTooltip="Project Central" matTooltip="Idea pitch Login" matTooltipClass="example-tooltip" (click)="openBlank(ideapitchUrl)" *ngIf="!this.user?.is_vendor">
                    <mat-icon>swap_horiz</mat-icon>
                </li>
                <li class="nav-item" matTooltip="Project Central" matTooltipClass="example-tooltip" (click)="navigateTo('delivery/projects')">
                    <img src="assets/Icons/free_icon_1.svg" alt="image" width="20px">
                </li>
                <li class="nav-item" matTooltip="IPDB" matTooltipClass="example-tooltip" (click)="navigateTo('prism/ipdb')" *ngIf="!this.user?.is_vendor">
                    <img src="assets/Icons/ipdb.svg" width="15px" alt="image">
                </li>
                <li class="nav-item" matTooltip="Action Central" matTooltipClass="example-tooltip" *ngIf="!this.user?.is_vendor">
                    <img src="./assets/images/action-tracker.svg" class="action-tracker-ico" style="margin-top: 5px;" (click)="navigateTo('actiontracker')">
                </li>
                <li class="nav-item" matTooltip="GRC Workbench" matTooltipClass="example-tooltip" (click)="navigateTo('incidentracker')">
                    <mat-icon style="color: rgb(169, 225, 206);">track_changes</mat-icon>
                </li>
                <li class="nav-item" matTooltip="Airo" [matMenuTriggerFor]="comingSoonSearch" matTooltipClass="example-tooltip" *ngIf="!this.user?.is_vendor">
                    <img src="./assets/images/airo.png" class="action-tracker-ico" style="margin-top: 5px;">

                    <mat-menu #comingSoonSearch="matMenu" xPosition="before" (click)="$event.stopPropagation();" class="margin-top: 10px;" style="z-index:9999">
                        <app-chat-bot-window (click)="$event.stopPropagation();"></app-chat-bot-window>
                    </mat-menu>
                </li>
                <li class="nav-item"  *ngIf="authenticated && !this.user?.is_vendor">
                    <span class="p-1 notification-Status  bg-danger border border-light rounded-circle" *ngIf="notficationsList?.length"></span>
                    <mat-icon aria-hidden="false" aria-label="material-icons-filled" class="cursor-pointer" id='notificationbell' [matMenuTriggerFor]="notificationmenu">
                        notifications
                    </mat-icon>
                </li>
                <li class="nav-item dropdown d-flex flex-row align-items-center" (click)="showProfilePopUp=!showProfilePopUp" id="profile" *ngIf="authenticated">
                    <ngx-avatar class="text-truncate"  matTooltip="{{user?.full_name}}" matTooltipClass="example-tooltip" [style]="customStyle" size="40"  [name]="user?.full_name ? user?.full_name : user?.email">
                    </ngx-avatar>
                    <mat-icon aria-label="material-icons-filled " class="cursor-pointer fs-5 ml-2 mt-2">
                        expand_more
                    </mat-icon>
                </li>
                <li>
                    <a class="btn d-flex p-0" style="align-items: center;" *ngIf="!authenticated" (click)="showLoginPopUp=!showLoginPopUp">
                        <img src="assets/images/logout_trans.png" class="cursor-pointer fs-4" alt="logout" width="30" height="30">
                    </a> 
                </li>
            </ul>
            
        </div>
    </div>
</nav>
<div class="collapse navbar-collapse " id="navbarSupportedContent">
    <div style="width:100%" class="d-block d-sm-block d-md-block d-lg-block">
        <ul class="list-group">
            <li><br><br></li>
            <li class="list-group-item list-item d-flex" (click)="navigateToHome()">
                <span>
                    <img src="assets/images/header/home.svg" alt="image" width="18" /> 
                </span> <div class="mt-1 ml-2">Home</div>
            </li>
            <li class="list-group-item list-item" *ngIf="authenticated">
                <span href="#" class="dropdown-toggle d-flex justify-content-between align-items-center"
                    id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <div>              
                        <img src="assets/images/header/newopportunities.svg" alt="image" class="mr-2" width="17">
                        <span [innerHTML]="staticText.menu.mainMenu1"></span>
                    </div>
                </span>
                <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown1">
                    <div class="row m-0">
                        <div class="col-md-7 bg-nav-menu image-1 d-flex align-items-center">
                            <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                {{staticText.menu.mainMenuBannerText1}}</h5>
                        </div>
                        <div class="col-md-5">
                            <div class="py-4">
                                <p class="fs-16 fw-500 mb-2 menu-over nohover cp" (click)="clickOpportunity()">{{staticText.menu.subTitleMenu1}}
                                </p>
                                <p class="fs-16 fw-500 cursor-pointer mb-2 cp" (click)="clickEngagement()">
                                    {{staticText.menu.subTitleMenu2}}</p>

                                    <p class="fs-16 fw-500 mb-2 menu-over nohover cp" (click)="navigateTo('challenge-workbench/challenge-statements')">{{staticText.menu.Hackathon}}
                                    </p>
                                    <p class="fs-16 fw-500 mb-2 menu-over nohover cp" (click)="navigateTo('ideapitch')">{{staticText.menu.Idea_Pitch}}
                                    </p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item list-item" *ngIf="authenticated">
                <span href="#" class="dropdown-toggle d-flex justify-content-between align-items-center"
                    id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <div>
                        <img src="assets/images/header/insights.svg" alt="image" class="mr-2" width="17">
                        <span [innerHTML]="staticText.menu.mainMenu2"></span>
                    </div>
                </span>
                <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown2">
                    <div class="row m-0">
                        <div class="col-md-7 bg-nav-menu image-2 d-flex align-items-center">
                            <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                {{staticText.menu.mainMenuBannerText2}}</h5>
                        </div>
                        <div class="col-md-5 pr-0">
                            <div class="py-4">
                                <p class="fs-16 fw-500 cursor-pointer mb-2"
                                    (click)="navigateTo('balancedscorecard/dashboard')">{{staticText.menu.subTitleMenu3}}
                                </p>
                                <p class="fs-16 fw-500 cursor-pointer mb-2"
                                    (click)="navigateTo('riskconvergence')">{{staticText.menu.subTitleMenu4}}
                                </p>
                                <!-- <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('toe-master')">
                                    {{staticText.menu.subTitleMenu11}}
                                </p>
                                <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('BVT/list')">
                                    {{staticText.menu.subTitleMenu5}}
                                </p> -->
                                <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('workforce')">
                                    {{staticText.menu.people_insights}}
                                </p>
                                <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('performanceInsights/hubble')">
                                    {{staticText.menu.qualitative_insights}}

                                </p>
                                <!-- <p class="fs-16 fw-500 cursor-pointer mb-2" (click)="navigateTo('vendor/list')">
                                    Vendor
                                </p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item list-item" *ngIf="authenticated">
                <span href="#" class="dropdown-toggle d-flex justify-content-between align-items-center"
                    id="navbarDropdown3" role="button" data-toggle="dropdown" aria-haspopup="true"
                    aria-expanded="false">
                    <div>
                        <img src="assets/images/header/strategicrelevance.svg" alt="image" class="mr-2" width="17">
                        <span [innerHTML]="staticText.menu.mainMenu3"></span>
                    </div>
                </span>
                <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown3" style="min-width: 430px;">
                    <div class="row m-0">
                        <div class="col-md-7 bg-nav-menu image-3 d-flex align-items-center">
                            <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                {{staticText.menu.mainMenuBannerText3}}</h5>
                        </div>
                        <div class="col-md-5">
                            <div class="py-4">
                                <p class="fs-16 fw-500 cursor-pointer mb-2"
                                    (click)="navigateTo('okrconvergence')">{{staticText.menu.subTitleMenu7}}</p>
                                    <p class="fs-16 fw-500 cursor-pointer mb-2"
                                    (click)="navigateTo('strategicRelevance/assessment-central')">{{staticText.menu.it_north_star}}</p>
                                    <p class="fs-16 fw-500 cursor-pointer mb-2"
                                    (click)="navigateTo('strategicRelevance/governancect')">{{staticText.menu.governance_CT}}</p>
                                    <p class="fs-16 fw-500 cursor-pointer mb-2"
                                    (click)="navigateTo('strategicRelevance/maven')">{{staticText.menu.maven}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item list-item" *ngIf="authenticated">
                    <span href="#" class="dropdown-toggle d-flex justify-content-between align-items-center"
                        id="navbarDropdown4" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <div>
                            <img src="assets/images/header/learn.svg" alt="image" class="mr-2">
                            <span [innerHTML]="staticText.menu.mainMenu4"></span>
                        </div>
                    </span>
                    <div class="dropdown-menu p-0" aria-labelledby="navbarDropdown4" style="min-width: 430px;">
                        <div class="row m-0">
                            <div class="col-md-6 bg-nav-menu image-4 d-flex align-items-center">
                                <h5 class="fs-20 text-white px-2 py-4 bg-nav-menu-heading fw-normal">
                                    {{staticText.menu.mainMenuBannerText4}}</h5>
                            </div>
                            <div class="col-md-6">
                                <div class="py-4">
                                    <p class="fs-16 fw-500 cursor-pointer mb-2 " style="white-space: nowrap;"
                                        (click)="navigateToSP()">{{staticText.menu.subTitleMenu9}}</p>
                                    <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;"
                                        (click)="navigateTo('delivery-accelerator/dashboard')">
                                        {{staticText.menu.subTitleMenu10}}</p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;"
                                        (click)="navigateTo('parade')">
                                        {{staticText.menu.intractive_reviews}}</p>
                                        <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;"
                                        (click)="navigateTo('confluence/teamplantracker')">
                                        {{staticText.menu.team_plan_tracker}}</p>
                                        <!-- <p class="fs-16 fw-500 cursor-pointer mb-2" style="white-space: nowrap;"
                                        (click)="navigateTo('toemasterlist')">
                                        {{staticText.menu.key_terms}}</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
            </li>
            <!-- <li class="list-group-item list-item d-flex" (click)="navigateTo('delivery/projects')">
                <img src="assets/Icons/free_icon_1.svg" alt="image" width="20px"> 
                <div class="mt-1 ml-2">Project Central</div>
            </li> -->
        </ul>
    </div>
</div>

<mat-menu #notificationmenu="matMenu" xPosition="before" class="panel-notify">
        <div *ngIf="authenticated">
            <div class="arrow-top"></div>
            <div class="notification-body p-2">
                <div class="d-flex flex-row justify-content-between align-items-center pe-2 ps-2">
                    <h5 class="fs-16 color-Port-Gore text-capitalize fw-normal">{{staticText?.home?.notifications}}</h5>
                    <h5 class="fs-14 fw-500 color-blue cursor-pointer" [routerLink]="['/notification']" *ngIf="notficationsList?.length">{{staticText?.common?.view_all}}</h5>
                </div>
                <div class="notification-list" *ngIf="notficationsList?.length">
                    <div class="notification-box p-1 cursor-pointer" [routerLink]="[notificationPopUplist?.routepath]" *ngFor="let notificationPopUplist of notficationsList | slice:0:3;index as notificaitonIndex"
                        (click)="MarkAsReadNotification(notificationPopUplist?.id)">
                        <div class="d-flex flex-row justify-content-start align-items-start pt-2 pb-2">
                            <div class="user-icon p-1">
                                <ngx-avatar [name]="notificationPopUplist?.posted_by_details?.full_name || notificationPopUplist?.posted_by_details?.username" [bgColor]=" notificaitonIndex | getRandomColor" size="38" [round]="true">
                                </ngx-avatar>
                            </div>
                            <div class="notification-msg d-flex flex-column p-1">
                                <h5 class="color-black-olive fs-14">
                                    <!-- <span class="fw-500">Abhishek Joshi</span> -->
                                    <span class="fw-normal">{{notificationPopUplist?.purpose}}</span>
                                </h5>
                                <p class="fs-12 color-black-olive fw-500 mb-0">{{notificationPopUplist?.posted_at | dateAgo}}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center my-4" *ngIf="!notficationsList?.length">
                    <span class="text-capitalize text-dark text-center text-muted fs-18">{{staticText?.header?.no_notifications}}</span>
                </div>
            </div>
        </div>
    <!-- </ng-container> -->
</mat-menu>


<ng-container *ngIf="showProfilePopUp  && authenticated">
    <div class="log-container">
        <div class="arrow-top"></div>
        <div class="profile-body pt-4 p-2">
            <div class="d-flex flex-column cursor-pointer">
                <div class="p-1 border-bottom">
                    <h5 class="color-Port-Gore fs-14">{{staticText?.header?.user_profile}}</h5>
                </div>
                <div class="pt-3 pb-3 border-bottom">
                    <div class="d-flex flex-row justify-content-start align-items-start ">
                        <div class="user-icon p-1">
                            <ngx-avatar [name]="user?.full_name" bgColor="#92c353" size="38" [round]="true">
                            </ngx-avatar>
                        </div>
                        <div class="user-info d-flex flex-column p-1 mx-2">
                            <h5 class="fs-16 text-dark">
                                {{user?.full_name}}
                            </h5>
                            <p class="fs-14 fw-500 mb-0 text-dark mb-2 words-ellipse" data-bs-toggle="tooltip" data-bs-placement="bottom" [title]="user?.email">
                                {{user?.email}}
                            </p>
                            <!-- <div class="role-badge text-center">
                                <p class="fs-12 color-black-olive fw-500 mb-0">Delivery Manage</p>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="pt-3 pb-3">
                    <div class="d-flex flex-row justify-content-start align-items-center hover-box p-2" (click)="logOut()">
                        <div class="pe-2 ps-2">
                            <img src="assets/images/header/logout.svg" alt="logout" width="18" height="18">
                        </div>
                        <div class="pe-2 ps-2 text-capitalize">
                            <p class="color-black-olive fs-16 fw-500 mb-0">{{staticText?.header?.logout}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="showLoginPopUp ">
    <div class="login-container" id="login">
        <div class="arrow-top" ></div>
        <div class="profile-body pt-1 p-1" >
            <div class="d-flex flex-column cursor-pointer">
                
                <div class="pt-2 pb-1">
                    <div class="d-flex flex-row justify-content-start align-items-center hover-box p-1" (click)="signIn()">
                        <div class="pe-2 ps-2" style="color: white;">
                            <mat-icon aria-hidden="false" aria-label="material-icons-filled" class="cursor-pointer fs-4 mr-2">
                                login
                            </mat-icon>                       
                        </div>
                        <div class="pe-2 ps-2 text-capitalize">
                            <p class="color-black-olive fs-16 fw-500 mb-0" style="color: white;">
                                Sign In
                            </p>
                        </div>
                    </div>
                </div>
                <div class="pt-1 pb-1">
                    <div class="d-flex flex-row justify-content-start align-items-center hover-box p-1" (click)="signUp()">
                        <div class="pe-2 ps-2" style="color: white;">
                            <img src="assets/images/signup.png" class="cursor-pointer fs-4 mr-2" alt="logout" width="24" height="24">
                        </div>
                        <div class="pe-2 ps-2 text-capitalize">
                            <p class="color-black-olive fs-16 fw-500 mb-0" style="color: white;">
                                Sign Up
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #addTracker *ngIf="authenticated">
    <div class="field-adjust">
        <div class="modal-dialog action-tracker-popup mb-0">
            <div class="modal-content b-none tableModelStyle">
                <div class="modal-header d-flex justify-content-between align-items-center pt-0 pb-1">
                    <h2 mat-dialog-title class="mb-0 fw-bold color-Port-Gore fs-18">
                        {{staticText?.action_tracker?.title}}
                    </h2>
                    <img class="cursor-pointer" src="./assets/images/pages/workforce/close.svg" alt="closeModal" (click)="closeDialog()">
                </div>
                <div class="modal-body d-flex flex-column justify-content-between pb-0">
                    <form [formGroup]="actionTrackerForm" novalidate class="p-0">
                        <div class="">
                            <div class="form-group required">
                                <div class="mb-3 d-flex">
                                    <div class="bubble-top bg-1 mr-2"></div>
                                    <div class="d-flex">
                                        <label for="benefits" class="labelstyle">
                                            {{staticText?.action_tracker?.basic_details}}
                                        </label>
                                    </div>
                                    <hr>
                                </div>

                                <div class="row col-12 px-0 details-bg-1">
                                    <!-- <div class="d-flex"> -->
                                    <div class="col-3">
                                        <mat-form-field appearance="outline" class="col-12 p-0">
                                            <mat-label>{{staticText?.action_tracker?.action_category}}</mat-label>
                                            <input matInput [matAutocomplete]="auto" type="text" formControlName="action_category">
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayActionCatFn">
                                                <mat-option *ngFor="let option of filterActionTracker | async" [value]="option">
                                                    {{option.display_name| titlecase}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- <mat-error *ngIf="(submitted|| actionTrackerForm.get('action_category').touched) && actionTrackerForm.get('action_category').errors">
                                                    <small class="text-danger" *ngIf="actionTrackerForm.get('action_category').errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                                </mat-error> -->
                                        </mat-form-field>
                                    </div>

                                    <div class="required mb-0 col-3" *ngIf="showAdditionalDetails">
                                        <mat-form-field class="col-12 p-0" appearance="outline">
                                            <mat-label>
                                                {{actionTrackerForm.get("action_category")?.value?.name=='Project'? staticText?.action_tracker?.project_num : staticText?.action_tracker?.opportunity_no}}
                                            </mat-label>
                                            <input type="text" matInput formControlName="additional_detail1" disabled />
                                        </mat-form-field>
                                    </div>
                                    <div class="required mb-0 col-3" *ngIf="showAdditionalDetails">
                                        <mat-form-field class="col-12 p-0" appearance="outline">
                                            <mat-label>{{actionTrackerForm.get("action_category")?.value?.name=='Project'?
                                                staticText?.action_tracker?.project_name :
                                                staticText?.action_tracker?.opportunity_name}}</mat-label>
                                            <input type="text" matInput formControlName="additional_detail" disabled />
                                        </mat-form-field>
                                    </div>
                                    <div class="col-3" *ngIf="actionTrackerForm.get('vendor').value">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{staticText?.filter?.gdp}}</mat-label>
                                            <input matInput type="text" class="text-truncate" readonly
                                                matTooltip="{{actionTrackerForm.get('vendor').value}}"
                                                formControlName="vendor">
                                            <!-- <mat-icon matSuffix class="muted">search</mat-icon>
                                                <mat-autocomplete #auto8="matAutocomplete" [displayWith]="displayFnGDP">
                                                    <mat-option *ngFor="let option of filterGDP | async"
                                                        [value]="option">
                                                        {{option.name| titlecase}}
                                                    </mat-option>
                                                </mat-autocomplete> -->
                                        </mat-form-field>
                                        <!-- <mat-form-field class="example-chip-list col-12 p-0" appearance="outline">
                                                <mat-label>{{staticText?.filter?.gdp}}</mat-label>
                                                <mat-chip-list #chipList aria-label="selection" formControlName="vendor">
                                                    <mat-chip
                                                        *ngFor="let item of actionTrackerForm.get('vendor').value;"
                                                        class="background-grey fs-12 fw-500  border" formControlName="vendor"
                                                        name="vendor" ngDefaultControl>
                                                        {{item?.vendor_details?.name || item?.vendor }}
                                                    </mat-chip>
                                                </mat-chip-list>
                                            </mat-form-field> -->
                                    </div>
                                    <div class="required mb-0 col-3">
                                        <mat-form-field class="col-12 p-0" appearance="outline">
                                            <mat-label>{{staticText?.action_tracker?.title_field}}</mat-label>
                                            <input type="text" matInput formControlName="title"
                                                placeholder="Enter the Tracker Title..." required />
                                        </mat-form-field>
                                    </div>
                                    <div class="mb-0 required" [ngClass]="showAdditionalDetails ? 'col-9':'col-6'">
                                        <mat-form-field class="elementalign brief-description w-100"
                                            appearance="outline">
                                            <mat-label>{{staticText?.action_tracker?.brief_desc}}</mat-label>
                                            <textarea matInput id="solution_brief" formControlName="description"
                                                cdkTextareaAutosize placeholder="Enter the Description..."
                                                required></textarea>
                                        </mat-form-field>
                                    </div>
                                    <div class="required mb-0  col-3">
                                        <mat-form-field appearance="outline" class="col-12 p-0">
                                            <mat-label>{{staticText?.action_tracker?.assignee}}</mat-label>
                                            <input matInput [matAutocomplete]="autoAssignee" type="text"
                                                formControlName="assignee">
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #autoAssignee="matAutocomplete"
                                                [displayWith]="displayAssigneFn">
                                                <mat-option *ngFor="let option of filterAssignee | async"
                                                    [value]="option">
                                                    {{option.name}}
                                                </mat-option>
                                            </mat-autocomplete>
                                            <!-- <mat-error *ngIf="(submitted|| actionTrackerForm.get('assignee').touched) && actionTrackerForm.get('assignee').errors">
                                                    <small class="text-danger" *ngIf="actionTrackerForm.get('assignee').errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                                </mat-error> -->
                                        </mat-form-field>
                                    </div>
                                    <!-- </div>

                                    <div class="form-group">
                                        <div class="d-flex"> -->
                                    <div class="required col-3 date-fld">
                                        <mat-form-field appearance="outline">
                                            <mat-label>{{staticText?.action_tracker?.due_date}}</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="due_date"
                                                autocomplete="off" (click)="picker.open()">
                                            <mat-datepicker-toggle matSuffix [for]="picker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                    <div class=" col-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>{{staticText?.filter?.status}}</mat-label>
                                            <input matInput [matAutocomplete]="autos" type="text"
                                                formControlName="status">
                                            <mat-icon matSuffix class="muted">search</mat-icon>
                                            <mat-autocomplete #autos="matAutocomplete">
                                                <mat-option value="Open">Open</mat-option>
                                                <mat-option value="Closed">Closed</mat-option>
                                                <!-- <mat-error *ngIf="(submitted|| actionTrackerForm.get('vendor').touched) && actionTrackerForm.get('vendor').errors">
                                                            <small class="text-danger" *ngIf="actionTrackerForm.get('vendor').errors.optionError">{{staticText?.common?.autocomplete_option_error}}</small>
                                                        </mat-error> -->
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <!-- </div> -->
                                    <!-- </div> -->


                                </div>
                            </div>
                            <!-- <div class="form-group required ">
                                <div class="mb-3 d-flex">
                                    <div class="bubble-top bg-2 mr-2"></div>
                                    <label for="benefits" class="labelstyle">{{staticText?.action_tracker?.classifications}}</label>
                                    <hr>
                                </div>
                                <div class="d-flex form-group px-3 details-bg-2">
                                    <div class="d-flex">
                                        <div class="mr-4">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{staticText?.action_tracker?.action_category}}</mat-label>
                                                <input matInput [matAutocomplete]="auto" type="text"
                                                    formControlName="action_category">
                                                <mat-icon matSuffix class="muted">search</mat-icon>
                                                <mat-autocomplete #auto="matAutocomplete"
                                                    [displayWith]="displayActionCatFn">
                                                    <mat-option *ngFor="let option of filterActionTracker | async"
                                                        [value]="option">
                                                        {{option.display_name| titlecase}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="required mr-4">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{staticText?.opportunity?.function}}</mat-label>
                                                <input matInput [matAutocomplete]="auto1" type="text"
                                                    formControlName="action_function">
                                                <mat-icon matSuffix class="muted">search</mat-icon>
                                                <mat-autocomplete #auto1="matAutocomplete"
                                                    [displayWith]="displayFunction">
                                                    <mat-option *ngFor="let option of filterFunctionTracker | async"
                                                        [value]="option">
                                                        {{option.display_name| titlecase}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div class="required">
                                            <mat-form-field appearance="outline">
                                                <mat-label>{{staticText?.action_tracker?.due_date}}</mat-label>
                                                <input matInput [matDatepicker]="picker" formControlName="due_date"
                                                    autocomplete="off" (click)="picker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="picker">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="form-group required">
                                <div class="mb-2 d-flex">
                                    <div class="bubble-top bg-3  mr-2"></div>
                                    <label for="benefits"
                                        class="labelstyle">{{staticText?.action_tracker?.supporting_docs}}</label>
                                    <hr>
                                </div>
                                <div class="d-flex form-group px-3">
                                    <div class="col-md-3 p-0">
                                        <div class="upload mt-2 ">
                                            <div class="d-flex">
                                                <div class="w-100 cursor-pointer d-flex justify-content-center mb-2"
                                                    (click)="fileInput1.click()">
                                                    <small class="p-3 fw-bold m-0">
                                                        {{staticText?.common.upload_doc}}</small>
                                                </div>
                                            </div>
                                            <input multiple hidden (change)="onFileSelected($event)" #fileInput1
                                                type="file" name="file" id="file"
                                                accept=".doc, .pdf, .docx, .ppt, .pptx">
                                            <small class="doc text-nowrap pt-2">
                                                {{staticText?.action_tracker?.doc_maxsize}}</small>
                                        </div>
                                    </div>

                                    <div class="col-md-9 p-0">
                                        <div class="d-flex files">
                                            <p *ngIf="files.length == 0"
                                                class=" ml-4 pt-4 fw-bold add-doc cursor-pointer"
                                                (click)="fileInput1.click()"> <i class="fal fa-plus fw-bold"></i>
                                                {{staticText?.action_tracker?.add_doc}}</p>
                                            <span *ngFor="let data of files" class="file d-flex">
                                                <div class="d-flex"
                                                    *ngIf="data.name.includes('.doc') || data.name.includes('.docx') ">
                                                    <img src="./assets/images/docs.png" height="40">
                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                        {{data.name}}</p>
                                                    <img src="./assets/images/delete-icon-new.svg" height="40"
                                                        (click)="deleteFile(data)">
                                                </div>
                                                <div class="d-flex" *ngIf="data.name.includes('.pdf') ">
                                                    <img src="./assets/images/pdf-icon-new.svg" height="40">
                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                        {{data.name}}</p>
                                                    <img src="./assets/images/delete-icon-new.svg" height="40" (click)="deleteFile(data)">
                                                </div>

                                                <div class="d-flex" *ngIf="data.name.includes('.pptx') || data.name.includes('.ppt')">
                                                    <img src="./assets/images/powerpoint.png" height="40">
                                                    <p class="w-100 cursor-pointer" style="font-size: 8pt;">
                                                        {{data.name}}
                                                    </p>
                                                    <img src="./assets/images/delete-icon-new.svg" height="40" (click)="deleteFile(data)">
                                                </div>
                                            </span>
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="float-right">
                            <button mat-button (click)="closeDialog()" class="my-3 px-4 p-0 border border-default me-3">
                                <span class="m-0">
                                    {{staticText?.buttons?.cancel_btn}}
                                </span>
                            </button>
                            <button mat-button class="my-3 me-3 btn-bg text-white px-4 p-0"
                                (click)="submitActionTracker()">
                                <span class="m-0">
                                    {{staticText?.action_tracker?.add_tracker_btn}}
                                </span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>
</div>