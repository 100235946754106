import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  public filterSubject = new BehaviorSubject<{}>({});

  public filterFlag = new BehaviorSubject<{}>({});

  constructor(private http: HttpClient,private transferState: TransferState) { }


  setValue(value: string) {
    this.filterSubject.next(value);
  }

  getValue() {
    return this.filterSubject.asObservable();
  }

  setFilterField(field:any){
    this.filterFlag.next({field:field})
  }

  getFilterField() {
    return this.filterFlag.asObservable();
  }

  getPortfolios(){
    return this.http.get(`delivery/get-portfolio-list/`);
  }
  
  getFilterList(queryParam: any) {
    return this.http.get(`delivery/get-filtered-list/?${queryParam}`)
  }

  getSubPortfolios(portfolioId:any){
    if(portfolioId){
      return this.http.get(`delivery/get-subportfolio-list/?parent=${portfolioId}`);
    }
    return this.http.get(`/delivery/get-complete-subportfolio-list/`);
  }

  getProgram(subPortfolioId:any){
    if(subPortfolioId){
      return this.http.get(`delivery/sub-porfolio-program/?sub_portfolio_id=${subPortfolioId}`);
    }
    return this.http.get(`/delivery/get-complete-program-list/`);
  }

  getOpportunity(subPortfolioId:any,program:any){
    if(subPortfolioId && program){
      return this.http.get(`delivery/get-opportunity-list/?portfolio=${subPortfolioId}&program=${program}`);
    }
    return this.http.get(`/delivery/get-complete-opportunity-list/`);
  }

  getOppStatus(){
    return this.http.get(`opportunity/opportunity-status-list`);
  }

  getProject(subPortfolioId:any,program:any){
    if(subPortfolioId && program){
      return this.http.get(`delivery/program-project/?program_id=${program}&sub_portfolio_id=${subPortfolioId}`);
    }
    return this.http.get(`/delivery/get-complete-project-list/`);
  }

  getVendor(){
    return this.http.get(`/delivery/get-complete-vendor-list/`);
  }

  getTag(){
    return this.http.get(`/delivery/get-complete-tag-list/`);
  }

  getApplicationFilters(){
    return this.http.get(`/delivery/application/filter-options/`);
  }

  getWorkforceFilters(){
    return this.http.get(`/delivery/get-complete-filter-list/`);
  }

  getDomain(){
    return this.http.get(`delivery/incidentdomain/`);
  }
  getSubDomain(){
    return this.http.get(`delivery/incidentsubdomain/`);
  }
  
}
