<div *ngIf="currentCaptureStatus == true" class="capture-hint">
    Currently in Parade Mode

    <button mat-button color="close" class="ml-3" (click)="removeCapture()">Close</button>
</div>
<div class="parade-dps" *ngIf="paradeImgArray.length > 0">
    <h6 class="m-0"  style="font-size: 12px; text-align: center; color: white; border-bottom: 1px solid; display: flex; align-items: center; justify-content: space-between;">
        Carousel ({{paradeImgArray.length }})
        <mat-icon (click)="clear()" style="font-size: 14px;">clear</mat-icon>
    </h6>
    <div style="display: flex; justify-content: right">
        <div style="display: flex; align-items: center; width: 80%; overflow-x: auto; padding: 0 5px;">
            <div *ngFor="let img of paradeImgArray; let i = index" class="text-center mgh">
                <p class="m-0 lbl">{{img.label}}</p>
                <div style="position: relative;">
                    <img src="{{img.base64}}" style="width: 40px; height: 40px; object-fit: cover;  object-position: bottom; padding: 0px; border: 0.5px dotted white;"  >
                    <mat-icon (click)="deleteItem(i)">delete</mat-icon>
                </div>
                <div class="shw">
                    <p>{{img.label}}</p>
                    <img src="{{img.base64}}" class="imgzoom">
                </div>
            </div>
        </div>
        <div style="width: 20%; margin-top: 2px; padding: 5px 10px;">
            <button mat-raised-button color="map" (click)="mapParade()" style="width: 100% !important;">Map to existing parade</button>
            <button mat-raised-button color="create" (click)="createParade()" style="width: 100% !important;">Create new parade</button>
        </div> 
    </div>
</div>
<div id="wrapper" #screenshotContainer (mousedown)="startSelection($event)">
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#D40F78" type="ball-fussion" [fullScreen]="true"></ngx-spinner>
    <router-outlet></router-outlet>
    <div class="to-top" (click)="scrollToTop()" [ngClass]="{ 'show-scrollTop': windowScrolled }">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAOVBMVEVBQkH9//3///88PTw3ODfy9PJiY2Ld390sLSwkJSTAwsBERUQuLi719/VJSklpamkWFxbOz84yMzJyqKThAAACXklEQVR4nO3c604CMRCG4YVVQUU83P/FyuChLMuWXeh0OpP3+SuJvvlICyTYdQAAAAAAAAAAAAAAAAAAAAAAAAAAALb6XW/9J+jq94/vD9Z/hKZ+/7ZeR06UwFXkxGPgKnDib2DcxP/AqIkngTETB4ERE88C4yWOAqMlXgiMlXgxMFLiRGCcxL6bCJTEpwCJ/evjVGCMxL7LBEZIzC4YIfHKgv4Try7oPXFWoOfEGU9R34kzF/SbOHtBr4kLFvSZuGhBj4mZBWO8Rs0suJ7+iaPE3IKbjxf/idnAXffsPjH3FN1sDw/wnnhlQeE78eqCwnNidsHt/8P8Js5aUHhNnLmg8JmYXXB39mCPiQsWFP4SZ1wTQ94SZx8yia/ExQsKT4k3LCj8JC48ZBIviTcuKHwk3ryg8JC46KIfaz/xrgVF64k3XRNDbSfeccgkLScWWFC0m1hkQdFq4t2HTNJmYrEFRYuJBRcU7SXeedGPtZZYeEHRVmKha2KopcSih0zSTqLKgqKVRKUFRRuJCodM0kKi4oLCPlF1QWGdWPyiH7NNVF9QWCaqXRNDdonKh0xilrivsqDIJup90Xa3qbOgyCSuvgr/rhNTG5YPnE5U3XDqoNEInEpUP2ouJeoEXk40uS5KHzLJONHkytdaUJwnVnrZNkzUW1AME01eemsuKE4TK759Som6C4qUaPIWWHtB8ZdY+WOMn8QagX+JJh9F1Qn8STT4OPGQWCnwmGjxkfD+U/EF8Jnti8k3husFHn5X8P9MBAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMCdb1F1HmHm3lXzAAAAAElFTkSuQmCC" alt="to-top-icon" width="35" />
    </div>
    <div *ngIf="currentCaptureStatus != true && !iframeEmbedded" class="capture" (click)="capture()" matTooltip="Change to parade mode"  matTooltipClass="example-tooltip">
        <mat-icon>control_camera</mat-icon>
    </div>
</div>
<div id="warning-message">
    Site is only viewable in landscape mode
</div>

