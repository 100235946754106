import { Component, Inject, OnInit, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ProgramService } from '../../services/program.service';
import * as textConfiguration from 'src/assets/static-text-configuration.json';
import { ViewEncapsulation } from '@angular/core';
import { FormValidatorService } from '../../services/form-validator/form-validator.service';
import { TagsService } from '../../services/tag.service';
import { DealStructureService } from '../../services/deal-structure.service'
import { ProjectService } from '../../services/project.service';
import { DatePipe } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FilterService } from '../../services/filter.service'; 


@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [DatePipe],
  encapsulation:ViewEncapsulation.Emulated
})
export class ModalComponent implements OnInit {
  staticText: any = (textConfiguration as any).default;
  type: any;
  typeName: any;
  data: any;
  screentype: any;
  fieldsdata: any;
  opportunityForm: FormGroup | any;
  dealForm: FormGroup | any;
  projectForm: FormGroup | any;
  projectMapForm = new FormGroup({});
  options2: any;
  options3: any;

  filteredOptions2: any;
  filteredOptions3: any;

  options1: any;
  filteredOptions1: any;
  subPortOptions: any;
  ownersOptions: any;
  // spocOptions: any;
  // spocfilteredOptions: any;
  nonWhitespaceRegExp = new RegExp('\\S');
  subPortfilteredOptions: any;
  ownersfilteredOptions: any[] = [];
  show = false;
  ownerData: any[] = [];
  submitted = false;
  ifSubmit = false;


  //// project screen model data
  deliveryProjectList: any;
  OpportunityList: any;
  opportunityFilterOptions: Observable<any[]> | any;
  projectFilterOptions: Observable<any[]> | any;


  OpportunityFieldList: any;
  OpportunityName:any;
  filterOptionType: Observable<any[]> | any;
  filterOptionProgram: Observable<any[]> | any;

  filterOptionPortfolio: Observable<any[]> | any;
  filterOptionSubPortfolio: Observable<any[]> | any;
  filterOptionOwners: Observable<any[]> | any;
  // filterOptionSpoc: Observable<any[]> | any;

  @ViewChild('autoInput') input: any;
  @ViewChild('autoInput1') input1: any;
  @ViewChild('autoInput2') input2: any;
  @ViewChild('autoInput3') input3: any;
  projectNumber: any;
  opportunityNumber: any;
  FormFieldOpportunity: any;
  FormFieldProject: any;
  SelectedOpportunity: any;
  selectedproject: any;
  option: any;
  opportunityLabel: any;
  projectLabel: any;
  programdata: any;
  maxChars = 100;
  subPortFolioChild:any[]=[];
  ownerDetails:any={};
  archiveList: any;
  userSearchText: any = "";
  contractSearchText: any = "";
  filterTeamList: any;
  filterContractList: any;
  surveyComments:any;
  tempTags: any = [];
  dealTags:any = [];
  programList: any = [];

  currentDomain: string = window.location.origin; 
  iframeUrl: any = null;
 
  constructor(
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private ps: ProgramService,
    private spinner: NgxSpinnerService,
    private _formValidator:FormValidatorService,
    private tagService: TagsService,
    private dealStructureService : DealStructureService,
    private projectService : ProjectService,
    private datepipe: DatePipe,
    private sanitizer: DomSanitizer,
    private filterService : FilterService,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
  ) {
  }

  ngOnInit(): void {
    if (this.type == 'Addopperuntiy') {

      this.createForm();
      this.data = {
        name: true,
        opportunity: false,
        portfolio: false,
        subPortfolio: false,
        program: false,
        owners: false,
        // spoc: false,
      }
      if(typeof this.OpportunityName === 'object'){
        this.ownerDetails = this.OpportunityName?.owner;
        this.ownerDetails.mail = this.ownerDetails.email;
        this.opportunityForm.patchValue({
          name:this.OpportunityName.title,
          portfolio:this.OpportunityName?.portfolio?.parent_portfolio,
          subPortfolio:this.OpportunityName?.portfolio,
          program:this.OpportunityName?.program,
          owners: this.OpportunityName?.owner.first_name + ' ' + this.OpportunityName?.owner.last_name,
          details_id: this.OpportunityName?.details_id
        })
      }
      this.getOpportunity();
    }

    if (this.type == 'AddDeal') {

      this.createDealForm();
      this.data = {
        name:true,
        deal_type: true,
        short_description: false,
        program_id: false,
        subPortfolio: false,
        program: true,
        owners: false,
        // spoc: false,
      }
      this.getDealFields('deal');
      
      this.getProgramList();
    }

    if (this.type == 'AddProject') {

      this.createProjectForm();
      this.data = {
        project_name:true,
        project_id: true,
        project_value: true,
        begin_date: true,
        expected_end_date: true,
        project_type_id: true,
        project_funding_type_id: true,
        program_id:true,
        program:true,
        subportfolio_id:true
      }
      this.getDealFields('project');
      this.getProjectType();
      this.getProjectFundingType();
    }

    if(this.type?.name == "user_owner"){
      this.filterTeamList = this.type.team_list;
    }
    if(this.type?.name == "map_sow_to_project"){
      this.filterContractList = this.type.contract_List;
    }
    if(this.type?.name == "surveyComments"){
      this.surveyComments = this.type?.comments;
    }

    if(this.type?.name == "CreatePlan"){
      // this.getOwner();
    }

    if(this.type?.name == "projectpop"){
      this.iframeUrl = this.currentDomain + '/view/' + btoa(this.modalData?.opportunity) + '/overview?embedded=true';
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
    }

  }

  get check() {
    return this.projectMapForm.controls;
  }

  showFn(data: any) {
    return data && data.opportunityname ? data.opportunityname : '';
  }

  createForm() {
    this.opportunityForm = this.fb.group({
      name: ['', [Validators.required]],
      opportunity: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      portfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      subPortfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      // program: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      program: [''],
      owners: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
      // spoc: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
      details_id: ['']
    });
    this.opportunityForm.get("owners").disable();
  }

  createDealForm(){
    this.dealForm = this.fb.group({
      name:['', [Validators.required]],
      deal_type:[''],
      portfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      subPortfolio: ['', [Validators.required,this._formValidator.autocompleteObjectValidator()]],
      program: [''],
      program_id: ['',[Validators.required]],
      owners: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
      // spoc: ['',[Validators.required,this._formValidator.autocompleteObjectValidator()]],
    });
    this.dealForm.get("owners").disable();
  }

  createProjectForm(){
      this.projectForm = this.fb.group({
        project_name:['', [Validators.required]],
        project_id:['', [Validators.required]],
        project_value:['',[Validators.required]],
        begin_date:['',[Validators.required]],
        expected_end_date:['',[Validators.required]],
        project_type_id:['',[Validators.required]],
        project_funding_type_id:['',[Validators.required]],
        program_id:['',[Validators.required]],
        portfolio_id:['',[Validators.required]],
        subportfolio_id:['',[Validators.required]]
      })
  }

  initAutoComplete(): any {
    if (this.data.opportunity) {
      this.filterOptionType = this.opportunityForm.get('opportunity')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.typeFilter(name) : this.filteredOptions2.slice())
        );
    }
    if (this.data.portfolio) {
      this.filterOptionPortfolio = this.opportunityForm.get('portfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
        );
    }
    if (this.data.program) {
      this.filterOptionProgram = this.opportunityForm.get('program')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.programFilter(name) : this.filteredOptions3.slice())
        );
    }

    // if (this.data.spoc) {
    //   this.filterOptionSpoc = this.opportunityForm.get('spoc')!.valueChanges
    //     .pipe(
    //       startWith(''),
    //       map((value: any) => typeof value === 'string' ? value : value?.name),
    //       map((name: any) => name ? this.spocFilter(name) : this.spocfilteredOptions.slice())
    //     );
    // }
  }

  private typeFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions2.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }

  private programFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions3.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }
  private portfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.filteredOptions1.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }

  private subPortfolioFilter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.subPortfilteredOptions.filter((i: any) => i.name.toLowerCase().includes(filterValue));
  }

  // private spocFilter(value: string): any {
  //   const filterValue = value.toLowerCase();
  //   return this.spocfilteredOptions.filter((i: any) => i.name.toLowerCase().includes(filterValue))
  // }


  displayFn(data: any) {
    return data && data.name ? data.name : '';
  }


  validateData(type: any) {
  }

  private Filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.OpportunityFieldList.filter((i: any) => i.opportunity_name.toLowerCase().includes(filterValue));
  }

  getOpportunity() {
    this.OpportunityFieldList?.map((i: any) => {
        
      if (i.field_name == "opportunity_name") {
        this.data.name = i.field_status
        this.data.disName = i.display_name
        if(this.type == 'AddDeal'){
          this.data.disName = 'Deal Name';
        }
        if(this.type == 'AddProject'){
          this.data.disName = 'Project Name';
        }
        if (!i.field_status) {
          this.opportunityForm.removeControl('name');
        }
      }
      if (i.field_name == "opportunity_type") {
        this.data.opportunity = i.field_status;
        this.options2 = i.field_values;
        this.filteredOptions2 = this.options2;
        this.data.disOpportunity = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('opportunity');
        }
      }
      if (i.field_name == "program_name") {
        this.data.program = i.field_status;
        this.options3 = i.field_values;
        this.filteredOptions3 = this.options3;
        this.data.disProgram = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('program');
        }
      }
      if (i.field_name == "opportunity_portfolio") {
        this.data.portfolio = i.field_status;
        this.options1 = i.field_values;
        this.filteredOptions1 = this.options1;
        this.data.disPortfolio = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('portfolio');
        }
      }
      if (i.field_name == "opportunity_sub_portfolio") {
        this.data.subPortfolio = i.field_status;
        this.subPortOptions = i.field_values;
        this.subPortfilteredOptions = this.subPortOptions;
        this.data.disSubPortfolio = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('subPortfolio');
        }
      }
      if (i.field_name == "opportunity_owners") {
        this.data.owners = i.field_status;
        this.ownersOptions = i.field_values;
        this.ownersfilteredOptions = this.ownersOptions;
        this.data.disOwners = i.display_name
        if (!i.field_status) {
          this.opportunityForm.removeControl('owners');
        }
      }
      // if (i.field_name == "opportunity_spoc") {
      //   this.data.spoc = i.field_status;
      //   this.spocOptions = i.field_values;
      //   this.spocfilteredOptions = this.spocOptions;
      //   this.data.disSpoc = i.display_name
      //   if (!i.field_status) {
      //     this.opportunityForm.removeControl('spoc');
      //   }
      // }
    })
    this.initAutoComplete();
  }

  getDeal(){

  }

  onSelectionChangePortfolio(event: any) {
    const portFolioId = event.source.value.id;
    this.subPortfilteredOptions = [];
    if (!portFolioId) {
      return;
    }
    this.opportunityForm.patchValue({
      subPortfolio: '',
      owners:'',
      program:''
    })
    this.subPortFolioChild =[];
    Object.keys(this.opportunityForm.controls).forEach((control:any)=>{
      if(control.includes('sub_portfolio_child')){
        this.opportunityForm.removeControl(control);
      }
    })
    
    this.subPortOptions = event.source.value.sub_portfolio;
    this.subPortfilteredOptions = this.subPortOptions;
    this.filterOptionSubPortfolio = this.opportunityForm.get('subPortfolio')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
      );
  }

 
  onSelectionChangeSubportPortfolio(e: any) {  
    if(e.isUserInput){
    const subportFolioId = e.source.value.id;
    this.spinner.show();
    //this.subPortfilteredOptions = [];
    if (!subportFolioId) {
      return;
    }  
    this.opportunityForm.patchValue({
      program:''
    })
    let value = e.source.value; 
    this.filterService.getProgram(e.source.value.id).subscribe((res:any)=>{
      this.filterOptionProgram = this.opportunityForm.get('program')!.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value?.name),
        map((name: any) => name ? this.programFilter(name) : res.records.slice())
      );
      this.spinner.hide();
    });
    
    this.subPortFolioChild =[];
    if(JSON.stringify(value).includes("sub_portfolio_child")){
      Object.keys(value).forEach((key:any)=>{
        if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
          this.opportunityForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));  
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
        }else{
          this.opportunityForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })
          this.ownerDetails = value.owner_details?.[0];
          this.ownerDetails['mail']=this.ownerDetails['email'];
        }
      });
    }else{
      this.opportunityForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })
      this.ownerDetails = value.owner_details?.[0];
      this.ownerDetails['mail']=this.ownerDetails['email'];
    }
   }
  }
  onSelectionChangeSubportPortfolioChild(e:any){
    let value = e.source.value;
    if(JSON.stringify(value).includes("sub_portfolio_child")){
      Object.keys(value).forEach((key:any)=>{
        if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
          let index = this.subPortFolioChild.findIndex((e:any)=> e.key === key);
          if(index>-1)
            this.subPortFolioChild.splice(index , this.subPortFolioChild.length - index );
          this.opportunityForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));       
          let level = key?.split("_")?.pop();;
          this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
        }else{            
          this.opportunityForm.patchValue({
            owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
          })          
          this.ownerDetails = value.owner_details?.[0];
          this.ownerDetails['mail']=this.ownerDetails['email'];
        }
      });      
    }else{
      this.opportunityForm.patchValue({
        owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
      })      
      this.ownerDetails = value.owner_details?.[0];
      this.ownerDetails['mail']=this.ownerDetails['email'];
    }
  }
  onSelectionOwners(event: any) {
    if (!!event.first_name == null) {
      event.first_name = '';
    } else if (!!event.last_name == null) {
      event.last_name = '';
    }
  }

  // onSelectionSPoc(event: any) {
  //   if (!!event.first_name == null) {
  //     event.first_name = '';
  //   } else if (!!event.last_name == null) {
  //     event.last_name = '';
  //   }
  // }

  onSelectionProgram(event: any) {
    
  }

  get f() {
    return this.opportunityForm.controls;
  }
  get fd() {
    return this.dealForm.controls;
  }
  add() {
    this.submitted = true;
    if (this.opportunityForm.invalid) {
      return;
    } else {
      if (this.data.opportunity) {
        const a = this.filteredOptions2.some((i: any) => i.id == this.opportunityForm.value.opportunity.id);
        if (!a) {
          this.toastrService.error(`Please select anyone value from ${this.data.disOpportunity}`);
          return;
        }
      }

      if (this.data.portfolio) {
        const b = this.filteredOptions1.some((i: any) => i.id == this.opportunityForm.value.portfolio.id);
        if (!b) {
          this.toastrService.error(`Please select anyone value from ${this.data.disPortfolio}`);
          return;
        }
      }
      // if (this.data.program) {
      //   const a = this.filteredOptions3.some((i: any) => i.id == this.opportunityForm.value.program.id);
      //   if (!a) {
      //     this.toastrService.error(`Please select anyone value from ${this.data.disProgram}`);
      //     return;
      //   }
      // }
      this.addOpportunity();
    }
  }

  addOpportunity() {
    if(this.tempTags.length <= 0 ){
      this.toastrService.error(`Please select tags`);
      return;
    }
    this.spinner.show();
    const payload: any = {
      opportunity_name: this.opportunityForm.value.name,
    }
    if (this.opportunityForm.value.opportunity) {
      payload.opportunity_type = this.opportunityForm.value.opportunity.id
    }
    if (this.opportunityForm.value.program) {
      payload.program = this.opportunityForm.value.program.id
    }
    if (this.opportunityForm.value.portfolio) {
      let childID = this.subPortFolioChild[this.subPortFolioChild?.length-1]?.id;
      payload.portfolio = childID>=0 ? childID:this.opportunityForm.value.subPortfolio.id;
    }

    if(this.opportunityForm.value.details_id){
      payload.details_id = this.opportunityForm.value.details_id;
    }
    // if (this.opportunityForm.value.spoc) {
    //   payload.spoc = this.opportunityForm.value.spoc
    // }
	payload.owners = [this.ownerDetails];
    console.log("add model popup values", payload);
    this.ps.createOpportunity(payload).subscribe(async (res: any) => {
      localStorage.setItem('opportunityId', res[0].id);
      await this.tempTags.forEach(async (element: any) => {
        let pst = {
          entity_id: res[0].id,
          entity_type: "opportunity",
          tag_id: element.id
        }
        await this.tagService.magTag(pst).subscribe((res) => {});
      });

      this.router.navigate([`rfx/`+btoa(res[0].id)+`/opportunity/setup`]);
      this.dialogRef.close();
      this.spinner.hide();
      
    }, error => {
      this.spinner.hide();
      if(error.status == 500){
        this.toastrService.error("Internal server error")
      }else{
        this.toastrService.error(error.message)
      }      
      throw error;
    })
  }

  cancel() {
    this.dialogRef.close();
  }
  done() {
    if(this.type == 'ArchiveList'){
      this.dialogRef.close(this.archiveList);
    }else if(this.type?.name == 'user_owner'){
      this.dialogRef.close(this.type.team_list);
    }else if(this.type?.name == 'map_sow_to_project'){
      this.dialogRef.close(this.type.contract_List);
    }else{
      this.dialogRef.close(true);
    }
  }

  searchUser(item: any, search: any){
    if(search != ""){
      return item?.name?.toLowerCase().includes(search?.toLowerCase())
    }else{
      return true;
    }
  }

  searchContract(item: any, search: any){
    if(search != ""){
      return item?.contract_name?.toLowerCase().includes(search?.toLowerCase())
    }else{
      return true;
    }
  }

  ngAfterViewInit(){
    if(this.type?.id){
      let el:any = document.getElementById(this.type?.id);
      el.scrollIntoView();
    }  
  }

  getChildTags(data: any){
    this.tempTags = data;
  }

  getChildDealTags(data: any){
    this.dealTags = data;
  }
  projectTags:any = [];
  getChildProjectTags(data:any){
    this.projectTags = data;
  }
  addDeal(){
    this.dealForm.markAllAsTouched();
    if(this.dealTags.length <= 0 ){
      this.toastrService.error(`Please select tags`);
      return;
    }
    if (this.dealForm.invalid) {
      return;
    } else {
      let payload = {
        deal_name:this.dealForm.value.name,
        // deal_type:this.dealForm.value.deal_type.id,
        portfolio_id:this.dealForm.value.subPortfolio.id,
        program_id:this.dealForm.value.program_id
      }

      this.dealStructureService.addNewDeal(payload).subscribe(async (res:any)=>{
        if(res){
          await this.dealTags.forEach(async (element: any) => {
            let pst = {
              entity_id: res.id,
              entity_type: "deal",
              tag_id: element.id
            }
            await this.tagService.magTag(pst).subscribe((res) => {});
          });
          this.router.navigate([`deal/`+btoa(res?.id)+`/setup`]);
          this.dialogRef.close();
          this.toastrService.success('Deal Created successfully. Complete the setup')
        }
      });
    }
}

addProject(){
  this.projectForm.markAllAsTouched();
    if(this.projectTags.length <= 0 ){
      this.toastrService.error(`Please select tags`);
      return;
    }
    if (this.projectForm.invalid) {
      return;
    } else {
      this.projectForm.value.begin_date = this.datepipe.transform(this.projectForm.value.begin_date,"yyyy-MM-dd");
      this.projectForm.value.expected_end_date = this.datepipe.transform(this.projectForm.value.expected_end_date,"yyyy-MM-dd");
      this.spinner.show();
      let payload = {
        project_name:this.projectForm.value.project_name,
        project_id:this.projectForm.value.project_id,
        project_value:this.projectForm.value.project_value,
        begin_date:this.projectForm.value.begin_date,
        expected_end_date:this.projectForm.value.expected_end_date,
        project_type_id:this.projectForm.value.project_type_id,
        project_funding_type_id:this.projectForm.value.project_funding_type_id,
        subportfolio_id:this.projectForm.value.subportfolio_id.id,
        program_id:this.projectForm.value.program_id.id,
        work_type_id:50,
      }
      this.projectService.addNewProject(payload).subscribe(async (res:any)=>{
        this.spinner.hide();
        if(res){
          await this.projectTags.forEach(async (element: any) => {
            let pst = {
              entity_id: res.id,
              entity_type: "project",
              tag_id: element.id
            }
            await this.tagService.magTag(pst).subscribe((res) => {});
          });
          
          let newitem = {
            project_id:res.id,
            program_name: this.projectForm.value.program_id.name || this.projectForm.value.program_id.display_name,
            program_id: this.projectForm.value.program_id.id,
            subportfolio_name: this.projectForm.value.subportfolio_id.name || this.projectForm.value.subportfolio_id.display_name,
            subportfolio_id: this.projectForm.value.subportfolio_id.id,
            portfolio_name: this.projectForm.value.portfolio_id.name || this.projectForm.value.portfolio_id.display_name,
            portfolio_id: this.projectForm.value.portfolio_id.id,
            manager: this.ownerName
           }
           localStorage.setItem("program",JSON.stringify(newitem))
          this.router.navigate([`view/`+btoa(res?.id)+`/overview`]);
          this.dialogRef.close();
          this.toastrService.success('Project Created successfully.')
        }
        
      });
    }
}
  
  getProgramList(){
    this.dealStructureService.getProgramList().subscribe((res:any)=>{
      this.programList = res;
    })
  }

  getDealFields(form:any){
      this.OpportunityFieldList?.map((i: any) => {
        if (i.field_name == "opportunity_name") {
          this.data.name = i.field_status
          this.data.disName = i.display_name
          if(this.type == 'AddDeal'){
            this.data.disName = 'Deal Name';
          }
          if(this.type == 'AddProject'){
            this.data.disName = 'Project Name';
          }
          if (!i.field_status) {
            this.opportunityForm.removeControl('name');
          }
        }
        if (i.field_name == "opportunity_type") {
          this.data.opportunity = i.field_status;
          this.options2 = i.field_values;
          this.filteredOptions2 = this.options2;
          this.data.disOpportunity = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('opportunity');
          }
        }
        if (i.field_name == "program_name") {
          this.data.program = i.field_status;
          this.options3 = i.field_values;
          this.filteredOptions3 = this.options3;
          this.data.disProgram = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('program');
          }
        }
        if (i.field_name == "opportunity_portfolio") {
          this.data.portfolio = i.field_status;
          this.options1 = i.field_values;
          this.filteredOptions1 = this.options1;
          this.data.disPortfolio = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('portfolio');
          }
        }
        if (i.field_name == "opportunity_sub_portfolio") {
          this.data.subPortfolio = i.field_status;
          this.subPortOptions = i.field_values;
          this.subPortfilteredOptions = this.subPortOptions;
          this.data.disSubPortfolio = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('subPortfolio');
          }
        }
        if (i.field_name == "opportunity_owners") {
          this.data.owners = i.field_status;
          this.ownersOptions = i.field_values;
          this.ownersfilteredOptions = this.ownersOptions;
          this.data.disOwners = i.display_name
          if (!i.field_status) {
            this.opportunityForm.removeControl('owners');
          }
        }
      })
      if(form == 'deal'){
        this.initAutoCompleteDeal();
      }else{
        this.initAutoCompleteProject();
      }
      
    }
  
    initAutoCompleteDeal(): any {
      if (this.data.opportunity) {
        this.filterOptionType = this.dealForm.get('name')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.typeFilter(name) : this.filteredOptions2.slice())
          );
      }
      if (this.data.portfolio) {
        this.filterOptionPortfolio = this.dealForm.get('portfolio')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
          );
      }
      if (this.data.program) {
        this.filterOptionProgram = this.dealForm.get('program')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.programFilter(name) : this.filteredOptions3.slice())
          );
      }
    }

    initAutoCompleteProject(): any {
      if (this.data.portfolio) {
        this.filterOptionPortfolio = this.projectForm.get('portfolio_id')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.portfolioFilter(name) : this.filteredOptions1.slice())
          );
      }
      if (this.data.program) {
        this.filterOptionProgram = this.projectForm.get('program_id')!.valueChanges
          .pipe(
            startWith(''),
            map((value: any) => typeof value === 'string' ? value : value?.name),
            map((name: any) => name ? this.programFilter(name) : this.filteredOptions3.slice())
          );
      }
  
    }

    onSelectionChangePortfolioDeal(event: any) {
      const portFolioId = event.source.value.id;
      this.subPortfilteredOptions = [];
      if (!portFolioId) {
        return;
      }
      this.dealForm.patchValue({
        subPortfolio: '',
        owners:''
      })
      this.subPortFolioChild =[];
      Object.keys(this.dealForm.controls).forEach((control:any)=>{
        if(control.includes('sub_portfolio_child')){
          this.dealForm.removeControl(control);
        }
      })
      
      this.subPortOptions = event.source.value.sub_portfolio;
      this.subPortfilteredOptions = this.subPortOptions;
      this.filterOptionSubPortfolio = this.dealForm.get('subPortfolio')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
        );
    }
    onSelectionChangePortfolioProject(event: any) {
      this.spinner.show();
      const portFolioId = event.source.value.id;
      this.subPortfilteredOptions = [];
      if (!portFolioId) {
        return;
      }
      this.projectForm.patchValue({
        subportfolio_id: '',
        program_id:''
      })
     
      
      this.subPortOptions = event.source.value.sub_portfolio;
      this.subPortfilteredOptions = this.subPortOptions;
      this.filterOptionSubPortfolio = this.projectForm.get('subportfolio_id')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.subPortfolioFilter(name) : this.subPortfilteredOptions.slice())
         
        );
        this.spinner.hide();
    }
  
  
    onSelectionChangeSubportPortfolioDeal(e: any) {    
      const subportFolioId = e.source.value.id;
      //this.subPortfilteredOptions = [];
      if (!subportFolioId) {
        return;
      }  
      let value = e.source.value;  
     
      this.subPortFolioChild =[];
      if(JSON.stringify(value).includes("sub_portfolio_child")){
        Object.keys(value).forEach((key:any)=>{
          if(key.includes("sub_portfolio_child")&& value[key] && value[key]?.length>0){  
            this.dealForm.addControl(key,new FormControl('',[Validators.required,this._formValidator.autocompleteObjectValidator()]));  
            let level = key?.split("_")?.pop();;
            this.subPortFolioChild.push({"key":key,"name":"Sub-Portfolio Child "+level,arrList:value[key]})
          }else{
            this.dealForm.patchValue({
              owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
            })
            this.ownerDetails = value.owner_details?.[0];
            this.ownerDetails['mail']=this.ownerDetails['email'];
          }
        });
      }else{
        this.dealForm.patchValue({
          owners: value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username
        })
        this.ownerDetails = value.owner_details?.[0];
        this.ownerDetails['mail']=this.ownerDetails['email'];
      }
    }
    ownerName:any='';

    onSelectionChangeSubportPortfolioProject(e:any){
      if(e.isUserInput){
      this.spinner.show();
      const subportFolioId = e.source.value.id;
      if (!subportFolioId) {
        return;
      }  
      this.projectForm.patchValue({
        program_id:''
      })
      let value = e.source.value; 
      this.filterService.getProgram(e.source.value.id).subscribe(async (res:any)=>{
        this.filterOptionProgram = this.projectForm.get('program_id')!.valueChanges
        .pipe(
          startWith(''),
          map((value: any) => typeof value === 'string' ? value : value?.name),
          map((name: any) => name ? this.programFilter(name) : res.records.slice())
        );
        this.spinner.hide();
        
      });
      // this.projectForm.patchValue({
        this.ownerName = value.owner_details?.[0]?.full_name || value.owner_details?.[0]?.username;
      // })
    }
    }
  selectedSowResources:any = [];
    getResourceListFromContract(contract:any,index:any,event:any){
      if(event){
        let details = JSON.parse(localStorage.getItem('program') || '{}');
        let params = {
          project_id:details?.project_id,
          portfolio_name:details?.portfolio_name,
          subportfolio_name:details?.subportfolio_name,
          program_name:details?.program_name,
          vendor:contract?.vendor?.name,
        }
        this.spinner.show();
        this.projectService.getResourceListOfSowContract(params).subscribe((res:any)=>{
          if(this.selectedSowResources?.length > 0){
            this.selectedSowResources.forEach((element:any) => {
              res = res.filter((item:any) => item.id !== element.id);
            });
          }
          this.type.contract_List[index].resourseList = res;
        })
        this.spinner.hide();
      }
    }

    selectedResource(resource:any,i:any,event:any){
      if(event){
        this.selectedSowResources.push(resource);
        this.type.contract_List.forEach((res:any,index:any) => {
          if(index != i){
            if(res?.resourseList){
              this.type.contract_List[index].resourseList = res?.resourseList?.filter((item:any) => item.id !== resource.id);
            }
          }
        });
      }else{
        this.selectedSowResources = this.selectedSowResources?.filter((item:any) => item.id !== resource.id);
      }
    }
    projectType:any = [];
    projectFundingType:any = [];
    async getProjectType(){
      await this.projectService.getProjectType().subscribe((res:any)=>{
        this.projectType = res;
      });
    }

    async getProjectFundingType(){
      await this.projectService.getProjectFundingType().subscribe((res:any)=>{
        this.projectFundingType = res;
      });
    }

    close(){
      this.dialogRef.close()
      // let iframe: any = document.getElementById('emberIfram');
      // this.dialogRef.close( iframe.contentWindow.location.href)
    }

    getConsumed(blended: any){
      let amount = 0;
      blended.forEach((element: any) => {
        amount = amount + Number(element.cost)
      });
      return amount;
    }
}
