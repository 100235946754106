import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { MessageService } from '../../services/message.service';
import { TurnkeyTrackerService } from '../../services/turnkey-tracker.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-workgroup',
  templateUrl: './workgroup.component.html',
  styleUrls: ['./workgroup.component.scss']
})
export class WorkgroupComponent {
    @Input() inputString: any;
    @Input() inputType: any;
    @Input() editable: boolean = true;
    @Output() refereshParentData = new EventEmitter<string>();
    @Input() showCount: any;
    @Input() showLabel:boolean = true;
    @Input() workgroupdata: any;
    oldData: any;
    workgroup: any;
    selectedWorkgroup: any = [];

    myControl = new FormControl();
    filteredOptions: Observable<string[]>;

    editMode: boolean = false; 
    
    constructor(private cookieService: CookieService, private messageService: MessageService,
      private turnkeyTrackerService: TurnkeyTrackerService){}

    ngAfterViewInit() {
      this.getWorkgroup();
  
      if(!this.cookieService.get('workgroup')){
        const myDate: Date = new Date();
        myDate.setSeconds( myDate.getSeconds() + 10 );
  
        this.cookieService.set('workgroup', 'true', { expires: myDate });
        this.getAllWorkgroup();
      }
    }

    async getWorkgroup(){
      let t = this;
      this.messageService.getWorkgroup().subscribe((res: any) => {
        if(res.length > 0){
          t.selectedWorkgroup = [];
          t.workgroup = res;
            if(t.workgroupdata){
              t.workgroupdata.forEach((element: any) => {
                t.workgroup.forEach((elm: any) => {
                  if(elm.name === element){
                    t.selectedWorkgroup.push(elm)
                  }
                });
              });
            }
            this.filteredOptions = this.myControl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        }
      })
    }

    private _filter(value: any): any {
      if(typeof value === 'string'){
        const filterValue = value?.toLowerCase();
        let t = this;
  
        let removeSelected = this.workgroup.filter( function( el: any ) {
          return !t.selectedWorkgroup.includes( el );
        } );
    
        return removeSelected.filter((wg: any) => wg?.name?.toLowerCase().includes(filterValue));
      }
    }
  
    getAllWorkgroup() {
      this.turnkeyTrackerService.getWorkgroup().subscribe((res: any) => {
        let resRec = res.records.filter((e: any) => e.field_name == 'opportunity_function')
        this.messageService.setWorkgroup(resRec[0].field_values);
      });
    }

    addWorkGroup(e: any){
      this.selectedWorkgroup.push(e);
      this.myControl.setValue("");
      this.sendToParent();
    }


    removeWorkGroup(i: any){
      this.selectedWorkgroup.splice(i, 1);
      this.sendToParent();
    }

    sendToParent(){
      let sel: any = [];
      this.selectedWorkgroup.forEach((element: any) => {
        sel.push(element.name)
      });

      this.refereshParentData.emit(sel);
    }
    
    cancelEdit(){
        this.inputString = this.oldData;
        this.editMode = false;
    }
}

