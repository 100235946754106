import { HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { of} from 'rxjs';
import {tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkForceService {

  constructor(private http: HttpClient,private transferState: TransferState) { }

  getWorkForceData(portFolioId?: any, subPortId?: any, projectId?: any, resource_name?: any, resource_title?: any, resource_classification?: any, resource_pyramid?: any, resource_gdp?: any, offSet?: any, end?: any,month?:any) {
    let params =`offset=${offSet}&end=${end}`;

    if (portFolioId) {
      if (portFolioId !== 20) params = `portfolio=${portFolioId}&${params}`; else params = params;
    } 
    
    if (subPortId) {
      params = `portfolio=${subPortId}&${params}`
    }
    
    if (month) {
      params = `month=${month}&${params}`
    } 
    
    if (projectId) {
      params = `project=${projectId}&${params}`
    } 
    
    if (resource_name) {
      if (resource_name !== '') params = `resource_name=${resource_name}&${params}`; params = params;
    } 
    
    if (resource_title) {
      params = `resource_title=${resource_title}&${params}`
    } 
    
    if (resource_classification) {
      params = `resource_classification=${resource_classification}&${params}`
    } 
    
    if (resource_pyramid) {
      params = `resource_pyramid=${resource_pyramid}&${params}`
    } 
    
    if (resource_gdp) {
      params = `resource_gdp=${resource_gdp}&${params}`
    }

    return this.http.get(`delivery/get-resource-data/?${params}`)
  }
  getAttritionData(projectId?: any,offSet?: any, end?: any,month?:any) {
    let params =`offset=${offSet}&end=${end}`;

    if (month) {
      params = `month=${month}&${params}`
    } if (projectId) {
      params = `project=${projectId}&${params}`
    }

    return this.http.get(`delivery/get-attrition-data/?${params}`)
  }

  getApplicationMasterData(projectId:any,offSet?: any, end?: any,filterString?:any){
    let params =`offset=${offSet}&end=${end}&${filterString}`;
    return this.http.get(`delivery/get-application-master/${projectId}/?${params}`)
    
  }

  getOpenRequirmentMasterData(projectId:any,offSet?: any, end?: any){
    let params =`offset=${offSet}&end=${end}`;
    // return this.http.get(`delivery/get-open-requirements/${projectId}?${params}`)
    return this.http.get(`delivery/open-reqs/?${params}`)
    
  }
  getApplicationMasterCount(){
   return this.http.get(`delivery/count-application-master/`)
  
  }

  getOpenRequirmentMasterCount(projectId:any){
    return this.http.get(`delivery/count-open-requirements/${projectId}/`)
   
   }
  getProjectApplicationMasterData(projectId:any,offSet?: any, end?: any,filterString?:any){
    let params =`offset=${offSet}&end=${end}&${filterString}`;
    return this.http.get(`delivery/get-project-application-master/${projectId}/?${params}`)
    
  }



  getProjectOpenRequirmentMasterData(projectId:any,offSet?: any, end?: any){
    let params =`offset=${offSet}&end=${end}`;
    // return this.http.get(`delivery/get-project-open-requirements/${projectId}?${params}`)
    return this.http.get(`delivery/project/${projectId}/open-reqs/?${params}`)
    
  }

  getProjectOpenRequirmentMasterCount(projectId:any){
    return this.http.get(`delivery/count-project-open-requirements/${projectId}/`)
   
   }

  getProjectApplicationMasterCount(projectId:any){
   return this.http.get(`delivery/count-project-application-master/${projectId}/`)
  
  }

  updateProjectApplicationMaster(projectId:any,payload:any){
    return this.http.put(`delivery/update-project-application-master/${projectId}/`,payload)

  }
  updateProjectOpenRequirmentMaster(projectId:any,payload:any){
    return this.http.put(`delivery/update-project-open-requirements/${projectId}/`,payload)

  }
  searchProjectApplications(projectId:any,name:any,tier:any){
    let params =``;

    


   if (name&&!tier) {
      params = `name=${name}`
    }
   else if (tier&&!name) {
      params = `tier=${tier}`
    }
   else if (tier&&name) {
      params = `name=${name}&tier=${tier}`
    }
    return this.http.get(`delivery/search-project-application-master/${projectId}?${params}`)

  }
  searchProjectOpenRequirments(projectId:any,jobid:any,jobtitle:any,daysopen:any,gdp:any){
    let params =``;
    if (jobid) {
      params = `job_id=${jobid}&${params}`
    }
    if (jobtitle) {
      params = `job_title=${jobtitle}&${params}`
    }
    if (daysopen) {
      params = `days_open=${daysopen}&${params}`
    }
    if (gdp) {
      params = `gdp=${gdp}&${params}`
    }
    params= params.substring(0,params.length-1);
    return this.http.get(`delivery/search-project-open-requirements/${projectId}?${params}`)

  }



getWorkforceDataCount(month:any,projectId?:any){
  let params =`month=${month}`;

  if(projectId){
    params = `project=${projectId}&${params}`
 }

  return this.http.get(`delivery/workforce-data-count?${params}`)

}
getAttritionDataCount(month:any,projectId?:any){
  let params =`month=${month}`;

  if(projectId){
    params = `project=${projectId}&${params}`
 }


  return this.http.get(`delivery/attrition-data-count/?${params}`)

}

getAttritionDataCountMonthWise(year:any,projectId?:any){
  let params =`year=${year}`;

  if(projectId){
    params = `project=${projectId}&${params}`
 }


  return this.http.get(`delivery/attrition-month-count/?${params}`)

}
  getWorkForceExportData(portFolioId?: any, subPortId?: any, projectId?: any, resource_name?: any, resource_title?: any, resource_classification?: any, resource_pyramid?: any, resource_gdp?: any, offSet?: any, end?: any) {
    let params = `offset=${offSet}&end=${end}`;

    if (portFolioId) {
      if (portFolioId !== 20) params = `portfolio=${portFolioId}&${params}`; else params = params;
    } if (subPortId) {
      params = `portfolio=${subPortId}&${params}`
    }if (projectId) {
      params = `project=${projectId}&${params}`
    } if (resource_name) {
      if (resource_name !== '') params = `resource_name=${resource_name}&${params}`; params = params;
    } if (resource_title) {
      params = `resource_title=${resource_title}&${params}`
    } if (resource_classification) {
      params = `resource_classification=${resource_classification}&${params}`
    } if (resource_pyramid) {
      params = `resource_pyramid=${resource_pyramid}&${params}`
    } if (resource_gdp) {
      params = `resource_gdp=${resource_gdp}&${params}`
    }

    return this.http.get(`delivery/get-detailed-resource-data/?${params}`)
  }

  getWorkForcePyramidDataById(projectId: number,month:number) {
    return this.http.get(`delivery/workforce-pyramid-map/?project=${projectId}&month=${month}`);
  }

  getWorkforceAttritionGaugeValue(projectId: number,month:number){
    return this.http.get(`delivery/target-value-attrition?project=${projectId}&month=${month}&year=2023`);


  }
  getWorkForceDataById(id: number) {
    return this.http.get(`delivery/get-resource-data/?id=${id}`);
  }



  deleteWorkForceById(id: number) {
    return this.http.delete(`delivery/delete-resource-data/${id}/`);
  }

  uploadDocfile(payload: any) {
    const uploadReq = new HttpRequest('POST', 'delivery/sync-resource-with-csv/', payload, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.http.request(uploadReq);
  }

  getAllDropDownValues() {
    const statusKey = makeStateKey('get-resource-dropdown');
    const cachedResponse = this.transferState.get(statusKey, null);
    if (!cachedResponse) {
      return this.http.get(`delivery/get-resource-dropdown/`).pipe(
        tap((res:any) => this.transferState.set(statusKey, res))
      )
    }
    return of(cachedResponse);
  }

  postWorkForceById(id: number, payload: any) {
    return this.http.put(`delivery/edit-resource-data/${id}/`, payload, { observe: 'response' });
  }

  getResourceChartData(portFolioId?: any, subPortId?: any, program?: any, projectId?: any, resource_name?: any, resource_title?: any, resource_classification?: any, resource_pyramid?: any, resource_gdp?: any,month?:any ) {
    let params = ``;
    if (portFolioId) {
      if (portFolioId !== 20) params = `portfolio=${portFolioId}&${params}`; else params = params;
    } if (subPortId) {
      params = `portfolio=${subPortId}&${params}`
    } 
    if(month){
      params = `month=${month}&${params}`

    }
    if (program) {
      params = `program=${program}&${params}`
    } if (projectId) {
      params = `project=${projectId}&${params}`
    } if (resource_name) {
      if (resource_name !== '') params = `resource_name=${resource_name}&${params}`; params = params;
    } if (resource_title) {
      params = `resource_title=${resource_title}&${params}`
    } if (resource_classification) {
      params = `resource_classification=${resource_classification}&${params}`
    } if (resource_pyramid) {
      params = `resource_pyramid=${resource_pyramid}&${params}`
    } if (resource_gdp) {
      params = `resource_gdp=${resource_gdp}&${params}`
    }
    if(params.endsWith("&")){
      params = params.substring(0, params.length - 1)
    }
    return this.http.get(`delivery/get-resource-chart?${params}`);
  }

  downloadResourceList() {
    return this.http.get(`delivery/workforce-sample-form/`, { responseType: 'blob' });
  }

  projectList() {
    return this.http.get('delivery/project-list/');
  }
  saveNewInsightsData(payload:any) {
    return this.http.post(`powerbi/create-powerbi-list/`, payload);;

  }
  getInsightsList(){
    return this.http.get(`powerbi/get-powerbi-list/`);;

  }
  deleteInsightData(id:any){
    return this.http.delete(`powerbi/delete-powerbi-list/${id}/`);;

  }

  getProjectKKRList(projectId:any,offSet?: any, end?: any,filterString?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.get(`delivery/get-krr-data/?project=${projectId}&${params}&${filterString}`);
  }
  getProjectResourceList(projectId:any,offSet?: any, end?: any,filterString?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.get(`delivery/get-resource-data/?project=${projectId}&${params}&${filterString}`);
  }
  getProjectAttritionList(projectId:any,offSet?: any, end?: any,filterString?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.get(`delivery/get-attrition-data/?project=${projectId}&${params}&${filterString}`);
  }

  getResourceList(offSet?: any, end?: any, payload?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-resource-data/?${params}`, payload);
  }
  getKKRList(offSet?: any, end?: any, payload?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-krr-data/?${params}`, payload);
  }
  getAttritionList(offSet?: any, end?: any,payload?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-attrition-data/?${params}`, payload);
  }


  getProjectResourceListNew(offSet?: any, end?: any, payload?: any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-resource-data/?${params}`, payload);
  }

  getProjectAttritionListNew(offSet?: any, end?: any, payload?: any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-attrition-data/?${params}`, payload);
  }

  geProjectKKRListNew(offSet?: any, end?: any, payload?: any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-krr-data/?${params}`, payload);
  }

  getProjectApplicationMasterDataNew(projectId?: any ,offSet?: any, end?: any, payload?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-project-application-master/${projectId}/?${params}`, payload)
    
  }
  getApplicationMasterDataNew(projectId?: any, offSet?: any, end?: any, payload?:any){
    let params =`offset=${offSet}&end=${end}`;
    return this.http.post(`delivery/get-application-master/${projectId}/?${params}`, payload)
    
  }

  getWorkForceAttritionDataById(id: number) {
    return this.http.post(`delivery/get-attrition-data/?id=${id}`,'');
  }
  getWorkForceResourceDataById(id:number, projectId: any = ''){
    let queryParam = projectId ? `id=${id}&project_id=${projectId}` : `id=${id}`;
    return this.http.post(`delivery/get-resource-data/?${queryParam}`,'');
  }
  getWorkForceKrrDataById(id:number){
    return this.http.post(`delivery/get-krr-data/?id=${id}`,'');
  }

  updateWorkForceDetailsById(payload:any,id:any){
    return this.http.put(`delivery/workforce/${id}/`,payload);
  }

  updateResourceContract(id:any,payload:any){
    return this.http.patch(`delivery/workforce/${id}/`,payload);
  }

  downloadPeopleInsights(url:any,payload:any) {
    return this.http.post(url,payload, {responseType: 'blob' });
  }
  getBrowseAIData(query:any) {
    return this.http.get(`browseai/parameterized-search/?key=${query}`)
    // const statusKey = makeStateKey('browseai/api/?key=${query}');
    // const cachedResponse = this.transferState.get(statusKey, null);
    //  if (!cachedResponse) {
    //   return this.http.get(`browseai/api/?key=${query}`).pipe(
    //     tap((res:any) => this.transferState.set(statusKey, res))
    //   )
    //  }
    // return of(cachedResponse);
  }

  getProjectVendorContractList(payload:any){
   
    const params = new HttpParams().set('project_id', payload?.project_id)
    .set('subportfolio_name', payload?.subportfolio_name)
    .set('portfolio_name', payload?.portfolio_name)
    .set('program_name',payload?.program_name)
    .set('company_name',payload?.vendor);
    return this.http.get(`delivery/ppm-contract/available-contracts/`, {params})
  }

  getProjectTrainings(projectId: any){
    return this.http.get(`delivery/project-trainings/?project_id=${projectId}`);
  }

  deleteResourceTraining(resTrainingId: any){
    return this.http.delete(`delivery/resource-training/${resTrainingId}/`);
  }

  getmetric(){
    return this.http.get(`metrics/metric-value/metrics`);
  }


}
