
        <!-- {{customcarousel|json}}{{buttonClicked|json}}{{stylecss|json}} -->
<section class="carousel">
    <div class="d-flex flex-row justify-content-around align-items-center">
        <button class="prevbtn p-2" (click)="slidePrev()" *ngIf="swipernav!=false">
            <span class="material-icons-outlined">
                keyboard_arrow_left
            </span>
        </button>
        <swiper #swiperRef [config]="config" (swiper)="onSwiper($event)" (slideChange)="onSlideChange()">
            <ng-template swiperSlide let-data
                *ngFor="let slide of customcarousel | comparePortFolio:masterPortfolioRecords;index as slideIndex">
                <div class="container">
                    <div class="row">
                        <div class="col" *ngIf="slideIndex==0">
                            <div class="row filter" (click)="carouselbutton('')"
                                [style.background-color]="buttonClicked === '' && customcarousel?.length === 8 && !!stylecss?.background === false ? '#706FD3' : stylecss?.background"
                                [ngStyle]="{'pointer-events': customcarousel?.length !== 8 ? 'none' : 'auto','opacity': customcarousel?.length !== 8 ? '0.5' : '1'}"
                                matTooltip="All of IT" matTooltipPosition="below">
                                <div class="col d-flex flex-row justify-content-evenly align-items-center p-0">
                                    <img src="/assets/images/carousel/customfilter/20.svg" alt="all of it" width="auto"
                                        height="auto">
                                    <p class="h6 fw-normal mb-0 text-truncate ms-1"
                                        [style.color]="buttonClicked === '' && customcarousel?.length === 8 && !!stylecss?.color === false ? '#ffffff' : stylecss?.color">
                                        {{staticText?.filter?.all_of_it}}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col p-0">
                            <div class="row filter" (click)="carouselbutton(slide.id )"
                                [style.background-color]="buttonClicked == slide.id  && !!stylecss?.background === false ? '#706FD3' : stylecss?.background"
                                [matTooltip]="slide.display_name.split('(')[0] | titlecase" matTooltipPosition="below"
                                [ngStyle]="{'pointer-events': slide?.disable_state ? 'none' : 'auto','opacity': slide?.disable_state ? '0.5' : '1'}">
                                <div class="col d-flex flex-row justify-content-evenly align-items-center p-0">
                                    <img [src]="'/assets/images/carousel/customfilter/'+ slide.id+'.svg'"
                                         width="auto" height="auto">
                                    <p class="h6 fw-normal mb-0 text-truncate ms-1 text-capitalize"
                                        [style.color]="buttonClicked == slide.id && !!stylecss?.color === false ? '#ffffff' : stylecss?.color">
                                        {{
                                        slide.display_name.split('(')[0] |
                                        titlecase}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </swiper>
        <button class="nextbtn p-2" (click)="slideNext()" *ngIf="swipernav!=false">
            <span class="material-icons-outlined">
                keyboard_arrow_right
            </span>
        </button>
    </div>
</section>