import { Component, Input, OnInit } from '@angular/core';
import { TermsService } from '../../services/terms.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-selection-tree-listing',
  templateUrl: './selection-tree-listing.component.html',
  styleUrls: ['./selection-tree-listing.component.scss'],
})
export class SelectionTreeListingComponent implements OnInit {
  @Input() items: any = [];
  @Input() tree: any;
  @Input() context: any;
  @Input() disabled: boolean = false;
  @Input() fromPage: string = "";

  loading: boolean = false

  constructor(private termService: TermsService, private toastrService: ToastrService){

  }
  
  ngOnInit(): void {
   
  }

  async toggleAccordion(item: any, indx: number) {
    item.expanded = !item.expanded;

    if(item.expanded){
      this.context.componentParent.collapse(item);
    }
  }

  getColor(item: any){
    return "";
  }

  selectGoals(item: any){
    if(!this.disabled){
      this.context.componentParent.selectGoals(item);
    }
  }
  emitCommentValue(event:any,item:any){
    item.comments = event.target.value;
    this.context.componentParent.selectGoals(item);
  }

  add(item: any){
    if(item.level == 'category'){
      item.children.push({
        "id": "",
        "term_category_id": item.uuid,
        "term_category": item.id,
        "name": "",
        "display_name":"",
        "is_custom": true,
        "level": "subcategory",
      })
    }else{
      item.children = item?.children ? item.children : []
      item.children.push({
        "id": "",
        "subcategory_id": item.id,
        "name": "",
        "title": "",
        "description":"",
        "is_custom": true,
        "level": "term",
        "category_id": item.category_id,
        "term_subcategory": item.id,
      })
    }
   
    console.log(item)
  }

  save(item: any){
    item.display_name = item.name;
    this.termService.saveSubcategory(item).subscribe((res: any) => {
      item.id = res.id;
      this.toastrService.success("Added successfully !");
      this.context.componentParent.reload();
    })
  }

  saveTerm(item: any){
    item.title = item.name;
    item.description = item.name;
    this.termService.saveTermcategory(item).subscribe((res: any) => {
      item.id = res.id;
      this.toastrService.success("Added successfully !");
      this.context.componentParent.reload();
    })
  }
}