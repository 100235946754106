<div class="accordion" id="accordionExample">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingZero">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseOne"
                style="font-size: 13px">
                Projects Sorted by Tags
            </button>
        </h2>
        <div id="collapseZero" class="accordion-collapse collapse" aria-labelledby="headingZero"
            data-bs-parent="#accordionExample">
            <div class="accordion-body p-2">
                <app-loading *ngIf="loading"></app-loading>
                <div class="browseai1-card" [ngStyle]="{'background': data.color}"  *ngFor="let data of projects" (click)="openProjects(data)">
                    <div class="d-flex justify-content-between">
                        <h6 style="font-size: 14px; font-weight: 400;" class="m-0">{{data.project_name}} | {{data.portfolio}} | {{data.sub_portfolio}} | {{data.owner_name}} </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne"
                style="font-size: 13px">
                Curated from Web
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body p-2">
                <app-gnews [display_header]="'false'"></app-gnews>
            </div>
        </div>
    </div>
</div>

<!-- <h6></h6>
<app-loading *ngIf="loading"></app-loading> -->
<!-- <div class="browseai1-card" [ngStyle]="{'background': data.color}"  *ngFor="let data of projects" (click)="openProjects(data)">
    <div class="d-flex justify-content-between">
        <h6 style="font-size: 14px;" class="m-0">{{data.project_name}} | {{data.portfolio}} | {{data.sub_portfolio}} | {{data.owner_name}} </h6>
    </div>
</div> -->